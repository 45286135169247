import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'

// import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getUserRequests, clearErrors } from '../../actions/requestActions'

let clicked = "All";
let firstLoad = true;

const UserRequests = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, requests } = useSelector(state => state.getRequests);

    let [ filteredRequests, setFilteredRequests ] = useState(requests)

    if (firstLoad) {filteredRequests = requests};

    useEffect(() => {
        dispatch(getUserRequests());

        if (error) {
            console.log(error, "ERRROORRRR!! - userRequests.jsx")
            // alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const toggleTable = (status) => {
        firstLoad = false
        let filter
        clicked = status
        if (status === "All") {
            filter = requests;
        } else if (status === "Processing") {
            filter = requests.filter(request => request.status === "Processing")
        } else if (status === "Completed") {
            filter = requests.filter(request => request.status === "Completed")
        } else if (status === "Cancelled"){
            filter = requests.filter(order => order.status === "Cancelled")
        } else {
            filter = []
        }

        setFilteredRequests(filter)
    }

    const rowData = [];

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Fragment>
            <Metadata title={"User Requests"}/>

            <div className="container-md green-color my-4 ps-4 ps-md-2">
                <div onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</div>
            </div>

            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-md text-muted">REQUESTS</h3>
                    <div className="d-flex flex-column flex-md-row justify-content-between border-bottom mb-4">
                        <span className="text-muted text-size-xs order-1 order-md-2">{filteredRequests.length} {clicked === 'All' ? 'Requests' : clicked}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Processing') }} className={`table-nav ${clicked === 'Processing'?'table-active':''}`}>Processing</button>
                            <button onClick={function() { toggleTable('Completed') }} className={`table-nav ${clicked === 'Completed'?'table-active':''}`}>Completed</button>
                            <button onClick={function() { toggleTable('Cancelled') }} className={`table-nav ${clicked === 'Cancelled'?'table-active':''}`}>Cancelled</button>
                        </div>
                    </div>
                        

                    {loading ? <Loader/> : (
                        <Fragment>

                        <table className="table mt-3">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Request date</th>
                                    <th scope="col">Date needed</th>
                                    <th scope="col">Status</th>
                                    <th scope="col" className="text-center">Action</th>
                                </tr>
                            </thead>

                            {filteredRequests.forEach(request => rowData.push(
                                <tr className="bg-white" key={request._id}>
                                    <td className="text-size-xs">{request.product}</td>
                                    <td className="text-size-xs">{request.quantity}</td>
                                    <td className="text-size-xs">{request.createdAt.split('T')[0]}</td>
                                    <td className="text-size-xs">{request.date.split('T')[0]}</td>
                                    <td className={`text-size-xs ${request.status === 'Cancelled' ? 'red-color' : request.status === 'Completed' ? 'green-color' : ''}`}>{request.status}</td>
                                    <td className="text-center"><Link to={`/requests/${request._id}`}className="table-btn">View</Link></td>
                                </tr>                                 
                            ))}
                            <tbody>{rowData}</tbody>
                        </table>
                        { filteredRequests.length == 0 ? (
                            <div className="text-center py-3 w-100">
                                <h3 className='text-size-md'>NO DATA</h3>
                            </div>
                        ):(<Fragment></Fragment>)}
                    </Fragment>
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default UserRequests