import axios from 'axios';
import {
    GET_LOGS_REQUEST,
    GET_LOGS_SUCCESS,
    GET_LOGS_FAIL,
    CLEAR_ERRORS
} from '../constants/logsConstants'

export const getLogs = () => async (dispatch) => {
    try {
        dispatch({ type: GET_LOGS_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/logs`)

        dispatch({
            type: GET_LOGS_SUCCESS,
            payload: data.logs
        })
    } catch (error) {
        dispatch({
            type: GET_LOGS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}