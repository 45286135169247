import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { signin, clearErrors } from '../../actions/userActions'

const Signin = () => {

    const [credential, setCredential] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { isAuthenticated, error, loading } = useSelector(state => state.auth);
    const loggedInUser = useSelector(state => state.auth).user;

    useEffect(() => {

        if (isAuthenticated) {
            if (loggedInUser.mobileVerification === "ChangePassword") {
                return navigate('/password/change', {state: {
                    source: "signin"
                }
            })};
            const userRole = loggedInUser.role === "Admin" || loggedInUser.role === "Admin+" ? "/admin/dashboard" : loggedInUser.role === "Farmer" || loggedInUser.role === "Vendor" ? "/seller/dashboard" : "/"
            const redirect = location.search ? (`/${location.search.split('=')[1]}`) : userRole
            navigate(redirect)
        }

        if (error) {
            toast.error(error)
            dispatch(clearErrors());
        }

    }, [dispatch, isAuthenticated, error])

    // FINGERPRINT
    const fingerPrintClick = (e) => {
        if (!credential) toast.error("Please enter your email address or phone number.")
    }

    function successFunc(result) {
        if (result.ErrorCode === 0) {
            if (result != null && result.BMPBase64.length > 0) {
                document.getElementById("finger").src = "data:image/bmp;base64," + result.BMPBase64;
                return result.TemplateBase64;
            }
        }else if (result.ErrorCode === 3) {
            return toast.error("Please connect a compatible SecuGen fingerprint scanner and try again.");
        } else {
            return toast.error("Fingerprint Capture Error Code:  " + result.ErrorCode);
        }
    };

    function errorFunc(status) {
        document.getElementById("finger").src = "/images/PlaceFinger.bmp";
        return toast.error("Error collecting fingerprint. Check if driver is installed and service is running.");
    };

    function CallSGIFPGetData(successCall, failCall) {

        document.getElementById("finger").src = "/images/wait.gif";

        let uri = "https://localhost:8443/SGIFPCapture";

        let xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function () {

            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                let fpobject = JSON.parse(xmlhttp.responseText);
                
                if (fpobject.ErrorCode !== 0) {
                    return toast.error("An error occured. \nPlease check that a compatible SecuGen fingerprint scanner is connected, the drivers are installed and the Web API Service is running.")
                };

                let template = successCall(fpobject);

                dispatch(signin(credential, password, template));
            }
            else if (xmlhttp.status == 404) {
                failCall(xmlhttp.status)
            }
        }
        xmlhttp.onerror = function () {
            failCall(xmlhttp.status);
        }

        let params = "timeout=" + "10000";
        params += "&quality=" + "50";
        params += "&templateFormat=" + "ISO";
        xmlhttp.open("POST", uri, true);
        xmlhttp.send(params);
    };

    ////////////////////////////////////////

    const submitHandler = (e) => {
        e.preventDefault();
        // CHECK IF CREDENTIAL AND PASSWORD IS ENTERED BY USER
        if (!credential || !password) {
            return toast.error('Please enter email/phone number and password')
        }
        dispatch(signin(credential, password));
    }

    return (
        <Fragment>
            <Fragment>
                <Metadata title={'Signin'} />
                <ToastContainer />
                <div className="container-md mid-container shadow-lg p-0 my-0 my-md-5 mx-md-auto">
                    <div className="row">
                        <div className="col-md-8 p-4 py-2 p-md-5">
                            <form className="px-2" onSubmit={submitHandler}>
                                <div className="mb-3">
                                    <h3 className="text-size-lg mb-5 mt-5">Sign In</h3>
                                    <label htmlFor="credential" className="form-label">Email address or Phone number</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="credential" 
                                        aria-describedby="credentialHelp" 
                                        placeholder="E.g. john@email.com or +2348012345678"
                                        value={credential}
                                        onChange={(e) => setCredential(e.target.value)}
                                        required
                                    />
                                    <div id="credentialHelp" className="text-size-xxs mt-2 text-muted">Phone number must have country code. E.g. +234, +1, etc.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Password" className="form-label">Password</label>
                                    <div className="d-flex">
                                        <input 
                                            type="password" 
                                            className="form-control me-3" 
                                            id="Password" 
                                            placeholder="**********"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <button type="button" onClick={fingerPrintClick} className='fingerprint-box' data-bs-toggle="modal" data-bs-target="#signInModal">
                                            <img className='fingerprint-icon' src="/images/fingerprint-icon.jpg" alt="" />
                                        </button>
                                    </div>
                                </div>

                                {/* Modal */}
                                { credential ?                                
                                <div className="modal fade" id="signInModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="signModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <span className="modal-title text-size-sm bold-2" id="signModalLabel">Biometric Sign In</span>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>

                                            <div className="modal-body">                                            
                                                <div className="d-flex flex-column align-items-center m-5 p-5">
                                                    <p className="text-center">When you're ready, click "scan finger" and scan any of your registered fingers</p>
                                                    <img id="finger" alt="finger" className="d-block hand" src="/images/PlaceFinger.bmp"/>
                                                    <button className="border-btn mt-4" type="button" onClick={function() { CallSGIFPGetData(successFunc, errorFunc)}}>Scan Finger</button>                                                                                                   
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <Fragment></Fragment> }


                                <div className="text-end"><Link to="/password/forgot" className="green-link">Forgot Password?</Link></div>
                                <button 
                                    type="submit" 
                                    className="text-size-xs green-btn py-2 my-4 w-100"
                                    disabled={loading ? true : false}
                                    >Sign In</button>
                                <div className="form-label text-center mb-5">Don't have a farmwarehouse account? <Link to="/register" className="text-end green-link">Create One</Link></div>
                            </form>
                        </div>
                        <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/sign-in-image.png')", backgroundSize: "cover"}}>
                            <img src="/images/fm-logo-white.png" className="white-logo my-4 me-4" alt="Farmwarehouse Logo"/>
                        </div>
                    </div>
                </div>    
            </Fragment>     
        </Fragment>
    )
}

export default Signin