import { 
    ALL_FARMS_REQUEST,
    ALL_FARMS_SUCCESS,
    ALL_FARMS_FAIL,
    FARM_DETAILS_REQUEST,
    FARM_DETAILS_SUCCESS,
    FARM_DETAILS_RESET,
    FARM_DETAILS_FAIL,
    UPDATE_FARM_REQUEST,
    UPDATE_FARM_SUCCESS,
    UPDATE_FARM_FAIL,
    CLEAR_ERRORS
} from '../constants/farmConstants'

export const updateFarmReducer = (state = { farm: {} }, action) => {
    switch(action.type) {
        case UPDATE_FARM_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_FARM_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                farm: action.payload.farm

            }
        case UPDATE_FARM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const allFarmsReducer = (state = { farms: [] }, action) => {
    switch (action.type) {
        case ALL_FARMS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ALL_FARMS_SUCCESS:
            return {
                ...state,
                farms: action.payload,
                loading: false,

            }

        case ALL_FARMS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state

    }
}

export const farmDetailsReducer = (state = { farm: {} }, action) => {
    switch (action.type) {
        case FARM_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FARM_DETAILS_SUCCESS:
            return {
                loading: false,
                farm: action.payload
            }
        case FARM_DETAILS_RESET:
            return {
                loading: false,
                farm: {}
            }
        case FARM_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}