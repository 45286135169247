import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Select from 'react-select';
import { getDefaults, clearErrors } from '../../actions/defaultsActions'

import Metadata from '../layout/metadata'
import SellerNavigation from '../layout/sellerNavigation'

let initDownPayment = 10; // In Percentage
let totalAmountToRepay = 0;

let duration = 12; // In Months
let amount = 0;

const financiers= [
    { name: 'financier', value: 'None', label: 'None' },
    { name: 'financier', value: 'FarmWarehouse', label: 'FarmWarehouse' },
]

const associations= [
    { name: 'association', value: 'None', label: 'None' },
    { name: 'association', value: 'FarmWec', label: 'FarmWec' },
]

const downPayments = [
    { name: 'downPayment', value: '5%', label: '5%'},
    { name: 'downPayment', value: '10%', label: '10%'},
    { name: 'downPayment', value: '20%', label: '20%'},
    { name: 'downPayment', value: '30%', label: '30%'},
    { name: 'downPayment', value: '50%', label: '50%'},
    { name: 'downPayment', value: '70%', label: '70%'},
    { name: 'downPayment', value: '90%', label: '90%'}
]

const durations = [
    { name: 'duration', value: '1 year', label: '1 year' },
    { name: 'duration', value: '2 years', label: '2 years' },
    { name: 'duration', value: '3 years', label: '3 years' },
    { name: 'duration', value: '4 years', label: '4 years' },
    { name: 'duration', value: '5 years', label: '5 years' },
    { name: 'duration', value: '8 years', label: '8 years' },
    { name: 'duration', value: '10 years', label: '10 years' }
]

const ApplyForFinancing = () => {
    let firstLoad = useRef(true);
    let initFinanceRate = useRef(0);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const { error, defaults } = useSelector(state => state.getDefaults);
    
    const loggedInUser = useSelector(state => state.auth).user;
    const { cartItems } = useSelector(state => state.cart)

    useEffect(() => {
        dispatch(getDefaults());

        if (error) {
            console.log(error)
            dispatch(clearErrors())
        }

    }, [dispatch, error])

    const itemsPrice = cartItems.reduce((acc, item) => acc + (item.quantity * item.price), 0)
    const shippingPrice = itemsPrice > 200000 ? 200 : 0 //500
    const taxPrice = Number((0.075 * itemsPrice).toFixed(2))
    const totalPrice = (itemsPrice + shippingPrice + taxPrice).toFixed(2);

    const [customerDetails, setCustomerDetails] = useState({
        firstName: loggedInUser.firstName,
        middleName: '',
        lastName: loggedInUser.lastName,
        mobileNumber: '',
        emailAddress: '',
        NIN: '',
        BVN: '',
        address: '',
        NOK: '',
        NOKrelationship: '',
        NOKaddress: ''
    });

    const [loanDetails, setLoanDetails] = useState({
        association: 'FarmWec',
        financier: 'FarmWarehouse',
        amountRequired: parseFloat(totalPrice),
        downPayment: '10%',
        duration: '1 year',
    });

    const [monthlyRepayment, setMonthlyRepayment] = useState(0);

    if (firstLoad.current && defaults.interestRate1) { 
        amount = parseFloat(totalPrice) - (parseFloat(totalPrice) * (parseInt(initDownPayment)/100)) 
        totalAmountToRepay = parseFloat(totalPrice) + (parseFloat(totalPrice) * (parseInt(initFinanceRate.current)/100))
        firstLoad.current = false
        initFinanceRate.current = defaults.interestRate1
        setMonthlyRepayment((( amount + (amount * (initFinanceRate.current/100)))  / duration ).toFixed(2))
    }


    const { firstName, middleName, lastName, mobileNumber, emailAddress, NIN, BVN, address, NOK, NOKrelationship, NOKaddress} = customerDetails;

    const onChange = e => {
        firstLoad.current = false;
        setCustomerDetails({...customerDetails, [e.target.name]: e.target.value })
    }

    const associationOnChange = e => {
        firstLoad.current = false;
        setLoanDetails({...loanDetails, [e.name]: e.value })
    }

    const financierOnChange = e => {
        firstLoad.current = false;
        setLoanDetails({...loanDetails, [e.name]: e.value })
    }

    const downPaymentOnChange = e => {
        firstLoad.current = false;
        setLoanDetails({...loanDetails, [e.name]: e.value })
        amount = totalPrice - (totalPrice * (parseInt(e.value)/100))
        recalculateTotal(initFinanceRate.current);
    }

    const durationOnChange = e => {
        firstLoad.current = false;
        initFinanceRate.current = defaults.interestRate2;
        let financeRate = initFinanceRate.current;
        
        setLoanDetails({...loanDetails, [e.name]: e.value })
        if (parseInt(e.value) === 1) {
            initFinanceRate.current = defaults.interestRate1;
            financeRate = initFinanceRate.current
            duration = 12
        } else if (parseInt(e.value) === 2) {
            duration = 24
            // financeRate *= 2
        } else if (parseInt(e.value) === 3) {
            duration = 36
            // financeRate *= 3
        } else if (parseInt(e.value) === 4) {
            duration = 48
            // financeRate *= 4
        } else if (parseInt(e.value) === 5) {
            duration = 60
            // financeRate *= 5
        } else if (parseInt(e.value) === 8) {
            duration = 96
            // financeRate *= 8
        } else if (parseInt(e.value) === 10) {
            duration = 120
            // financeRate *= 10
        }
        recalculateTotal(financeRate);
    }

    function recalculateTotal(interest) {
        setMonthlyRepayment( ( (amount + (amount * (interest/100) ) )  / duration ).toFixed(2) );
        totalAmountToRepay = parseFloat(totalPrice) + (parseFloat(totalPrice) * interest/100)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        loanDetails.totalAmountToRepay = totalAmountToRepay.toFixed(2);
        loanDetails.totalAmountToRepayAfterDiscount = totalAmountToRepay.toFixed(2);
        loanDetails.amountDue = (parseInt(loanDetails.downPayment)/100 * totalAmountToRepay).toFixed(2)
        loanDetails.monthlyRepayment = parseFloat(monthlyRepayment)
        loanDetails.monthsRemaining = duration
        loanDetails.interestRate = initFinanceRate.current + "%"
        loanDetails.type = "Product Financing"

        let loanData = {
            userDetails: customerDetails,
            loanDetails: loanDetails,
            productDetails: cartItems,
            type: "Product Financing"
        }

        navigate('/seller/loans/apply/terms', {state: { 
            loanData: loanData,
            orderDetails: location.state.orderDetails
        }})
    };


  return (
    <Fragment>
        <Metadata title={'Apply for Financing'} />
        <SellerNavigation Loans/>

        <div className="container-md green-color my-4 ps-4 ps-md-2">
            <Link to="/checkout/confirm-order" className="green-color">{"< Back to Confirmation"}</Link>
        </div>
        <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

            <h3 className="text-size-md text-muted">APPLY FOR FINANCING</h3>
            <hr className="my-4" />

            { loggedInUser ?
                <form onSubmit={submitHandler}>
                    {/* CUSTOMER DETAILS */}
                    <fieldset className="mb-5">
                        <legend>Customer Details</legend>
                        <div className="row">
                            {/* FIRST NAME */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="firstName" className="form-label">First Name</label><i className="red-color">*</i>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="firstName"
                                    name='firstName'
                                    value={firstName}
                                    onChange={onChange}
                                    aria-describedby="firstNameHelp" 
                                    required
                                    readOnly
                                />
                            </div>

                            {/* MIDDLE NAME */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="middleName" className="form-label">Middle Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="middleName"
                                    name='middleName'
                                    value={middleName}
                                    onChange={onChange}
                                    aria-describedby="middleNameHelp" 
                                />
                            </div>

                            {/* LAST NAME */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="lastName" className="form-label">Last Name</label><i className="red-color">*</i>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="lastName"
                                    name='lastName'
                                    value={lastName}
                                    onChange={onChange}
                                    aria-describedby="lastNameHelp" 
                                    required
                                    readOnly
                                />
                            </div>

                            {/* MOBILE NUMBER */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="mobileNumber" className="form-label">Mobile Number</label><i className="red-color">*</i>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="mobileNumber"
                                    name='mobileNumber'
                                    value={mobileNumber}
                                    onChange={onChange}
                                    aria-describedby="mobileNumberHelp" 
                                    required
                                />
                            </div>

                            {/* EMAIL ADDRESS */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="emailAddress" className="form-label">Email Address</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    id="emailAddress"
                                    name='emailAddress'
                                    value={emailAddress}
                                    onChange={onChange}
                                    aria-describedby="emailAddressHelp"
                                />
                            </div>

                            {/* NIN */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="NIN" className="form-label">National Identity Number (NIN)</label><i className="red-color">*</i>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="NIN"
                                    name='NIN'
                                    value={NIN}
                                    onChange={onChange}
                                    aria-describedby="NINHelp" 
                                    required
                                />
                            </div>

                            {/* BVN */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="BVN" className="form-label">Bank Verification Number (BVN)</label><i className="red-color">*</i>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="BVN"
                                    name='BVN'
                                    value={BVN}
                                    onChange={onChange}
                                    aria-describedby="BVNHelp" 
                                    required
                                />
                            </div>

                            {/* ADDRESS */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="address" className="form-label">Physical Address (with landmark)</label><i className="red-color">*</i>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="address"
                                    name='address'
                                    value={address}
                                    onChange={onChange}
                                    aria-describedby="addressHelp" 
                                    required
                                />
                            </div>

                            {/* NEXT OF KIN */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="NOK" className="form-label">Next of kin</label><i className="red-color">*</i>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="NOK"
                                    name='NOK'
                                    value={NOK}
                                    onChange={onChange}
                                    aria-describedby="NOKHelp" 
                                    required
                                />
                            </div>

                            {/* RELATIONSHIP WITH NEXT OF KIN */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="NOKrelationship" className="form-label">Relationship to next of kin</label><i className="red-color">*</i>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="NOKrelationship"
                                    name='NOKrelationship'
                                    value={NOKrelationship}
                                    onChange={onChange}
                                    aria-describedby="NOKrelationshipHelp" 
                                    required
                                />
                            </div>

                            {/* NEXT OF KIN ADDRESS */}
                            <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="NOKaddress" className="form-label">Next of kin address</label><i className="red-color">*</i>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="NOKaddress"
                                    name='NOKaddress'
                                    value={NOKaddress}
                                    onChange={onChange}
                                    aria-describedby="NOKaddressHelp" 
                                    required
                                />
                            </div>
                        </div>
                    </fieldset>

                    {/* PRODUCT DETAILS */}
                    <fieldset className="mb-5">
                        <legend>Product Details</legend>
                        { cartItems.map(item => (
                            <div className="row" key={item.product}>
                                {/* PRODUCT NAME */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="productName" className="form-label">Product Name</label><i className="red-color">*</i>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="productName"
                                        name='productName'
                                        value={item.name}
                                        onChange={onChange}
                                        aria-describedby="productNameHelp" 
                                        readOnly
                                    />
                                </div>

                                {/* PRODUCT QUANTITY */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="productQuantity" className="form-label">Product Quantity</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="productQuantity"
                                        name='productQuantity'
                                        value={item.quantity}
                                        onChange={onChange}
                                        aria-describedby="productQuantityHelp" 
                                        readOnly
                                    />
                                </div>
                            </div>
                        ))}
                    </fieldset>
                    
                    {/* LOAN DETAILS */}
                    <fieldset>
                        <legend>Finance Details</legend>
                        <div className="row">
                            {/* AMOUNT REQUIRED */}
                            <div className="col-md-6 mb-4">
                                <label htmlFor="amountRequired" className="form-label">Financing Required incl. VAT (&#x20A6;)</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="amountRequired"
                                    name='amountRequired'
                                    value={totalPrice}
                                    onChange={onChange}
                                    aria-describedby="amountRequiredHelp" 
                                    readOnly
                                />
                            </div>

                            {/* FINANCE RATE */}
                            <div className="col-md-6 mb-4">
                                <label htmlFor="initFinanceRate.current" className="form-label">Finance Rate</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="initFinanceRate.current"
                                    name='initFinanceRate.current'
                                    value={initFinanceRate.current +"%"}
                                    onChange={onChange}
                                    aria-describedby="financeRateHelp" 
                                    readOnly
                                />
                            </div>

                            {/* ASSOCIATION*/}
                            <div className="col-md-6 mb-4">
                                <label htmlFor="association" className="form-label">Select Association</label><i className="red-color">*</i>
                                <Select
                                    name='association'
                                    value={associations.value}
                                    options={associations}
                                    defaultValue={associations[1]}
                                    onChange={associationOnChange}
                                />
                            </div>

                            {/* FINANCIER */}
                            <div className="col-md-6 mb-4">
                                <label htmlFor="financier" className="form-label">Select Financier</label><i className="red-color">*</i>
                                <Select
                                    value={financiers.value}
                                    options={financiers}
                                    defaultValue={financiers[1]}
                                    onChange={financierOnChange}
                                />
                            </div>

                            {/* DOWN PAYMENT */}
                            <div className="col-md-6 mb-4">
                                <label htmlFor="downPayment" className="form-label">Down Payment</label><i className="red-color">*</i>
                                <Select
                                    name='downPayment'
                                    value={downPayments.value}
                                    options={downPayments}
                                    defaultValue={downPayments[1]}
                                    onChange={downPaymentOnChange}
                                />
                            </div>

                            {/* PAYMENT DURATION */}
                            <div className="col-md-6 mb-4">
                                <label htmlFor="duration" className="form-label">Payment Duration</label><i className="red-color">*</i>
                                <Select
                                    name='duration'
                                    value={durations.value}
                                    options={durations}
                                    defaultValue={durations[0]}
                                    onChange={durationOnChange}
                                />
                            </div>

                            <div className="total">
                                <h5 className="text-size-sm bold-2">Total Monthly Repayments: &#x20A6;{monthlyRepayment}</h5>
                            </div>
                        </div>
                    </fieldset>

                    <div className="d-sm-flex align-items-center justify-content-end">
                        <button type="submit" className="text-size-xs border-btn mt-4">Read Terms and Conditions</button>
                    </div>
        
                </form> : <Fragment></Fragment>
            }
        </div>
    </Fragment>
  )
}

export default ApplyForFinancing;