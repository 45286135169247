import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Metadata from '../layout/metadata'


const OrderSuccess = () => {

  useEffect(() => {
    const element = document.getElementsByClassName("modal-backdrop")[0];
    element.remove();
  }, []);

  return (
    <Fragment>
        <Metadata title={'Order Success'}/>
        <div className="container-sm mid-container mt-5 pt-4">
            <div className="d-flex flex-column align-items-center justify-content-center py-5">
                <img src="/images/success.png" alt="Order Success Checkmark" className="mt-5 mb-5" width="100" height="100" />
                <h4>Your order has been placed successfully.</h4>
                <Link to={"/orders"} className="text-size-md mt-3">Go to orders</Link>
            </div>
           
        </div>
    </Fragment>
  )
}

export default OrderSuccess