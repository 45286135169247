import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getSellerOrders, clearErrors } from '../../actions/orderActions'
import SellerNavigation from '../layout/sellerNavigation'

let clicked = "All";
let firstLoad = true;

const SellerOrders = () => {
    const dispatch = useDispatch();

    const { loading, error, orders } = useSelector(state => state.getOrders);

    let [ filteredOrders, setFilteredOrders ] = useState(orders)


    if (firstLoad) { filteredOrders = orders };

    useEffect(() => {
        dispatch(getSellerOrders());

        if (error) {
            console.log(error, "ERRROOORR!! - sellerOrders.jsx")
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const toggleTable = (status) => {
        firstLoad = false
        let filter
        clicked = status
        if (status === "All") {
            filter = orders;
        } else if (status === "Processing") {
            filter = orders.filter(order => order.orderStatus === "Processing")
        } else if (status === "Shipped") {
            filter = orders.filter(order => order.orderStatus === "Shipped")
        } else if (status === "Delivered") {
            filter = orders.filter(order => order.orderStatus === "Delivered")
        } else if (status === "Cancelled"){
            filter = orders.filter(order => order.orderStatus === "Cancelled")
        } else {
            filter = []
        }
        setFilteredOrders(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Seller Orders"}/>
            <SellerNavigation Orders/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-md text-muted">ORDERS</h3>
                    <div className="d-flex flex-column flex-md-row justify-content-between border-bottom mb-4">
                        <span className="text-muted text-size-xs order-1 order-md-2">{filteredOrders.length} {clicked === 'All' ? 'Orders' : clicked}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Processing') }} className={`table-nav ${clicked === 'Processing'?'table-active':''}`}>Processing</button>
                            <button onClick={function() { toggleTable('Shipped') }} className={`table-nav ${clicked === 'Shipped'?'table-active':''}`}>Shipped</button>
                            <button onClick={function() { toggleTable('Delivered') }} className={`table-nav ${clicked === 'Delivered'?'table-active':''}`}>Delivered</button>
                            <button onClick={function() { toggleTable('Cancelled') }} className={`table-nav ${clicked === 'Cancelled'?'table-active':''}`}>Cancelled</button>
                        </div>
                    </div>
                
                    {loading ? <Loader/> : (
                        <Fragment>
                            <table className="table mt-3">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">OrderId</th>
                                        <th scope="col">No. of Items</th>
                                        <th scope="col">Order Amount</th>
                                        <th scope="col">Date ordered</th>
                                        <th scope="col">Status</th>
                                        <th className="text-center" scope="col">Action</th>
                                    </tr>
                                </thead>

                                {filteredOrders.forEach(order => rowData.push(
                                    <tr className="bg-white" key={order._id}>
                                        <td className="text-size-xs d-flex align-items-center">{order.new ? <span className="red-circle">🔴</span> : <Fragment></Fragment>}#{order._id}</td>
                                        <td className="text-size-xs">{order.orderItems.length}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(order.totalPrice)}</td>
                                        <td className="text-size-xs">{order.createdAt.split('T')[0]}</td>
                                        <td className={`text-size-xs ${order.orderStatus === 'Cancelled' ? 'red-color' : order.orderStatus === 'Delivered' ? 'green-color' : ''}`}>{order.orderStatus}</td>
                                        <td className="text-center"><Link to={`/seller/orders/${order._id}`} className="table-btn">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { filteredOrders.length === 0 ? (
                                <div className="text-center py-3 w-100">
                                    <h3 className='text-size-md'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>
                    )}
                </div>
            </div>
            
        </Fragment>
    )
}

export default SellerOrders