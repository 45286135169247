import React, { Fragment, useEffect, useState, useRef } from 'react'
import Metadata from '../layout/metadata'

import AdminNavigation from '../layout/adminNavigation'

import { numberWithCommas, formatDate } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getLoanDetails, updateLoanData, deleteLoanData, clearErrors } from '../../actions/loanActions'

import { UPDATE_LOAN_RESET } from '../../constants/loanConstants'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../layout/loader'
import DeleteModal from '../layout/deleteModal'
import PaymentHistoryModal from '../layout/paymentHistoryModal'

let downPayment;

const AdminLoanDetails = () => {
    let firstLoad = useRef(true);
    let isChange = useRef(false);
    let isDelete = useRef(false);

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);
    const { loan, error, loading } = useSelector(state => state.loanDetails);

    const { loading: modifyLoading, error: modifyError, success } = useSelector(state => state.modifyLoan);

    if (loan && loan.loanDetails) downPayment = (parseInt(loan.loanDetails.downPayment)/100 * loan.loanDetails.totalAmountToRepayAfterDiscount).toFixed(2)

    let [loanData, setLoanData] = useState({
        status: "",
        discount: "",
        paidAmount: ""
    })

    let { status, discount, paidAmount } = loanData;

    if (firstLoad.current && loan && loan.loanDetails) {
        discount = parseInt(loan.loanDetails.discount)
        status = loan.status
    }

    const onLoanDataChange = e => {
        firstLoad.current = false
        isChange.current = true
        setLoanData({...loanData, [e.target.name]: e.target.value })
    }

    useEffect(() => {

        if (!isDelete.current) dispatch(getLoanDetails("Admin", params.id));

        if (success) {
            firstLoad.current = true;
            dispatch({type: UPDATE_LOAN_RESET});
            toast.success("Completed successfully! 👍");
            if (isDelete.current) navigate("/admin/loans");
        }

        if (error) {
            toast.error("Error fetching loan details, please check your internet connection.")
            dispatch(clearErrors())
        }

        if (modifyError) {
            toast.error("An error occurred, please check your internet connection.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success])

    const getClickedButton = (clickedButton) => {
        if (clickedButton === "Delete") {
            dispatch(deleteLoanData(params.id));
            isDelete.current = true;
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateLoanData(loanData, params.id));
    }

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Loan Details'}/>
            <AdminNavigation Loans/>
            <ToastContainer/>

            {loading || modifyLoading ? <Loader/> : (
                <Fragment>

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/admin/loans" className="green-color">{"< Back to loans"}</Link>
                    </div>

                    <div className="container-md white-bg shadow-lg mb-5 pt-5">
                        <h3 className="text-size-md">LOAN <span className="text-muted">#{loan._id}</span></h3>
                        
                        <hr className="mb-5"/>
                        <div className="row">
                            {/* DETAILS */}
                            <div className="col-md-6">
                                {loan.loanDetails && loan.userDetails ? (
                                    <Fragment>
                                    <div className="">
                                        <h5 className="bold-2 text-size-sm mb-4">Loan Details</h5>
                                        <p className="text-size-sm">Loan Type: <strong>{loan.type}</strong></p>
                                        <p className="text-size-sm">Created: <strong>{loan.createdAt ? formatDate(loan.createdAt) : ""}</strong></p>
                                        <p className="text-size-sm">Payment Status: <strong>{loan.paymentStatus}</strong></p>
                                        <p className="text-size-sm">Order: <Link to={`/admin/orders/${loan.order}`} className="green-color bold-2">#{loan.order}</Link></p>
                                        <p className="text-size-sm">Product Total: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.amountRequired)} (Incl. VAT & Shipping)</strong></p>
                                        <p className="text-size-sm">Amount (Before discount): <strong>&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountToRepay)}</strong></p>
                                        <p className="text-size-sm">Amount (After discount): <strong>&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountToRepayAfterDiscount)}</strong></p>
                                        <p className="text-size-sm">Down Payment: <strong>{loan.loanDetails.downPayment} (&#x20A6;{numberWithCommas(downPayment)}) - {loan.loanDetails.payments.length === 0 ? "Not Paid" : loan.loanDetails.payments.amount >= downPayment ? "Paid in full" : "Partly paid"}</strong></p>
                                        <p className="text-size-sm">Monthly Repayments: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.monthlyRepayment > 0 ? loan.loanDetails.monthlyRepayment : 0)}/month (Excl. Down Payment)</strong></p>
                                        <p className="text-size-sm">Amount Repayed: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountRepaid.toFixed(2))}</strong></p>
                                        <p className="text-size-sm">No. of payments made: <strong>{loan.loanDetails.paymentHistory.length}</strong> <span data-bs-toggle="modal" data-bs-target="#payment-history-modal" className="green-color bold-2 action text-size-xs"> - See history</span></p>
                                        <PaymentHistoryModal paymentHistory={loan.loanDetails.paymentHistory}/>
                                        <p className="text-size-sm">Amount Due: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.amountDue)}</strong></p>
                                        <p className="text-size-sm">Date Due: <strong>{loan.loanDetails.dateDue ? formatDate(loan.loanDetails.dateDue) : "[ Set after approval ]"}</strong></p>
                                        <p className="text-size-sm">Interest Rate: <strong>{loan.loanDetails.interestRate}</strong></p>
                                        <p className="text-size-sm">Term: <strong>{loan.loanDetails.duration}</strong></p>
                                        <p className="text-size-sm">Discount: <strong>{loan.loanDetails.discount}</strong></p>
                                        <p className="text-size-sm">Financier: <strong>{loan.loanDetails.financier}</strong></p>
                                        <p className="text-size-sm m-0">Association: <strong> {loan.loanDetails.association}</strong></p>
                                        <div className="d-flex mt-4">
                                            <Link to={`print`} state={{farmerName: `${loan.userDetails.firstName} ${loan.userDetails.lastName}`}} className="green-btn bold-2 text-size-xxs px-2 py-1">Print Agreement</Link>
                                            <Link to={`summary`} state={{loan: loan}} className="green-btn bold-2 ms-3 text-size-xxs px-2 py-1">Print Loan Summary</Link>
                                        </div>
                                    </div>

                                    {/* USER DETAILS */}
                                    <div className="mt-4">
                                        <hr className="w-25 my-4" />
                                        <h5 className="bold-2 text-size-sm my-4">Farmer Details</h5>
                                        <p className="text-size-sm">Applicant: <Link to={`/admin/users/${loan.user}`} state={{from: "loans"}} className="green-color bold-2">{loan.userDetails.firstName} {loan.userDetails.lastName}</Link></p>
                                        <p className="text-size-sm">Address: <strong>{loan.userDetails.address}</strong></p>
                                        <p className="text-size-sm">Telephone: <strong> {loan.userDetails.mobileNumber}</strong></p>
                                        <p className="text-size-sm">BVN: <strong> {loan.userDetails.BVN}</strong></p>
                                        <p className="text-size-sm">NIN: <strong> {loan.userDetails.NIN}</strong></p>
                                        <p className="text-size-sm">Next of Kin: <strong>{loan.userDetails.NOK}</strong></p>
                                        <p className="text-size-sm">Relationship with NOK: <strong>{loan.userDetails.NOKrelationship}</strong></p>
                                        <p className="text-size-sm">Address of NOK: <strong>{loan.userDetails.NOKaddress}</strong></p>
                                    </div>
                                    </Fragment>
                                ):(<Fragment></Fragment>)}
                            </div>

                            <div className="col-md-6">
                                {/* UPDATE */}
                                {loan.loanDetails ? (
                                    <form onSubmit={submitHandler}>
                                        {/* DISCOUNT */}
                                        <h5 className="text-size-sm mt-2">Apply discount (%)</h5>
                                        <input type="number" defaultValue={discount} className="form-control w-75" name="discount" min="0" max="95" onChange={onLoanDataChange}
                                        readOnly={loan.status ===  "Pre-approved" || loan.status ===  "Approved" || loan.status === "Denied"}/>

                                        {/* STATUS */}
                                        <h5 className="text-size-sm mt-4">Update loan status</h5>

                                        <select defaultValue={status} className="form-control mt-2 w-75" name="status" id="status" onChange={onLoanDataChange}
                                        disabled={loan.status ===  "Pre-approved" || loan.status ===  "Approved" || loan.status === "Denied"}>
                                            <option value="Processing">Processing</option>
                                            <option className="my-2 py-2" value="Pre-approved">Pre-approve</option>
                                            {loan.status ===  "Approved" ? <option className="my-2 py-2" value="Approved">Approve</option> : ""}
                                            <option className="red-color" value="Denied">Deny</option>
                                        </select>

                                        {modifyLoading ? (
                                            <i className="d-block text-muted text-size-xxs">Updating loan status...</i>
                                        ):(<Fragment></Fragment>)}
                                        
                                        <button type="submit" className="green-btn text-size-xs mt-4 py-2 px-3" 
                                        disabled={ loan.status === "Approved" || loan.status === "Denied" || !isChange.current || modifyLoading }>Update Status</button>
                                    </form>
                                ):<Fragment></Fragment>}

                                {/* UPDATE */}
                                {loan.loanDetails && loan.paymentStatus !== "Paid-Off" && (loan.status ===  "Pre-approved" || loan.status ===  "Approved") ? (
                                    <Fragment>
                                        <hr className="w-50 my-4"/>
                                        <form onSubmit={submitHandler}>
                                            {/* DISCOUNT */}
                                            <h5 className="text-size-sm mt-2 bold-2">Enter Paid Amount (&#x20A6;)</h5>
                                            <p className="text-size-xs mt-3"><span className="bold-2">₦{loan.loanDetails.payments.length === 0 ? numberWithCommas(downPayment) + " due immediately" :   numberWithCommas(loan.loanDetails.monthlyRepayment) + " due by " + loan.loanDetails.dateDue.split("T")[0]}</span></p>
                                            <input type="number" defaultValue={paidAmount} 
                                                className="form-control w-75" 
                                                name="paidAmount" 
                                                min="1000" 
                                                step="0"
                                                onChange={onLoanDataChange}
                                                readOnly={loan.paymentStatus ===  "Paid-Off"}
                                            />                                        
                                            <button type="submit" className="green-btn text-size-xs mt-4 py-2 px-3" 
                                            disabled={!paidAmount || modifyLoading }>Mark as Paid</button>
                                        </form>
                                    </Fragment>
                                ):<Fragment></Fragment>}
                            </div>
                        </div>
                        
                        <hr className="my-4" />

                        { loan.productDetails ?
                            <Fragment>
                                <div className="d-flex justify-content-between align-items-center my-4">
                                    <h3 className="text-size-sm bold-2 m-0">Products</h3>
                                    <span className="text-size-xs text-muted">({loan.productDetails.length} Products)</span>
                                </div>
                        
                                <table className="table">
                                    <thead className="table-secondary py-5">
                                        <tr>
                                            <th scope="col">
                                                <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                            </th>
                                            <th scope="col">Product name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    {
                                        loan.productDetails.forEach(item => rowData.push(
                                            <tr className="bg-white" key={item._id}>
                                                <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="cart-img3 bloan rounded"></div></td>
                                                <td className="text-size-xs">{item.name}</td>
                                                <td className="text-size-xs">&#x20A6;{numberWithCommas(item.price)}</td>
                                                <td className="text-size-xs">{numberWithCommas(item.quantity)}</td>
                                                <td className="text-size-xs">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                            </tr>                                 
                                        ))
                                    }

                                    <tbody>{rowData}</tbody>
                                </table>
                            </Fragment>:<Fragment></Fragment>
                        }

                        {user.role === "Admin+" ?
                        <Fragment>
                            <button className="red-btn bold-2 text-size-xs mt-5" data-bs-toggle="modal" data-bs-target="#delete-modal" disabled={modifyLoading}>Delete loan record</button>
                            <DeleteModal item="loan record" onSelect={getClickedButton}/>
                        </Fragment>

                        :<Fragment></Fragment>}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default AdminLoanDetails;