import React, { Fragment, useEffect, useState } from 'react'
import Metadata from '../layout/metadata'

import ProductCard from '../product/productCard'

import { Link, useNavigate, useLocation } from 'react-router-dom'

// import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getFarmDetails, updateFarmDetails, clearErrors } from '../../actions/farmActions'

import { useParams } from 'react-router-dom'
import Loader from '../layout/loader'

let firstLoad = true;
const FarmDetails = (admin=false) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const alert = useAlert()
    const params = useParams();

    const { farm, success, error, loading } = useSelector(state => state.farmDetails)
    // const updateLoading = useSelector(state => state.updateFarm).loading

    useEffect(() => {
        dispatch(getFarmDetails(params.id))

        if (error) {
            console.log(error, "ERRROOORR!! - adminFarmDetails.jsx")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success, params.id])

    const goBack = () => {
        navigate(-1)
    }

    let longA, latA, longB, latB, longC, latC, longD, latD = ""

    return (
        <Fragment>
            <Metadata title={'Farm Details'}/>

            {loading ? <Loader/> :
            (
                <Fragment>
                    {/* USERS SHOW PAGE */}
                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <div onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</div>
                    </div>

                    <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                        <h3 className="text-size-md mt-1 ps-0 text-muted">{farm.farmName}</h3>

                        <hr className="mb-4"/>

                        <div    >
                            <p className="text-size-sm mt-2">Farm location: <strong>{farm.farmLocation}</strong></p>
                            <p className="text-size-sm mt-2">Farm size: <strong>{farm.farmSize} Hectares</strong></p>
                            {farm.farmer ? <p className="text-size-sm mt-2">Farmer: <strong>{farm.farmer.firstName} {farm.farmer.lastName}</strong></p> : <Fragment></Fragment>}
                        </div>

                        {/* SET FARM COORDINATES */}
                        <hr className="my-4"/>

                        <form>
                            <h5 className="text-size-sm text-muted mb-4 ">Set farm coordinates</h5>
                            <div className="row">
                                {/* POINT A */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point A</h4>
                                    </div>
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="longA" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="longA"
                                                name='longA' 
                                                value={longA}
                                                aria-describedby="longAHelp" 
                                                placeholder="E.g. 9.0543"
                                                readOnly
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="latA" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latA"
                                                name='latA' 
                                                value={latA}
                                                aria-describedby="latAHelp" 
                                                placeholder="E.g. 6.9432"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* POINT B */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point B</h4>
                                    </div>
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="longB" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="longB"
                                                name='longB' 
                                                value={longB}
                                                aria-describedby="longBHelp" 
                                                placeholder="E.g. 9.0543"
                                                readOnly
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="latB" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latB"
                                                name='latB' 
                                                value={latB}
                                                aria-describedby="latBHelp" 
                                                placeholder="E.g. 6.9432"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* POINT C */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point C</h4>
                                    </div>                                    
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="longC" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="longC"
                                                name='longC' 
                                                value={longC}
                                                aria-describedby="longCHelp" 
                                                placeholder="E.g. 9.0543"
                                                readOnly
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="latC" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latC"
                                                name='latC' 
                                                value={latC}
                                                aria-describedby="latCHelp" 
                                                placeholder="E.g. 6.9432"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* POINT D */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point D</h4>
                                    </div>
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="longD" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="longD"
                                                name='longD' 
                                                value={longD}
                                                aria-describedby="longDHelp" 
                                                placeholder="E.g. 9.0543"
                                                readOnly
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="latD" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latD"
                                                name='latD' 
                                                value={latD}
                                                aria-describedby="latDHelp" 
                                                placeholder="E.g. 6.9432"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default FarmDetails