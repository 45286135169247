import axios from 'axios';
import {
    GET_LOANS_REQUEST,
    GET_LOANS_SUCCESS,
    GET_LOANS_FAIL,
    NEW_LOAN_REQUEST,
    NEW_LOAN_SUCCESS,
    NEW_LOAN_FAIL,
    UPDATE_LOAN_REQUEST,
    UPDATE_LOAN_SUCCESS,
    UPDATE_LOAN_FAIL,
    DELETE_LOAN_REQUEST,
    DELETE_LOAN_SUCCESS,
    DELETE_LOAN_FAIL,
    LOAN_DETAILS_REQUEST,
    LOAN_DETAILS_SUCCESS,
    LOAN_DETAILS_FAIL,
    CLEAR_ERRORS
} from '../constants/loanConstants'

export const newLoan = (loanData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_LOAN_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/seller/loans/apply', loanData, config)

        dispatch({
            type: NEW_LOAN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_LOAN_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updateLoanData = (loanData, id) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_LOAN_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/loans/${id}`, loanData, config)

        dispatch({
            type: UPDATE_LOAN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_LOAN_FAIL,
            payload: error.response.data.errMessage
        })
    }
}
export const deleteLoanData = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_LOAN_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.delete(`/api/v1/admin/loans/${id}`)

        dispatch({
            type: DELETE_LOAN_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_LOAN_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getLoans = (role) => async (dispatch) => {
    try {
        let url = "/api/v1/seller/loans"
        if (role === "Admin+" || role === "Admin") url = "/api/v1/admin/loans"

        dispatch({ type: GET_LOANS_REQUEST })

        
        const { data } = await axios.get(url)

        dispatch({
            type: GET_LOANS_SUCCESS,
            payload: data.loans
        })
    } catch (error) {
        dispatch({
            type: GET_LOANS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getLoanDetails = (role, id) => async (dispatch) => {
    try {
        let url = `/api/v1/seller/loans/${id}`
        if (role === "Admin+" || role === "Admin") url = `/api/v1/admin/loans/${id}`

        dispatch({ type: LOAN_DETAILS_REQUEST })

        const { data } = await axios.get(url)

        dispatch({
            type: LOAN_DETAILS_SUCCESS,
            payload: data.loan
        })
    } catch (error) {
        dispatch({
            type: LOAN_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}