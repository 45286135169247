import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UPDATE_PRODUCT_RESET } from '../../constants/productConstants'
import { getProductDetails, updateProduct, clearErrors } from '../../actions/productActions'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import SellerNavigation from '../layout/sellerNavigation'
import AdminNavigation from '../layout/adminNavigation'

let firstLoad;

const UpdateProduct = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const categories = [
        'Aromatic Plants',
        'Aquatic',
        'Beverages and Juices',
        'Cereal',
        'Chemicals',
        'Dairy',
        'Edible Oils',
        'Essential Oils',
        'Equipment',
        'Fertilizers',
        'Flowers',
        'Fodders',  
        'Fruits',
        'Grains',
        'Herbal Products',
        'Livestock and Pets',
        'Nuts',
        'Poultry',
        'Seeds',
        'spices',
        'Tools',
        'Tubers',
        'Vegetables',
        'Vehicles',
        'Others'
    ]

    const packages = [
        'Aluminium Foil',
        'Basket',
        'Crate',
        'None',
        'Paper Box',
        'Plastic Box',
        'Wooden Box',
        'Poly Bag',
        'Others'
    ]

    const { product } = useSelector(state => state.productDetails)
    const { user } = useSelector(state => state.auth)
    const fetching = useSelector(state => state.productDetails.loading)
    const { loading, error, success } = useSelector(state => state.modifyProduct)


    useEffect(() => {
        dispatch(getProductDetails(params.id))
        firstLoad = true;
        
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error, "ERRORRR - updateProduct.jsx")
            dispatch(clearErrors())
        }
        if (success) {
            navigate(`/products/${product._id}`, { state: { from: "updateProduct" } })
            toast.success("Successfully updated product 👍")
            dispatch({ type: UPDATE_PRODUCT_RESET })
        }
    }, [dispatch, error, success])

    const [productUpdate, setProductUpdate] = useState({
        name: "",
        price:  "",
        description:  "",
        category:  "", 
        packaging:  "",
        weight:  "",
        stock:  ""
    })

    let { name, price, description, category, packaging, weight, stock} = productUpdate;


    const [images, setImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [specDocs, setSpecDocs] = useState([]);
  
    if (JSON.stringify(product) !== '{}' && firstLoad) {
        let images = product.images.map(image => image.url)
        setProductUpdate({
            name: product.name,
            price: product.price,
            description: product.description,
            category: product.category,
            packaging: product.packaging,
            weight: product.weight,
            stock: product.stock
        });

        setImagesPreview(images)
        firstLoad = false
    }

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.set('name', name);
        formData.set('category', category);
        formData.set('packaging', packaging);
        formData.set('price', price);
        formData.set('weight', weight);
        formData.set('stock', stock);
        formData.set('description', description);

        images.forEach(image => {
            formData.append('images', image)
        })
        specDocs.forEach(doc => {
            formData.append('docs', doc)
        })
  
        dispatch(updateProduct(formData, params.id, user.role));
        
    }

    const onChange = e => {
        setProductUpdate({...productUpdate, [e.target.name]: e.target.value })
    }

    const onImageChange = e => {

        const files = Array.from(e.target.files)

        setImagesPreview([])
        setImages([])

        if (files.length > 5) {
            e.target.value = "";
            return toast.error(`You can select a maximum of 5 files.`);
          }

        setImages(files)

        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImagesPreview(oldArray => [...oldArray, reader.result])
                }
            }
            reader.readAsDataURL(file)
        })
    };

    const goBack = () => {
        navigate(-1)
    }

  return (
    <Fragment>
        <Metadata title={'Update Product'} />
        <ToastContainer />
        {user.role === "Admin+" ? <AdminNavigation Products/> : <SellerNavigation Inventory/> }

        <div className="container-md green-color my-4 ps-4 ps-md-2">
            <span onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</span>
        </div>

        {fetching ? <Loader/> :(
            <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                <h3 className="text-size-md text-muted">UPDATE PRODUCT</h3>
                <hr className="my-4" />
                <form onSubmit={submitHandler} encType="multipart/form-data">

                    <div className="row">
                        {/* PRODUCT NAME */}
                        <div className="col-md-6 mb-4">
                        <label htmlFor="name" className="form-label">Product Name</label><i className="red-color">*</i>
                        <strong><i id="productImageHelp" className="ms-2 red-color text-size-xxs">(Cannot exceed 40 characters)</i></strong>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name"
                                name='name'
                                value={name} 
                                onChange={(onChange)}
                                aria-describedby="nameHelp" 
                                placeholder="E.g. Tomatoes"
                                required
                                readOnly={loading}
                            />
                        </div>

                        {/* PRODUCT CATEGORY */}
                        <div className="col-md-6 mb-4">
                            <label htmlFor="category" className="form-label">Product Category</label><i className="red-color">*</i>
                            <select 
                                name="category" 
                                id="category" 
                                className="form-control"
                                value={category} 
                                onChange={(onChange)}
                                required
                                disabled={loading}
                                >
                                <option value="">Choose a Product Category</option>
                                {categories.map(category => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </select>
                        </div>

                        {/* PRODUCT PACKAGING */}
                        <div className="col-md-6 mb-4">
                            <label htmlFor="packaging" className="form-label">Product Packaging</label><i className="red-color">*</i>
                            <select 
                                name="packaging" 
                                id="packaging" 
                                className="form-control"
                                value={packaging} 
                                onChange={(onChange)}
                                required
                                disabled={loading}
                                >
                                <option value="">Choose Packaging Type</option>
                                {packages.map(packaging => (
                                    <option key={packaging} value={packaging}>{packaging}</option>
                                ))}
                            </select>
                        </div>

                        {/* PRODUCT PRICE */}
                        <div className="col-md-6 mb-4">
                            <label htmlFor="price" className="form-label">Product Price (&#x20A6;)</label><i className="red-color">*</i>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="price" 
                                name='price' 
                                value={price} 
                                onChange={(onChange)}
                                placeholder="E.g. 37000"
                                required
                                readOnly={loading}
                            />
                        </div>

                        {/* PRODUCT WEIGHT */}
                        <div className="col-md-6 mb-4">
                            <label htmlFor="weight" className="form-label">Product Weight (Kg)</label><i className="red-color">*</i>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="weight" 
                                name='weight'
                                value={weight} 
                                onChange={(onChange)}
                                placeholder="E.g. 85"
                                required
                                readOnly={loading}
                            />
                        </div>

                        {/* UNITS IN STOCK */}
                        <div className="col-md-6 mb-4">
                            <label htmlFor="stock" className="form-label">Product Stock (Units in Stock)</label><i className="red-color">*</i>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="stock" 
                                name='stock'
                                value={stock} 
                                onChange={(onChange)}
                                placeholder="E.g. 50"
                                required
                                readOnly={loading}
                            />
                        </div>

                        {/* PRODUCT DESCRIPTION */}
                        <div className="col-12 mb-4">
                            <label htmlFor="description" className="form-label">Product Description</label><i className="red-color">*</i>
                            <textarea 
                                className="form-control" 
                                id="description" 
                                name='description'
                                rows="5" 
                                value={description} 
                                onChange={(onChange)}
                                placeholder="Describe the product/equipment..."
                                required
                                readOnly={loading}
                            />
                        </div>

                        {/* PRODUCT IMAGE */}
                        <div className="col-12 mb-4">
                            <label htmlFor="productImage" className="form-label mb-3">Product Images</label><i className="red-color">*</i>
                            <strong><i id="productImageHelp" className="ms-2 red-color text-size-xxs">(Max 5 images)</i></strong>
                            <p className="text-size-xs green-color bold-2"><i>Image changes take a few seconds to reflect</i></p>
                            <div className="d-flex align-items-center">
                                {imagesPreview.map(image => (
                                    <figure key={image} className="m-0 me-2 p-0">
                                        <img 
                                            src={image}
                                            alt="Product Images Preview"
                                            key={image}
                                            className="preview" 
                                        />
                                    </figure>
                                ))}

                                <div className="border rounded mx-2 p-2 w-100">
                                    <input 
                                        type="file"
                                        name="images"
                                        className="custom-file-input text-size-xs w-100"
                                        id="images"
                                        onChange={onImageChange}
                                        multiple
                                        accept="image/*"
                                        disabled={loading}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* PRODUCT SPECIFICATION */}
                        <div className="col-12 mb-4">
                            <label htmlFor="docs" className="form-label mb-3">Product Specification Document</label><i className="text-muted text-size-xs">(Optional)</i>
                            <input 
                                type="file"
                                name="docs"
                                className="custom-file-input text-size-xs w-100 p-2 border"
                                id="docs"
                                accept="image/png, image/jpeg, application/pdf"
                                multiple
                                onChange={(e) => setSpecDocs(Array.from(e.target.files))}
                                disabled={loading}
                            />
                        </div>

                        <button 
                            type="submit" 
                            className="green-btn my-4 ms-auto me-3"
                            disabled={loading ? true : false}
                        >{loading ? "Updating product, please hold on..." : "Update product"}
                        </button>
                    </div>
                </form>

            </div>
        )}
    </Fragment>
  )
}

export default UpdateProduct