import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'
import { formatDate, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getAllOrders, clearErrors } from '../../actions/orderActions'

let clicked = "All";
let firstLoad = true;

const AllOrders = () => {

    const dispatch = useDispatch();

    const { loading, error, orders } = useSelector(state => state.getOrders );

    let [ filteredOrders, setFilteredOrders ] = useState(orders)

    if (firstLoad) { filteredOrders = orders };

    useEffect(() => {
        dispatch(getAllOrders());

        if (error) {
            console.log(error, "ERRROORRRR!!")
            // alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredOrders(orders)
        }
        let matches = orders.filter(order => order._id.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredOrders(matches);
        firstLoad = false;
    }

    const toggleTable = (status) => {
        firstLoad = false
        let filter
        clicked = status
        if (status === "All") {
            filter = orders;
        } else if (status === "Processing") {
            filter = orders.filter(order => order.orderStatus === "Processing")
        } else if (status === "Shipped") {
            filter = orders.filter(order => order.orderStatus === "Shipped")
        } else if (status === "Delivered") {
            filter = orders.filter(order => order.orderStatus === "Delivered")
        } else if (status === "Cancelled"){
            filter = orders.filter(order => order.orderStatus === "Cancelled")
        } else {
            filter = []
        }

        setFilteredOrders(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"All Orders"}/>
            <AdminNavigation Orders/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-sm text-muted">ORDERS</h3>

                    <div className="d-flex">
                        <input 
                        className="form-control mb-3 py-2" 
                        type="search" 
                        placeholder="Search orders by Id" 
                        aria-label="Search"
                        onChange={onSearchChange}
                        />
                    </div>

                    <div className="d-flex flex-column flex-md-row justify-content-between border-bottom mb-4">
                        <span className="text-muted text-size-xs order-1 order-md-2">{filteredOrders.length} {clicked === 'All' ? 'Orders' : clicked}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Processing') }} className={`table-nav ${clicked === 'Processing'?'table-active':''}`}>Processing</button>
                            <button onClick={function() { toggleTable('Shipped') }} className={`table-nav ${clicked === 'Shipped'?'table-active':''}`}>Shipped</button>
                            <button onClick={function() { toggleTable('Delivered') }} className={`table-nav ${clicked === 'Delivered'?'table-active':''}`}>Delivered</button>
                            <button onClick={function() { toggleTable('Cancelled') }} className={`table-nav ${clicked === 'Cancelled'?'table-active':''}`}>Cancelled</button>
                        </div>
                        
                    </div>
                        

                    {loading ? <Loader/> : (
                        <Fragment>

                        <table className="table mt-3">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th className="text-size-xs" scope="col">OrderId</th>
                                    <th className="text-size-xs" scope="col">No. of Items</th>
                                    <th className="text-size-xs" scope="col">Order Amount</th>
                                    <th className="text-size-xs" scope="col">Date ordered</th>
                                    <th className="text-size-xs" scope="col">Payment method</th>
                                    <th className="text-size-xs" scope="col">Status</th>
                                    <th className="text-size-xs text-center" scope="col">Action</th>
                                </tr>
                            </thead>

                            {filteredOrders.forEach(order => rowData.push(
                                <tr className="bg-white" key={order._id}>
                                    <td className="text-size-xs d-flex align-items-center">{order.new ? <span className="red-circle">🔴</span> : <Fragment></Fragment>}#{order._id}</td>
                                    <td className="text-size-xs">{order.orderItems.length}</td>
                                    <td className="text-size-xs">&#x20A6;{numberWithCommas(order.totalPrice)}</td>
                                    <td className="text-size-xs">{formatDate(order.createdAt)}</td>
                                    <td className="text-size-xs">{order.paymentMethod}</td>
                                    <td className={`text-size-xs ${order.orderStatus === 'Cancelled' ? 'red-color' : order.orderStatus === 'Delivered' ? 'green-color' : ''}`}>{order.orderStatus}</td>
                                    <td className="text-center text-size-xs"><Link to={`/admin/orders/${order._id}`}className="table-btn">View</Link></td>
                                </tr>                                 
                            ))}
                            <tbody>{rowData}</tbody>
                        </table>
                        { filteredOrders.length == 0 ? (
                            <div className="text-center py-3 w-100">
                                <h3 className='text-size-sm'>NO DATA</h3>
                            </div>
                        ):(<Fragment></Fragment>)}
                    </Fragment>
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default AllOrders