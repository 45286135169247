import React, { Fragment, useState, useEffect, useRef } from 'react'
import Metadata from '../layout/metadata'
import SellerNavigation from '../layout/sellerNavigation'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProductDetails, deleteProductData, clearErrors } from '../../actions/productActions'
import { addItemToCart } from '../../actions/cartActions'

import DeleteModal from '../layout/deleteModal'
import { DELETE_PRODUCT_RESET } from '../../constants/productConstants';

import { useParams } from 'react-router-dom'
import Loader from '../layout/loader'
import { numberWithCommas } from '../../Functions/functions'

import PDFViewer from './pdfViewer';


const ProductDetails = () => {
    let firstLoad = useRef(true)
    let isDelete = useRef(false)
    const location = useLocation();
    const { from } = location.state
    const [quantity, setQuantity] = useState(1)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, product, error } = useSelector(state => state.productDetails)
    const { loading: modifyLoading, error: modifyError, success } = useSelector(state => state.modifyProduct);

    const { user } = useSelector(state => state.auth);

    let [ previewImage, setPreviewImage ] = useState('');

    if ( firstLoad.current && product.images) {previewImage = product.images[0].url}

    const params = useParams();

    useEffect(() => {
        if (!isDelete.current) dispatch(getProductDetails(params.id))

        // CLEAR SEARCH FIELD
        try {
            document.querySelector('#main-search-field').value = "";

        } catch (error) {
            return
        }

        if (success) {
            dispatch({type: DELETE_PRODUCT_RESET});
            toast.success("Completed successfully! 👍");
            if (isDelete.current) navigate(-1);
        }

        if (error) {
            toast.error("Error fetching product details, please check your internet connection.")
            dispatch(clearErrors())
        }

        if (modifyError) {
            toast.error("An error occurred, please check your internet connection.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success])

    const increaseQty = () => {
        const count = document.querySelector('#count')

        if (count.valueAsNumber >= product.stock) return;
        
        const qty = count.valueAsNumber + 1

        setQuantity(qty)
    }

    const decreaseQty = () => {
        const count = document.querySelector('#count')

        if (count.valueAsNumber <= 1) return;
        
        const qty = count.valueAsNumber - 1

        setQuantity(qty)
    }
    
    const addToCart = () => {
        dispatch(addItemToCart(params.id, quantity));
        toast.success("Added to cart 👍")
    }

   const getImage = (e) => {
        firstLoad.current = false;
        setPreviewImage(e.target.src)
    }

    const getClickedButton = (clickedButton) => {
        if (clickedButton === "Delete") {
            dispatch(deleteProductData(params.id));
            isDelete.current = true;
        }
    }

    const goBack = () => {
        firstLoad.current = true;
        navigate(-1)
    }

    return (
        <Fragment>
            <Metadata title={'Product Details'}/>
            <ToastContainer />
            {from === "seller" ? <SellerNavigation Inventory/> : from === "admin" ? <AdminNavigation Products/> : ""}

            {loading ? <Loader/> : (
                <Fragment>                    
                    {/* PRODUCTS SHOW PAGE */}
                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <div onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</div>
                    </div>
                    <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                    <h3 className="container-md text-size-md mt-1 ps-0 text-muted">PRODUCT DETAILS</h3>
                    
                    <hr className="mb-5"/>
                        <div className="row">
                            <div className="col-sm-6 col-xl-4 d-flex flex-column mb-4 mb-sm-0">
                                <div className="details-img" style={{backgroundImage: `url('${previewImage}')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
                                <div className="flex">
                                    { product.images && product.images.map(image => (
                                        <img onClick={getImage} key={image.url} src={image.url} className="toggle-img mx-1"/>
                                    ))}
                                </div>

                            </div>
                            <div className="col-sm-6 col-xl-8 d-flex flex-column">
                                <div>
                                    <h3 className="text-size-md mb-1">{product.name}</h3>
                                    <span className='text-size-xxs text-muted'>Product #{product._id}</span>
                                    <h4 className="text-size-sm mt-2">&#x20A6;{numberWithCommas(product.price)}/Unit</h4>
                                </div>
                                <hr className="light-hr my-1 my-sm-2"/>
                                <div>
                                    <span className="text-size-xs text-muted my-5">Units in Stock</span>
                                    <h4 className="text-size-sm mt-2">{numberWithCommas(product.stock)} Units - <span className={product.stock > 0 ? 'green-color' : 'red-color'}>{product.stock > 0 ? 'In Stock' : 'Out of Stock'}</span></h4>
                                </div>
                                <hr className="light-hr my-1 my-sm-2"/>
                                <div>
                                    <span className="text-size-xs text-muted">Packaging</span>
                                    <h4 className="text-size-sm mt-2">{product.packaging}</h4>
                                </div>
                                <hr className="light-hr my-1 my-sm-2"/>
                                <div>
                                    <span className="text-size-xs text-muted">Overall Rating</span>
                                    <h4 className="text-size-sm mt-2">{product.rating} ({product.numOfReviews} reviews)</h4>
                                </div>
                                <hr className="light-hr my-1 my-sm-2"/>
                                <div className="mt-3">
                                    <div className="d-flex align-items-center">
                                        <button className="d-flex align-items-center justify-content-center green-btn quantity-toggle" disabled={product.stock === 0}  onClick={decreaseQty}>-</button>
                                        <input type="number" id="count" className="text-size-sm m-0 p-0" value={product.stock === 0 ? 0 : quantity} readOnly/>
                                        <button className="d-flex align-items-center justify-content-center green-btn quantity-toggle" disabled={product.stock === 0} onClick={increaseQty}>+</button>
                                        <button className="border-btn text-size-sm ms-4" disabled={product.stock === 0} onClick={addToCart}>Add to Cart</button>

                                        {(user && product.seller) && (user._id === product.seller._id || user && user.role === "Admin+" || user && user.role === "Admin") ?
                                            <Link to={`/seller/update-product/${product._id}`} className="border-btn text-size-sm ms-2">Update Product</Link>
                                            : <Fragment></Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>  
            
                        <div className='mt-5'>
                            <h3 className="text-size-sm">Product Details</h3>
                            <hr className="mt-1 my-sm-2"/>
                            <span className="text-size-xs text-muted">Description</span>
                            <p className="text-size-xs mt-2">{product.description}</p>
                    
                            {product.farm ? (
                                <Fragment>
                                    <h5 className="text-size-xs text-muted mt-4">Farm</h5>
                                    <span className="text-size-xs mt-2">{product.farm.farmName} - {product.farm.farmLocation}</span>
                                </Fragment>
                                ) : (<Fragment></Fragment>)
                            }

                            {product.seller ? (
                                
                                <Fragment>
                                    <h5 className="text-size-xs text-muted mt-4">Sold By</h5>
                                    <span className="text-size-xs mt-2">{product.seller.firstName} {product.seller.lastName}</span>
                                    {user ? <Link to={`/users/${product.seller._id}`} state={{ from: "productDetails" }} className="d-block text-size-xs text-success mt-2">View Profile</Link>: ""}
                                </Fragment>
                                ) : (<Fragment></Fragment>)
                            }
                            
                            {product.docs && product.docs.length > 0 ? (
                                <div className="mt-5">
                                    <p className="bold-2 text-size-xs">Product Document</p>
                                    <PDFViewer pdfUrl={product.docs[0].url} />
                                </div>
                            ): (<Fragment></Fragment>)}

                        </div>

                        {user && user.role === "Admin+" ?
                        <Fragment>
                            <button className="red-btn bold-2 text-size-xs mt-5" data-bs-toggle="modal" data-bs-target="#delete-modal" disabled={modifyLoading}>Delete product record</button>
                            <DeleteModal item="product record" onSelect={getClickedButton}/>
                        </Fragment>

                        :<Fragment></Fragment>}

                    </div>
                                            
                </Fragment>
            )}
        </Fragment>
    )
}

export default ProductDetails