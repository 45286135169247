import axios from 'axios';
import {
    GET_DEFAULTS_REQUEST,
    GET_DEFAULTS_SUCCESS,
    GET_DEFAULTS_FAIL,
    UPDATE_DEFAULTS_REQUEST,
    UPDATE_DEFAULTS_SUCCESS,
    UPDATE_DEFAULTS_FAIL,
    CLEAR_ERRORS
} from '../constants/defaultsConstants'

export const getDefaults = () => async (dispatch) => {
    try {
        dispatch({ type: GET_DEFAULTS_REQUEST })

        const { data } = await axios.get(`/api/v1/defaults`)

        dispatch({
            type: GET_DEFAULTS_SUCCESS,
            payload: data.defaults
        })
    } catch (error) {
        dispatch({
            type: GET_DEFAULTS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const modifyDefaults = (defaultsData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_DEFAULTS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/defaults`, defaultsData, config)

        dispatch({
            type: UPDATE_DEFAULTS_SUCCESS,
            payload: data.defaults
        })
    } catch (error) {
        dispatch({
            type: UPDATE_DEFAULTS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}