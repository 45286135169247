import React, { Fragment } from 'react'

import Metadata from '../layout/metadata'
import ProductCard from './productCard'

import { useLocation, useNavigate } from 'react-router-dom'

const ProductCategory = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { category, productsInCategory } = location.state

    const goBack = () => {
        navigate(-1)
    }

    return (
        
        <Fragment>
            <Metadata title={category}/>

                <div className="container-md green-color my-4 ps-4 ps-md-2">
                    <div onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</div>
                </div>

                <div className="container mt-3">
                    <div className="cat-box d-flex align-items-center justify-content-between mb-0">
                        <h3 className="text-size-sm mb-0">All {category}</h3>
                    </div>

                    <div className="row m-0 mb-3 mb-md-5">
                        { productsInCategory.map((product) => (
                            <ProductCard key={product._id} product={product}/>
                        ))}
                    </div>
                </div>
        </Fragment>
    )
}

export default ProductCategory