import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import { numberWithCommas } from '../../Functions/functions'

// import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'

import AdminNavigation from '../layout/adminNavigation'


const AdminPayments = () => {
    // const alert = useAlert();
    const dispatch = useDispatch();

    // const { loading, error, products } = useSelector(state => state.products);

    // useEffect(() => {
    //     dispatch(getSellerProducts());

    //     if (error) {
    //         console.log(error, "ERRROOORR!! - sellerPayments.jsx")
    //         // alert.error(error);
    //         dispatch(clearErrors())
    //     }
    // }, [dispatch, error])

    const rowData = [];
    return (
        <Fragment>
            <Metadata title={'Payments'}/>
            <AdminNavigation Payments/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-sm text-muted mb-5">PAYMENTS</h3>

                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <div className="d-flex flex-column wallet shadow-lg">
                                <h1>N40,000</h1>
                                <span className="mb-4">Wallet balance</span>
                                <button className="border-btn">Withdraw to Bank</button>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex flex-column wallet-side shadow-lg">
                                <h1 className="text-size-xl">N500,000</h1>
                                <span>Total Received</span>
                            </div>
                            <div className="d-flex flex-column wallet-side shadow-lg mt-3">
                                <h1 className="text-size-xl">N200,000</h1>
                                <span>Total Withdrawn</span>
                            </div>
                        </div>
                    </div>

                    {/* {loading ? <Loader/> : (
                        <Fragment>
                            <table className="table mt-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">
                                            <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                        </th>
                                        <th scope="col">Product name</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Stock</th>
                                        <th className="text-center" scope="col">Action</th>
                                    </tr>
                                </thead>
                                {products.forEach(product => rowData.push(
                                    <tr className="bg-white" key={product._id}>
                                        <td><div style={{backgroundImage: `url('${product.images[0].url}')`, backgroundSize: 'cover'}} className="cart-img3 border rounded"></div></td>
                                        <td className="text-size-xs">{product.name}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(product.price)}</td>
                                        <td className="text-size-xs">{product.category}</td>
                                        <td className={`text-size-xs ${product.stock === 0 ? 'red-color' : 'green-color'}`}>{product.stock}</td>
                                        <td className="text-center"><Link to={`/products/${product._id}`} state={{ from: "seller" }} className="table-btn">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { products.length === 0 ? (
                                <div className="text-center py-3 w-100">
                                    <h3 className='text-size-md'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>
                    )} */}
                </div>
            </div>

        </Fragment>
    )
}

export default AdminPayments