export const ALL_PRODUCTS_REQUEST = 'ALL_PRODUCTS_REQUEST'
export const ALL_PRODUCTS_SUCCESS = 'ALL_PRODUCTS_SUCCESS'
export const ALL_PRODUCTS_FAIL = 'ALL_PRODUCTS_FAIL'

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'

export const NEW_PRODUCT_REQUEST = 'NEW_PRODUCT_REQUEST'
export const NEW_PRODUCT_SUCCESS = 'NEW_PRODUCT_SUCCESS'
export const NEW_PRODUCT_RESET = 'NEW_PRODUCT_RESET'
export const NEW_PRODUCT_FAIL = 'NEW_PRODUCT_FAIL'

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL'
export const UPDATE_PRODUCT_RESET = 'UPDATE_PRODUCT_RESET'

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'
export const DELETE_PRODUCT_RESET = 'DELETE_PRODUCT_RESET'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'