export const ALL_FARMS_REQUEST = 'ALL_FARMS_REQUEST'
export const ALL_FARMS_SUCCESS = 'ALL_FARMS_SUCCESS'
export const ALL_FARMS_FAIL = 'ALL_FARMS_FAIL'

export const FARM_DETAILS_REQUEST = 'FARM_DETAILS_REQUEST'
export const FARM_DETAILS_SUCCESS = 'FARM_DETAILS_SUCCESS'
export const FARM_DETAILS_RESET = 'FARM_DETAILS_RESET'
export const FARM_DETAILS_FAIL = 'FARM_DETAILS_FAIL'

export const UPDATE_FARM_REQUEST = 'UPDATE_FARM_REQUEST'
export const UPDATE_FARM_SUCCESS = 'UPDATE_FARM_SUCCESS'
export const UPDATE_FARM_FAIL = 'UPDATE_FARM_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'