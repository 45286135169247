import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'


import SellerNavigation from '../layout/sellerNavigation'


const SellerInsurance = () => {

    return (
        <Fragment>
            <Metadata title={'Insurance'}/>
            <SellerNavigation Insurance/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-md text-muted mb-5">ACTIVE INSURANCE</h3>

                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="d-flex flex-column bg-white shadow-sm p-4">
                                <span className=" text-size-sm">Farm Insurance</span>
                                <h1 className="text-size-lg my-3">&#x20A6;40,000</h1>
                                <span className=" text-size-xs mb-3">Expires: 22nd April, 2024</span>
                                {/* <button className="text-size-xs py-2 border-btn my-2">Upgrade Insurance</button> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-column bg-white shadow-sm p-4">
                                <span className=" text-size-sm">Golden Insurance</span>
                                <h1 className="text-size-lg my-3">&#x20A6;200,000</h1>
                                <span className=" text-size-xs mb-3">Expires: 4th Nov, 2026</span>
                                {/* <button className="text-size-xs py-2 border-btn my-2">Upgrade Insurance</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="text-size-xs green-btn py-2 mt-5"><Link to="/seller/insurance/offers" className="white-color">Buy Insurance Plan</Link></div>
                </div>
            </div>

        </Fragment>
    )
}

export default SellerInsurance