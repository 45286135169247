import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

import { productReducer, modifyProductReducer, productDetailsReducer } from './reducers/productReducers'
import { storageReducer, newStorageReducer, storageDetailsReducer } from './reducers/storageReducers'
import { getRequestsReducer, newRequestReducer, requestDetailsReducer } from './reducers/requestReducers'
import { getLogsReducer } from './reducers/logsReducers'
import { authReducer, allUsersReducer, updateUserReducer, userDetailsReducer, respondToUserReducer, forgotPasswordReducer } from './reducers/userReducers'
import { allFarmsReducer, farmDetailsReducer, updateFarmReducer } from './reducers/farmReducers'
import { cartReducer } from './reducers/cartReducers'
import { modifyOrderReducer, getOrdersReducer, orderDetailsReducer  } from './reducers/orderReducers'
import { loanReducer, modifyLoanReducer, loanDetailsReducer } from './reducers/loanReducers'
import { getNotificationsReducer } from './reducers/notificationReducers'
import { getDefaultsReducer, modifyDefaultsReducer } from './reducers/defaultsReducers'

const reducer = combineReducers({
    products: productReducer,
    modifyProduct: modifyProductReducer,
    productDetails: productDetailsReducer,
    getStorages: storageReducer,
    newStorage: newStorageReducer,
    storageDetails: storageDetailsReducer,
    getLogs: getLogsReducer,
    getRequests: getRequestsReducer,
    newRequest: newRequestReducer,
    requestDetails: requestDetailsReducer,
    auth: authReducer,
    allUsers: allUsersReducer,
    updateUser: updateUserReducer,
    userDetails: userDetailsReducer,
    loans: loanReducer,
    modifyLoan: modifyLoanReducer,
    loanDetails: loanDetailsReducer,
    allFarms: allFarmsReducer,
    farmDetails: farmDetailsReducer,
    updateFarm: updateFarmReducer,
    response: respondToUserReducer,
    cart: cartReducer,
    modifyOrder: modifyOrderReducer,
    getOrders: getOrdersReducer,
    orderDetails: orderDetailsReducer,
    getNotifications: getNotificationsReducer,
    forgotPassword: forgotPasswordReducer,
    getDefaults: getDefaultsReducer,
    modifyDefaults: modifyDefaultsReducer
})

let initialState = {
    cart: {
        cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
        shippingInfo: localStorage.getItem('shippingInfo') ? JSON.parse(localStorage.getItem('shippingInfo')) : {}
    }
}

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;