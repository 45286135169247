import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'

import { useDispatch, useSelector } from 'react-redux'
import { getAllFarms, clearErrors } from '../../actions/farmActions'

import { FARM_DETAILS_RESET } from '../../constants/farmConstants'

const AllFarms = () => {
    const dispatch = useDispatch();

    const { loading, error, farms } = useSelector(state => state.allFarms);

    useEffect(() => {
        dispatch(getAllFarms());
        dispatch({ type: FARM_DETAILS_RESET })

        if (error) {
            console.log(error, "ERRROORRRR!!")
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"All Farms"}/>
            <AdminNavigation Farms/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <div className="mid-container-header d-flex justify-content-between align-items-center">
                        <h3 className="text-size-sm text-muted d-inline-block m-0">FARMS</h3>
                        <span className="text-muted text-size-xs">{farms.length} farms</span>
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>
                            <table className="table mt-3">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">Farm</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Size (Hectares)</th>
                                        <th scope="col">Farmer</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>              
                                {farms.forEach(farm => rowData.push(
                                    <tr className="bg-white" key={farm._id}>
                                        <td className="text-size-xs">{farm.farmName}</td>
                                        <td className="text-size-xs">{farm.farmLocation}</td>
                                        <td className="text-size-xs">{farm.farmSize}</td>
                                        <td className="text-size-xs">{farm.farmer ? farm.farmer.firstName : "No farmer"} {farm.farmer ? farm.farmer.lastName : ""}</td>
                                        <td className="text-center"><Link to={`/admin/farms/${farm._id}`} className="table-btn">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { farms.length === 0 ? (
                                <div className="text-center py-3 w-100">
                                    <h3 className='text-size-sm'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>                              
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default AllFarms