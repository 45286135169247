import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'
import { costFormatter, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts } from '../../actions/productActions'
import { getAllOrders } from '../../actions/orderActions'
import { getAllUsers } from '../../actions/userActions'


const AdminDashboard = () => {
    const dispatch = useDispatch();
    const { products } = useSelector(state => state.products)
    const { orders, loading } = useSelector(state => state.getOrders)
    const { error, users } = useSelector(state => state.allUsers );

    const paidOrders = orders.filter(order => order.paidAt)

    let totalSales = 0;

    for (let order of paidOrders){
        totalSales += order.totalPrice;
    }

    useEffect(() => {
        dispatch(getAllProducts())
        dispatch(getAllOrders())
        dispatch(getAllUsers())
    }, [dispatch, error])

    return (
        <Fragment>
            <Metadata title={'Admin Dashboard'}/>
            <AdminNavigation Dashboard/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-md text-muted">ADMIN DASHBOARD</h3>

                    {loading ? <Loader/> : (
                        <Fragment>
                            <div className="row m-0 p-0 justify-content-between">
                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">&#x20A6;{numberWithCommas(costFormatter(totalSales))}</h3>
                                        <span className="dash-text-bottom">Total sales</span>
                                    </div>
                                    <div className="vertical-divider mx-3 mx-sm-5"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">&#x20A6;{numberWithCommas(costFormatter(0))}</h3>
                                        <span className="dash-text-bottom">Total withdrawal</span>
                                    </div>
                                </div>

                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(products ? (products.filter(product => product.stock > 0)).length : 0))}</h3>
                                        <span className="dash-text-bottom">Products in stock</span>
                                    </div>
                                    <div className="vertical-divider mx-3 mx-sm-5"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(products ? (products.filter(product => product.stock === 0)).length : 0))}</h3>
                                        <span className="dash-text-bottom">Products out of stock</span>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(orders ? (orders.filter(order => order.orderStatus === "Processing")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Orders in Process</span>
                                    </div>

                                    <div className="vertical-divider mx-2 mx-sm-3"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(orders ? (orders.filter(order => order.orderStatus === "Delivered")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Orders Delivered</span>
                                    </div>

                                    <div className="vertical-divider mx-2 mx-sm-3"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(orders ? (orders.filter(order => order.orderStatus === "Canceled")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Orders Canceled</span>
                                    </div>
                                </div>

                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(users ? (users.filter(user => user.role === "Buyer" && user.farmerStatus === "None" && user.vendorStatus === "None")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Buyers</span>
                                    </div>

                                    <div className="vertical-divider mx-3 mx-sm-4"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(users ? (users.filter(user => user.role === "Farmer")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Farmers</span>
                                    </div>

                                    <div className="vertical-divider mx-3 mx-sm-4"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(users ? (users.filter(user => user.role === "Vendor")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Vendors</span>
                                    </div>

                                    <div className="vertical-divider mx-3 mx-sm-4"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(users ? (users.filter(user => user.farmerStatus === "Pending" || user.vendorStatus === "Pending")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Pending</span>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>

        </Fragment>
    )
}

export default AdminDashboard