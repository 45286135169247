import { 
    GET_LOGS_REQUEST,
    GET_LOGS_SUCCESS,
    GET_LOGS_FAIL,
    CLEAR_ERRORS
} from '../constants/logsConstants'


export const getLogsReducer = (state = { logs: [] }, action) => {
    switch(action.type) {
        case GET_LOGS_REQUEST:
            return {
                loading: true,
                logs: []
            }

        case GET_LOGS_SUCCESS:
            return {
                loading: false,
                logs: action.payload
            }

        case GET_LOGS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}