import React , { useEffect } from 'react';
import { useDispatch,  useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { loadUser } from '../../actions/userActions';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../layout/loader'

const ProtectedRoute = ({children, isAdmin, isSeller}) => {

    const { isAuthenticated = false, loading = true, user } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!user) {
            dispatch(loadUser())
        }
    }, [isAuthenticated, loading])

    

    if (loading) return <Loader/>

    if (!loading && isAuthenticated) {
        <ToastContainer />
        if (isAdmin === true && (user.role !== 'Admin+' && user.role !== 'Admin')) {
            toast.error('You cannot access admin resource')
            return <Navigate to='/'/>
        }
        return children;
    } else {
        toast.error('Please signin first.')
        return <Navigate to={'/signin'} />
    }
    
};

export default ProtectedRoute