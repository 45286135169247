import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NEW_PRODUCT_RESET } from '../../constants/productConstants'
import { newProduct, clearErrors } from '../../actions/productActions'

import Metadata from '../layout/metadata'
import SellerNavigation from '../layout/sellerNavigation'


const AddProduct = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, success } = useSelector(state => state.modifyProduct)

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [packaging, setPackaging] = useState('');
    const [weight, setWeight] = useState('');
    const [stock, setStock] = useState('');
    const [images, setImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [specDocs, setSpecDocs] = useState([]);

    const categories = [
        'Aromatic Plants',
        'Aquatic',
        'Beverages and Juices',
        'Cereal',
        'Chemicals',
        'Dairy',
        'Edible Oils',
        'Essential Oils',
        'Equipment',
        'Fertilizers',
        'Flowers',
        'Fodders',  
        'Fruits',
        'Grains',
        'Herbal Products',
        'Livestock and Pets',
        'Nuts',
        'Poultry',
        'Seeds',
        'spices',
        'Tools',
        'Tubers',
        'Vegetables',
        'Vehicles',
        'Others'
    ]

    const packages = [
        'Aluminium Foil',
        'Basket',
        'Crate',
        'None',
        'Paper Box',
        'Plastic Box',
        'Wooden Box',
        'Poly Bag',
        'Others'
    ]


    useEffect(() => {
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error, "ERRORRR - addProduct.jsx")
            dispatch(clearErrors())
        }
        if (success) {
            navigate('/seller/inventory')
            toast.success("Successfully added product 👍")
            dispatch({ type: NEW_PRODUCT_RESET })
        }
    }, [dispatch, error, success])

    const submitHandler = (e) => {
        e.preventDefault();
 
        const formData = new FormData();

        formData.set('name', name);
        formData.set('category', category);
        formData.set('packaging', packaging);
        formData.set('price', price);
        formData.set('weight', weight);
        formData.set('stock', stock);
        formData.set('description', description);


        images.forEach(image => {
            formData.append('images', image)
        })
        specDocs.forEach(doc => {
            formData.append('docs', doc)
        })

        dispatch(newProduct(formData));
    }

    const onChange = e => {
        const files = Array.from(e.target.files)

        setImagesPreview([])
        setImages([])

        if (files.length > 5) {
            e.target.value = "";
            return toast.error(`You can select a maximum of 5 files.`);
          }

        setImages(files)

        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImagesPreview(oldArray => [...oldArray, reader.result])
                }
            }
            reader.readAsDataURL(file)
        })

    }

  return (
    <Fragment>
        <Metadata title={'Add Product'} />
        <SellerNavigation Inventory/>
        <ToastContainer />
        <div className="container-md green-color my-4 ps-4 ps-md-2">
            <Link to="/seller/inventory" className="green-color">{"< Back to Inventory"}</Link>
        </div>
        <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

            <h3 className="text-size-md text-muted">ADD PRODUCT</h3>
            <hr className="my-4" />
            <form onSubmit={submitHandler} encType="multipart/form-data">

                <div className="row">

                    {/* PRODUCT NAME */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="name" className="form-label">Product Name</label><i className="red-color">*</i>
                    <strong><i id="nameHelp" className="ms-2 red-color text-size-xxs">(Cannot exceed 50 characters)</i></strong>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="name"
                            name='name'
                            maxLength={50}
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            aria-describedby="nameHelp" 
                            placeholder="E.g. Tomatoes"
                            required
                            readOnly={loading}
                        />
                    </div>

                    {/* PRODUCT CATEGORY */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="category" className="form-label">Product Category</label><i className="red-color">*</i>
                        <select 
                            name="category" 
                            id="category" 
                            className="form-control"
                            value={category} 
                            onChange={(e) => setCategory(e.target.value)}
                            required
                            disabled={loading}
                            >
                            <option value="">Choose a Product Category</option>
                            {categories.map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>
                    </div>

                    {/* PRODUCT PACKAGING */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="packaging" className="form-label">Product Packaging</label><i className="red-color">*</i>
                        <select 
                            name="packaging" 
                            id="packaging" 
                            className="form-control"
                            value={packaging} 
                            onChange={(e) => setPackaging(e.target.value)}
                            required
                            disabled={loading}
                            >
                            <option value="">Choose Packaging Type</option>
                            {packages.map(packaging => (
                                <option key={packaging} value={packaging}>{packaging}</option>
                            ))}
                        </select>
                    </div>

                    {/* PRODUCT PRICE */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="price" className="form-label">Product Price (&#x20A6;)</label><i className="red-color">*</i>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="price" 
                            name='price' 
                            value={price} 
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="E.g. 37000"
                            required
                            readOnly={loading}
                        />
                    </div>

                    {/* PRODUCT WEIGHT */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="weight" className="form-label">Product Weight (Kg)</label><i className="red-color">*</i>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="weight" 
                            name='weight'
                            value={weight} 
                            onChange={(e) => setWeight(e.target.value)}
                            placeholder="E.g. 85"
                            required
                            readOnly={loading}
                        />
                    </div>

                    {/* UNITS IN STOCK */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="stock" className="form-label">Product Stock (Units in Stock)</label><i className="red-color">*</i>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="stock" 
                            name='stock'
                            value={stock} 
                            onChange={(e) => setStock(e.target.value)}
                            placeholder="E.g. 50"
                            required
                            readOnly={loading}
                        />
                    </div>

                    {/* PRODUCT DESCRIPTION */}
                    <div className="col-12 mb-4">
                        <label htmlFor="description" className="form-label">Product Description</label><i className="red-color">*</i>
                        <textarea 
                            className="form-control" 
                            id="description" 
                            name='description'
                            rows="5" 
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Describe the product/equipment..."
                            required
                            readOnly={loading}
                        />
                    </div>

                    {/* PRODUCT IMAGE */}
                    <div className="col-12 mb-4">
                        <label htmlFor="productImage" className="form-label mb-3">Product Images</label><i className="red-color">*</i>
                        <strong><i id="productImageHelp" className="ms-2 red-color text-size-xxs">(Max 5 images)</i></strong>
                        <div className="d-flex flex-column flex-md-row align-items-center">
                            <div className="d-flex mt-2 mt-md-0">
                                {imagesPreview.map(image => (
                                    <figure key={image} className="m-0 me-md-2 p-0">
                                        <img 
                                            src={image}
                                            alt="Product Images Preview"
                                            key={image}
                                            className="preview" 
                                        />
                                    </figure>
                                ))}
                            </div>

                            <div className="border rounded mt-3 mt-md-0 mx-md-2 p-2 w-100">
                                <input 
                                    type="file"
                                    name="images"
                                    className="form-control text-size-xs w-100"
                                    id="images"
                                    onChange={onChange}
                                    multiple
                                    accept="image/*"
                                    required
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>

                    {/* PRODUCT SPECIFICATION */}
                    <div className="col-12 mb-4">
                        <label htmlFor="specs" className="form-label mb-3">Product Specification Document - PDF only</label><i className="text-muted text-size-xs">(Optional)</i>
                        <input 
                            type="file"
                            name="docs"
                            className="form-control text-size-xs w-100 p-2 border"
                            id="docs"
                            accept="image/png, image/jpeg, application/pdf"
                            multiple
                            onChange={(e) => setSpecDocs(Array.from(e.target.files))}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column align-items-end">
                    <button 
                            type="submit" 
                            className="green-btn my-3"
                            disabled={loading ? true : false}
                        >{loading ? "Adding product, please hold on..." : "Add product"}
                        </button>
                </div>

            </form>

        </div>
    </Fragment>
  )
}

export default AddProduct