import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Metadata from '../layout/metadata'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NEW_REQUEST_RESET } from '../../constants/requestConstants'
import { newRequest, clearErrors } from '../../actions/requestActions'

const RequestProduct = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, success } = useSelector(state => state.newRequest)

    useEffect(() => {
        if (error) {
            console.log(error)
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }
        if (success) {
            dispatch({ type: NEW_REQUEST_RESET })
            navigate('/requests')
            toast.success('Request created successfully. The admins have been notified of your request, thank you.')
        }
    }, [dispatch, error, success])

    const [request, setRequest] = useState({
        product: '',
        category: '',
        quantity: '',
        date: '',
        comment: ''
    })

    const { product, category, quantity, date, comment } = request;

    const categories = [
        'Aromatic Plants',
        'Aquatic',
        'Beverages and Juices',
        'Cereal',
        'Dairy',
        'Edible Oils',
        'Essential Oils',
        'Equipment',
        'Fertilizers',
        'Flowers',
        'Fodders',  
        'Fruits',
        'Grains',
        'Herbal Products',
        'Livestock and Pets',
        'Nuts',
        'Poultry',
        'Seeds',
        'spices',
        'Tools',
        'Tubers',
        'Vegetables',
        'Others'
    ]

    const onChange = e => {
        setRequest({...request, [e.target.name]: e.target.value })
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(newRequest(request));
    }

    const goBack = () => {
        navigate(-1)
    }

  return (
    <Fragment>
        <Metadata title={'Request a Product'} />
        <ToastContainer />
        <div className="container-md green-color my-4 ps-4 ps-md-2">
            <a href="#" onClick={goBack} className="green-color">{"< Go Back"}</a>
        </div>

        <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

            <h3 className="text-size-md text-muted">Request a Product</h3>
            <hr className="my-3" />

            <span className="red-color">*<i className="text-size-xxs bold-2">This form is for special requests only. Please look through the store to ensure that your request is not already available</i></span>
            <form onSubmit={submitHandler}>

                <div className="row mt-4">

                    {/* PRODUCT NAME */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="product" className="form-label">Product Name</label><i className="red-color">*</i>
                    <i id="productNameHelp" className="ms-2 text-muted text-size-xxs">(Cannot exceed 40 characters)</i>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="product"
                            name='product'
                            value={product} 
                            onChange={onChange}
                            aria-describedby="productNameHelp" 
                            placeholder="E.g. Fresh Tomatoes"
                            required
                        />
                    </div>

                    {/* PRODUCT CATEGORY */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="category" className="form-label">Product Category</label><i className="red-color">*</i>
                        <select 
                            name="category" 
                            id="category" 
                            className="form-control"
                            value={category} 
                            onChange={(onChange)}
                            required
                            >
                            <option value="">Choose a Product Category</option>
                            {categories.map(category => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>
                    </div>

                    {/* PRODUCT QUANTITY */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="quantity" className="form-label">Product Quantity</label><i className="red-color">*</i>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="quantity" 
                            name='quantity' 
                            value={quantity} 
                            onChange={(onChange)}
                            placeholder="E.g. 50"
                            required
                        />
                    </div>

                    {/* DATE NEEDED */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="date" className="form-label">Date Needed</label><i className="red-color">*</i>
                        <input 
                            type="date" 
                            className="form-control" 
                            id="date" 
                            name='date' 
                            value={date} 
                            onChange={(onChange)}
                            placeholder="E.g. 50"
                            required
                        />
                    </div>

                    {/* COMMENT */}
                    <div className="col-12 mb-4">
                        <label htmlFor="comment" className="form-label">Comment</label><i className="text-size-xs text-muted">(Optional)</i>
                        <textarea 
                            className="form-control" 
                            id="comment" 
                            name='comment'
                            rows="5" 
                            value={comment} 
                            onChange={(onChange)}
                            placeholder="Enter any comments you may have here..."
                        />
                    </div>

                    <button 
                        type="submit" 
                        className="text-size-xs green-btn my-3 ms-auto me-3"
                        disabled={loading ? true : false}
                    >Send Request
                    </button>
                </div>
            </form>

        </div>
    </Fragment>
  )
}

export default RequestProduct