import axios from 'axios';
import { 
    ALL_FARMS_REQUEST,
    ALL_FARMS_SUCCESS,
    ALL_FARMS_FAIL,
    FARM_DETAILS_REQUEST,
    FARM_DETAILS_SUCCESS,
    FARM_DETAILS_FAIL,
    UPDATE_FARM_REQUEST,
    UPDATE_FARM_SUCCESS,
    UPDATE_FARM_FAIL,
    CLEAR_ERRORS
} from '../constants/farmConstants'

// GET ALL FARMS
export const getAllFarms = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_FARMS_REQUEST })

        const { data } = await axios.get('/api/v1/admin/farms')

        dispatch({
            type: ALL_FARMS_SUCCESS,
            payload: data.farms
        })
    } catch (error) {
        dispatch({
            type: ALL_FARMS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getAdminFarmDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: FARM_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/admin/farms/${id}`)

        dispatch({
            type: FARM_DETAILS_SUCCESS,
            payload: data.farm
        })
    } catch (error) {
        dispatch({
            type: FARM_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getFarmDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: FARM_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/farms/${id}`)
        dispatch({
            type: FARM_DETAILS_SUCCESS,
            payload: data.farm
        })
    } catch (error) {
        dispatch({
            type: FARM_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updateFarmDetails = (update, id) => async (dispatch) => {

    try {
        dispatch({
            type: UPDATE_FARM_REQUEST,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/farms/${id}`, update, config)

        dispatch({
            type: UPDATE_FARM_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_FARM_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}