import axios from 'axios';
import {
    GET_REQUEST_REQUEST,
    GET_REQUEST_SUCCESS,
    GET_REQUEST_FAIL,
    NEW_REQUEST_REQUEST,
    NEW_REQUEST_SUCCESS,
    NEW_REQUEST_FAIL,
    REQUEST_DETAILS_REQUEST,
    REQUEST_DETAILS_SUCCESS,
    REQUEST_DETAILS_FAIL,
    UPDATE_REQUEST_REQUEST,
    UPDATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_FAIL,
    CLEAR_ERRORS
} from '../constants/requestConstants'

export const newRequest = (requestData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_REQUEST_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/requests/new', requestData, config)

        dispatch({
            type: NEW_REQUEST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_REQUEST_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getAllRequests = () => async (dispatch) => {
    try {
        dispatch({ type: GET_REQUEST_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/requests`)

        dispatch({
            type: GET_REQUEST_SUCCESS,
            payload: data.requests
        })
    } catch (error) {
        dispatch({
            type: GET_REQUEST_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getUserRequests = () => async (dispatch) => {
    try {
        dispatch({ type: GET_REQUEST_REQUEST })

        const { data } = await axios.get(`/api/v1/requests`)

        dispatch({
            type: GET_REQUEST_SUCCESS,
            payload: data.requests
        })
    } catch (error) {
        dispatch({
            type: GET_REQUEST_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getAdminRequestDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: REQUEST_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/admin/requests/${id}`)

        dispatch({
            type: REQUEST_DETAILS_SUCCESS,
            payload: data.request
        })
    } catch (error) {
        dispatch({
            type: REQUEST_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getRequestDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: REQUEST_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/requests/${id}`)

        dispatch({
            type: REQUEST_DETAILS_SUCCESS,
            payload: data.request
        })
    } catch (error) {
        dispatch({
            type: REQUEST_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updateRequestStatus = (status, id) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_REQUEST_REQUEST,
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/v1/admin/requests/${id}`, {status: status}, config)

        dispatch({
            type: UPDATE_REQUEST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_REQUEST_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}