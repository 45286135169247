import React, { Fragment, useEffect, useState } from 'react'
import Metadata from '../layout/metadata'

// import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getStorageDetails, updateStorage, clearErrors } from '../../actions/storageActions'

import { useParams, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../layout/loader'
import { formatDate } from '../../Functions/functions'

const AdminStorageDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    // const alert = useAlert()

    const { storage, error, loading } = useSelector(state => state.storageDetails)
    const updateLoading = useSelector(state => state.newStorage).loading

    let inStorage = storage.quantity;
    let totalRetrieved = 0;

    if (storage.retrieved) {
        totalRetrieved = storage.retrieved.map(item => item.quantity)
        if (totalRetrieved.length > 0) {
            totalRetrieved = totalRetrieved.reduce((a,c) => a + c)
        }
        inStorage = storage.quantity - totalRetrieved
    }

    let [retrieveNo, setRetrieveNo] = useState("");



    useEffect(() => {
        dispatch(getStorageDetails(params.id))

        if (error) {
            console.log(error, "ERRROOORR!! - order/AdminOrderDetails.jsx")
            // alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const onChange = (e) => {
        let choice = e.target.value
        if (e.target.value) {
            choice = parseInt(choice)
            choice > inStorage || choice < 0 ? setRetrieveNo("") : setRetrieveNo(choice)
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateStorage(retrieveNo, params.id));
        console.log("done")
        window.location.reload(true);
        // navigate(`/admin/orders/${id}`)
    }

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Order Details'}/>

            {loading ? <Loader/> : (
                <Fragment>
                    {/* USERS SHOW PAGE */}

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/admin/storage" className="green-color">{"< Back to Storage"}</Link>
                    </div>
                    <div className="container-md white-bg shadow-lg mb-5 pt-5">
                    <h3 className="container-md dash-header mt-1 ps-0 text-muted">STORAGE DETAILS</h3>
                    <hr className="mb-5"/>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="text-size-sm">Warehouse: <strong>{storage.location}</strong></p>
                            <p className="text-size-sm">Product: <strong>{storage.product}</strong></p>
                            <p className="text-size-sm">Owner: <strong>{storage.seller ? storage.seller.firstName + " " + storage.seller.lastName : ""}</strong></p>
                            <p className="text-size-sm">Shelf: <strong>{storage.shelf}</strong></p>
                            <p className="text-size-sm">Stored: <strong>{storage.quantity} {storage.packaging} ({storage.createdAt ? formatDate(storage.createdAt) : ""})</strong></p>
                            <div className="d-flex">
                                <p className="text-size-sm">Retrieved: </p>
                                <div className="ms-2">
                                    {storage.retrieved ? (
                                        storage.retrieved.forEach(item => rowData.push(
                                        <strong key={item.date} className="d-block text-size-sm">{item.quantity} {storage.packaging} ({formatDate(item.date)})</strong>
                                    ))) : <Fragment></Fragment>}
                                    {rowData}
                                </div>
                            </div>
                            <p className="text-size-sm mt-3">In Storage: <strong>{inStorage} {storage.packaging}</strong></p>
                            <p className="text-size-sm">Admin: <strong>{storage.user ? storage.user.firstName + " " + storage.user.lastName : ""}</strong></p>

                        </div>

                        <hr className="mt-2 mb-4 d-md-none"/>
                        <div className="col-md-6">
                            <h5 className="text-size-sm">Retrieve Products</h5>
                            <hr className="mt-1 mb-2 d-none d-md-block"/>
                            <form onSubmit={submitHandler}>
                                <label htmlFor="retrieve" className="text-size-xs mb-2">How many products do you want to take from Storage? ({storage.packaging})</label>
                                <input 
                                    id="retrieve" 
                                    name="retrieve" 
                                    type="number"
                                    min="0"
                                    value={retrieveNo}
                                    onChange={onChange}
                                    className="form-control" 
                                    placeholder={`Max ${inStorage}`}
                                    required/>
                                {updateLoading ? (
                                    <i className="d-block text-muted text-size-xxs">Updating storage...</i>
                                ):(<Fragment></Fragment>)}
                                    
                                <button type="submit" className="green-btn text-size-xs mt-3 py-2 px-3" disabled={retrieveNo === "" || updateLoading }>Retrieve</button>
                            </form>
                        </div>
                    </div>
                        
                        {/* <div className="d-flex justify-content-between align-items-center my-4">
                            <h3 className="text-size-md m-0">Products in Order</h3>
                            {order.orderItems ? (
                                <span className="text-size-sm text-muted">({order.orderItems.length} Products)</span>
                            ):(<Fragment></Fragment>)}
                        </div> */}
                
                        {/* <table className="table">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">
                                        <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                    </th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            {order.orderItems ? (
                                order.orderItems.forEach(item => rowData.push(
                                    <tr className="bg-white" key={item._id}>
                                        <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="cart-img3 border rounded"></div></td>
                                        <td className="text-size-xs">{item.name}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(item.price)}</td>
                                        <td className="text-size-xs">{numberWithCommas(item.quantity)}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                    </tr>                                 
                                ))
                            ):(<Fragment></Fragment>)}

                            <tbody>{rowData}</tbody>
                        </table> */}

                        {/* {order.shippingInfo ? (
                            <Fragment>
                                <h3 className="text-size-md mt-5 mb-4">Shipping Infromation</h3>
                                <p className="text-size-sm">Address: <strong>{order.shippingInfo.address}</strong></p>
                                <p className="text-size-sm">City: <strong>{order.shippingInfo.city}</strong></p>
                                <p className="text-size-sm">Postal Code: <strong>{order.shippingInfo.postalCode}</strong></p>
                                <p className="text-size-sm">Country: <strong>{order.shippingInfo.country}</strong></p>
                                <p className="text-size-sm">Phone Number: <strong>{order.shippingInfo.phoneNo}</strong></p>
                            </Fragment>
                        ):(<Fragment></Fragment>)} */}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default AdminStorageDetails