import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'


const Register = () => {


    return (
        <Fragment>
            <Metadata title={'Register'} />
            <div className="container-md mid-container text-center shadow-lg">
                <h2 className="text-size-lg mb-5">How would you like to sign up?</h2>
                <Link to="/register/buyer" className="green-btn d-block mx-auto mb-3 w-75 p-3">As a Buyer</Link>
                <Link to="/register/farmer" className="green-btn d-block mx-auto mb-3 w-75 p-3">As a Farmer</Link>
                <Link to="/register/vendor" className="green-btn d-block mx-auto mb-3 w-75 p-3">As a Vendor</Link>
            </div>
        </Fragment>
    )}

export default Register