import { 
    NEW_REQUEST_REQUEST,
    NEW_REQUEST_SUCCESS,
    NEW_REQUEST_RESET,
    NEW_REQUEST_FAIL,
    GET_REQUEST_REQUEST,
    GET_REQUEST_SUCCESS,
    GET_REQUEST_FAIL,
    REQUEST_DETAILS_REQUEST,
    REQUEST_DETAILS_SUCCESS,
    REQUEST_DETAILS_FAIL,
    UPDATE_REQUEST_REQUEST,
    UPDATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_FAIL,
    CLEAR_ERRORS
} from '../constants/requestConstants'

export const newRequestReducer = (state = { request: {} }, action) => {
    switch(action.type) {
        case NEW_REQUEST_REQUEST:
        case UPDATE_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case NEW_REQUEST_SUCCESS:
        case UPDATE_REQUEST_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                request: action.payload.request

            }

        case NEW_REQUEST_FAIL:
        case UPDATE_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case NEW_REQUEST_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const getRequestsReducer = (state = { requests: [] }, action) => {
    switch(action.type) {
        case GET_REQUEST_REQUEST:
            return {
                loading: true,
                requests: []
            }

        case GET_REQUEST_SUCCESS:
            return {
                loading: false,
                requests: action.payload
            }

        case GET_REQUEST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const requestDetailsReducer = (state = {request: {}}, action) => {
    switch (action.type) {
        case REQUEST_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case REQUEST_DETAILS_SUCCESS:
            return {
                loading: false,
                request: action.payload
            }
        case REQUEST_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}