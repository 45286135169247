import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { registerVendor, clearErrors } from '../../actions/userActions'
import { REGISTER_USER_RESET } from '../../constants/userConstants'

const RegisterVendor = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        credential: '',
        address: '',
        ageGroup: '',
        gender: '',
        password: '',
    })

    const { firstName, lastName, credential, address, ageGroup, gender, password } = user;

    let [vendorType, setVendorType] = useState([])
    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('/images/default-avatar.png')

    const { isAuthenticated, isSuccess, error, loading } = useSelector(state => state.auth);

    useEffect(() => {

        if (isAuthenticated) {
            navigate('/')
        }

        if (isSuccess) {
            navigate('/signin')
            toast.success("Account successfully created, Please verify your email before you can sign in")
            dispatch({ type: REGISTER_USER_RESET })
        }

        if (error) {
            if (error.includes("E11000")){
                toast.error("This user already exists. Please use a different email address or go to sign in.")
            } else toast.error(error)
            console.log(error, "ERROR!")
            dispatch(clearErrors());
        }

    }, [dispatch, isSuccess, isAuthenticated, error])

    const submitHandler = (e) => {
        e.preventDefault();

        if (credential.length === 0) return toast.error("Please enter a valid credential")
        else if (credential.charAt(0).match(/[a-z]/i) && !credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/)) return toast.error("Please enter a valid email address")
        else if (!isNaN(parseInt(credential.charAt(0))) || (credential.charAt(0) === "+" && (isNaN(parseInt(credential.slice(1))) || credential.length < 8))) return toast.error("Please enter a valid phone number")
        else if (vendorType.length === 0) return toast.error("Please choose what you sell")

        const formData = new FormData();

        formData.set('firstName', firstName);
        formData.set('lastName', lastName);
        formData.set('credential', credential);
        formData.set('address', address);
        formData.set('ageGroup', ageGroup)
        formData.set('gender', gender);
        formData.set('password', password);
        formData.set('avatar', avatar);
        formData.set('vendorType', vendorType)

        dispatch(registerVendor(formData));
    }

    const onChange = e => {
        if(e.target.name === 'avatar'){
            
            const file = e.target.files[0]
            const reader = new FileReader();

            reader.onload = (e) => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                    setAvatar(reader.result)
                }
            }
            reader.readAsDataURL(file)
        } else {
            setUser({...user, [e.target.name]: e.target.value })
        }
    }

        
    function arrayRemove(arr, value) { 
        return arr.filter(function(element){ 
            return element != value; 
        });
    }

    const onChecked = e => {
        if (e.target.checked) {
            vendorType.push(e.target.value)
        }else{
            vendorType = arrayRemove(vendorType, e.target.value)
        }
        setVendorType(vendorType)

    }

  return (
    <Fragment>
        <Metadata title={'Register as a Vendor'} />
        <ToastContainer />
            <div className="container-md mid-container shadow-lg p-0">
                <div className="row">
                    <div className="col-md-8 p-5 order-2 order-md-1">
                        <form className="px-2" onSubmit={submitHandler} encType="multipart/form-data">
                            <Link to={"/register"} className="green-color text-size-xs">{"< Back to Sign Up"}</Link>
                            <h3 className="text-size-lg mb-5 mt-4">Sign up as a Vendor</h3>

                            <div className="row">

                                {/* FIRST NAME */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="first-name" className="form-label">First Name</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="first-name"
                                        name='firstName' 
                                        value={firstName}
                                        onChange={onChange}
                                        aria-describedby="firstNameHelp" 
                                        placeholder="E.g. John"
                                        required
                                    />
                                </div>

                                {/* LAST NAME */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="last-name" className="form-label">Last Name</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="last-name" 
                                        name='lastName' 
                                        value={lastName}
                                        onChange={onChange}
                                        aria-describedby="lastNameHelp" 
                                        placeholder="E.g. Smith"
                                        required
                                    />
                                </div>


                                {/* CREDENTIAL */}
                                <div className="col-12 mb-4">
                                    { !isNaN(parseInt(credential.charAt(0))) ? "set +": ""}
                                    <label htmlFor="credential" className="form-label">Email address or Phone number</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="credential" 
                                        name='credential' 
                                        value={credential}
                                        onChange={onChange}
                                        aria-describedby="credentialHelp" 
                                        placeholder="E.g. john@email.com or +2348012345678"
                                        required
                                    />
                                    <div id="credentialHelp" className="text-size-xxs mt-2 red-color">
                                        {   credential.length === 0 ? "Please enter a valid credential"
                                            : credential.charAt(0).match(/[a-z]/i) ? (credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/) ? <span className="green-color">A verification code will be sent to this email</span> : "Please enter a valid email address")
                                            :!isNaN(parseInt(credential.charAt(0))) ? "Phone number must have country code. E.g. +234, +1, etc."
                                            : credential.charAt(0) === "+" && credential.length < 8 ? "Keep typing..." 
                                            : isNaN(parseInt(credential.slice(1))) ? "Enter a valid phone number"
                                            : <span className="green-color">A verification code will be sent to this phone number</span>
                                        }
                                    </div>
                                </div>

                                {/* ADDRESS */}
                                <div className="col-12 mb-4">
                                    <label htmlFor="address" className="form-label">Address</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="address" 
                                        name='address' 
                                        value={address}
                                        onChange={onChange}
                                        aria-describedby="addressHelp" 
                                        placeholder="E.g. 18. Village Road, Gishiri, Abuja"
                                        required
                                    />
                                </div>

                                {/* AGE GROUP */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="ageGroup" className="form-label">Age group</label> <span className="red-color">*</span>
                                    <select 
                                        name="ageGroup" 
                                        id="ageGroup" 
                                        className="form-control"
                                        value={ageGroup} 
                                        onChange={onChange}
                                        required
                                        >
                                        <option value="">Select age group</option>
                                        <option value="10 - 18">10 - 18</option>
                                        <option value="19 - 25">19 - 25</option>
                                        <option value="26 - 35">26 - 35</option>
                                        <option value="36 - 50">36 - 50</option>
                                        <option value="50+">50+</option>
                                    </select>
                                </div>

                                    {/* GENDER */}
                                    <div className="col-md-6 mb-4">
                                        <label htmlFor="gender" className="form-label">Gender</label> <span className="red-color">*</span>
                                        <select 
                                            name="gender" 
                                            id="gender" 
                                            className="form-control"
                                            value={gender} 
                                            onChange={onChange}
                                            required
                                            >
                                            <option value="">Select gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>

                                {/* PASSWORD */}
                                <div className="col-12 mb-4">
                                    <label htmlFor="Password" className="form-label">Password</label> <span className="red-color">*</span>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="Password" 
                                        name='password'
                                        minLength="6" 
                                        value={password}
                                        onChange={onChange}
                                        placeholder="**********"
                                    />
                                    <div id="passHelp" className={`text-size-xxs mt-2 ${password.length >= 6 ? "green-color" : "red-color"}`}>Password must have a minimum of 6 characters</div>
                                </div>

                                {/* VENDOR TYPE */}
                                <h5 className="form-label mb-3">What do you sell? <span className="red-color">*</span></h5>
                                <div className="col-12 mb-3">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="farm equipment" name="vendorType" value="Farm Equipment" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="farm equipment">Farm Equipment</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="farm-inputs" name="vendorType" value="Farm Inputs" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="farm-inputs">Farm Inputs</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="farm-produce" name="vendorType" value="Farm Produce" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="farm-produce">Farm Produce</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="farm-services" name="vendorType" value="Farm Services" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="farm-services">Farm Services</label>
                                    </div>
                                </div>

                                {/* AVATAR */}
                                <div className="col-12 mb-3">
                                    <label htmlFor="avatar-upload" className="form-label">Avatar</label> <i className="text-size-xs">(optional)</i>
                                    <div className="d-flex align-items-center">
                                        <figure className="m-0 p-0">
                                            <img 
                                                src={avatarPreview} 
                                                alt="Avatar Preview"
                                                className="preview" 
                                            />
                                        </figure>
                                        <div className="border rounded mx-2 p-2 w-100">
                                            <input 
                                                type="file"
                                                name="avatar"
                                                className="custom-file-input text-size-xs"
                                                id="customFile" 
                                                accept="image/png, image/jpeg"
                                                onChange={onChange}
                                            />
                                            <label htmlFor="customFile" className="text-size-xxs text-muted">Choose Avatar</label>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <button 
                                type="submit" 
                                id="register-button"
                                className="green-btn my-4 w-100"
                                disabled={loading ? true : false}
                            >{loading ? "Creating your account, please wait..." : "Create Account"}
                            </button>
                            <div className="form-label text-center">By creating an account, you agree to the Farm Warehouse’s</div>
                            <div className="text-center"><Link to="/terms" className="green-link">Terms and Conditions and Privacy Policy</Link></div>
                            <div className="form-label text-center mt-3">Already have a farmwarehouse account? <Link to="/signin" className="text-end green-link">Sign In</Link></div>
                        </form>
                    </div>
                    <div className="col-md-4 order-1 order-md-2 text-end" style={{backgroundImage: "url('/images/sign-in-image.png')", backgroundSize: "cover"}}>
                        <img src="/images/fm-logo-white.png" className="white-logo my-4 me-4" alt="Farmwarehouse Logo"/>
                    </div>
                </div>
            </div>    
    </Fragment>
  )
}

export default RegisterVendor