export const GET_LOANS_REQUEST = 'GET_LOANS_REQUEST'
export const GET_LOANS_SUCCESS = 'GET_LOANS_SUCCESS'
export const GET_LOANS_FAIL = 'GET_LOANS_FAIL'

export const NEW_LOAN_REQUEST = 'NEW_LOAN_REQUEST'
export const NEW_LOAN_SUCCESS = 'NEW_LOAN_SUCCESS'
export const NEW_LOAN_RESET = 'NEW_LOAN_RESET'
export const NEW_LOAN_FAIL = 'NEW_LOAN_FAIL'

export const UPDATE_LOAN_REQUEST = 'UPDATE_LOAN_REQUEST'
export const UPDATE_LOAN_SUCCESS = 'UPDATE_LOAN_SUCCESS'
export const UPDATE_LOAN_FAIL = 'UPDATE_LOAN_FAIL'
export const UPDATE_LOAN_RESET = 'UPDATE_LOAN_RESET'

export const DELETE_LOAN_REQUEST = 'DELETE_LOAN_REQUEST'
export const DELETE_LOAN_SUCCESS = 'DELETE_LOAN_SUCCESS'
export const DELETE_LOAN_FAIL = 'DELETE_LOAN_FAIL'
export const DELETE_LOAN_RESET = 'DELETE_LOAN_RESET'

export const LOAN_DETAILS_REQUEST = 'LOAN_DETAILS_REQUEST'
export const LOAN_DETAILS_SUCCESS = 'LOAN_DETAILS_SUCCESS'
export const LOAN_DETAILS_RESET = 'LOAN_DETAILS_RESET'
export const LOAN_DETAILS_FAIL = 'LOAN_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'