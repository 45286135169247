import React, { Fragment, useEffect } from 'react'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import SellerNavigation from '../layout/sellerNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getSellerStorage, clearErrors } from '../../actions/storageActions'

const SellerStorage = () => {
    const dispatch = useDispatch();

    const { loading, error, storages } = useSelector(state => state.getStorages);

    useEffect(() => {
        dispatch(getSellerStorage());

        if (error) {
            console.log(error, "ERRROORRRR!!")
            toast.error("Error fetching data, please check your internet connection and refresh the page.")
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const rowData = [];
    return (
        <Fragment>
            <Metadata title={'Seller Storages'}/>
            <SellerNavigation Storage/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <div className="mid-container-header d-flex justify-content-between align-items-center">
                        <h3 className="text-size-md text-muted d-inline-block m-0">STORAGE</h3>
                        <span className="text-muted text-size-xs">{storages.length} Items in storage</span>
                    </div>

                    <div className="d-flex">
                        <input 
                        className="form-control py-2" 
                        type="search" 
                        placeholder="Search storage" 
                        aria-label="Search"

                        />
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>
                        <table className="table mt-4">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th className="text-size-xs" scope="col">Warehouse</th>
                                    <th className="text-size-xs" scope="col">Product</th>
                                    <th className="text-size-xs" scope="col">Shelf</th>
                                    <th className="text-size-xs" scope="col">Owner</th>
                                    <th className="text-size-xs text-center" scope="col">Quantity</th>
                                    <th className="text-size-xs text-center" scope="col">Date Stored</th>
                                </tr>
                            </thead>
                            {storages.forEach(storage => rowData.push(
                                
                                <tr className="bg-white" key={storage._id}>
                                    <td className="text-size-xs">{storage.location}</td>
                                    <td className="text-size-xs">{storage.product}</td>
                                    <td className="text-size-xs">{storage.shelf}</td>
                                    <td className="text-size-xs">{storage.seller.firstName} {storage.seller.lastName}</td>
                                    <td className="text-size-xs text-center">{storage.quantity} {storage.packaging}</td>
                                    <td className="text-size-xs text-center">{storage.createdAt.split('T')[0]}</td>
                                </tr>                                 
                            ))}
                            <tbody>{rowData}</tbody>
                        </table>
                        { storages.length == 0 ? (
                            <div className="text-center py-3 w-100">
                                <h3 className='text-size-md'>NO DATA</h3>
                            </div>
                        ):(<Fragment></Fragment>)}
                    </Fragment>
                    )}
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    )
}

export default SellerStorage