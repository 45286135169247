import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'

import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers, clearErrors } from '../../actions/userActions'
import { formatDate } from '../../Functions/functions'

import { useDownloadExcel } from 'react-export-table-to-excel';

let clicked = "All";
let firstLoad = true;

const AllUsers = () => {

    const dispatch = useDispatch();

    const { loading, error, users } = useSelector(state => state.allUsers );
    let [ filteredUsers, setFilteredUsers ] = useState(users)

    if (filteredUsers.length < 1 && firstLoad) {filteredUsers = users};

    const tableRef = useRef(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'users',
        sheet: 'users'
    })

    useEffect(() => {
        dispatch(getAllUsers());
        if (error) {
            console.log(error)
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const rowData = [];

    const toggleTable = (role) => {
        firstLoad = false
        let filter
        clicked = role
        if (role === "All") {
            filter = users;
        } else if (role === "Buyers") {
            filter = users.filter(user => user.role === "Buyer" && user.farmerStatus === "None" && user.vendorStatus === "None")
        } else if (role === "Farmers") {
            filter = users.filter(user => user.role === "Farmer")
        } else if (role === "Vendors") {
            filter = users.filter(user => user.role === "Vendor")
        } else if (role === "Pending"){
            filter = users.filter(user => user.farmerStatus === "Pending" || user.vendorStatus === "Pending")
        } else {
            filter = []
        }

        setFilteredUsers(filter)
    }

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredUsers(users)
        }
        let matches = users.filter(user => user.firstName.toLowerCase().includes(e.target.value.toLowerCase()) || user.lastName.toLowerCase().includes(e.target.value.toLowerCase()) || (user.farm && user.farm.farmLocation.toLowerCase().includes(e.target.value.toLowerCase())))

        setFilteredUsers(matches);
        firstLoad = false;
    }

    return (
        <Fragment>
            <Metadata title={"All Users"}/>
            <AdminNavigation Users/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-sm text-muted">USERS</h3>
                    <div className="d-flex mb-3">
                        <input 
                        className="form-control w-50 me-2" 
                        type="search" 
                        placeholder="Search by name or location" 
                        aria-label="Search"
                        onChange={onSearchChange}
                        />
                        {/* DOWNLOAD TO EXCEL */}
                        <button className="green-btn text-size-xs" onClick={onDownload}>Export to excel</button>
                    </div>

                    <div className="d-flex flex-column flex-md-row justify-content-between border-bottom mb-4">
                        <span className="text-muted text-size-xs order-1 order-md-2">{filteredUsers.length} {clicked === 'All' ? 'Users' : clicked}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Buyers') }} className={`table-nav ${clicked === 'Buyers'?'table-active':''}`}>Buyers</button>
                            <button onClick={function() { toggleTable('Farmers') }} className={`table-nav ${clicked === 'Farmers'?'table-active':''}`}>Farmers</button>
                            <button onClick={function() { toggleTable('Vendors') }} className={`table-nav ${clicked === 'Vendors'?'table-active':''}`}>Vendors</button>
                            <button onClick={function() { toggleTable('Pending') }} className={`table-nav ${clicked === 'Pending'?'table-active':''}`}>Pending Approval</button>
                        </div>
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>
                            <table className="table mt-3" id="users-table" ref={tableRef}>
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">User</th>
                                        <th scope="col">Email/Phone</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col" className="text-center">Location</th>  
                                        <th scope="col" className="text-center">Date Created</th>
                                        <th scope="col" className="text-center">Status</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>                            
                                {filteredUsers.forEach(user => rowData.push(
                                    <tr className="bg-white" key={user._id}>
                                        <td className="text-size-xs">
                                            {/* <img src={user.avatar.url} alt="User Icon" className="user-icon me-3"/> */}
                                            {user.firstName} {user.lastName}
                                        </td>
                                        <td className="text-size-xs">{user.credential}</td>
                                        <td className="text-size-xs">{user.farmerStatus === 'Pending' ? "Farmer (Pending approval)" : user.vendorStatus === 'Pending' ?
                                            "Vendor (Pending approval)": `${user.role}`}
                                        </td>
                                        <td className="text-size-xs">{user.gender}</td>
                                        <td className="text-size-xs text-center">{user.farm ? user.farm.farmLocation : " - "}</td>
                                        <td className="text-size-xs text-center">{formatDate(user.createdAt)}</td>
                                        {user.mobileVerification === "Unverified" ?
                                            <td className="text-size-xs red-color text-center">Unverified</td>
                                            : <td className={`text-size-xs text-center ${user.buyerStatus === 'Active' ? 'green-color' : 'red-color'}`}>{user.buyerStatus}</td>
                                        }
                                        <td className="text-center"><Link to={`/admin/users/${user._id}`} state={{ from: "admin" }} className="table-btn">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { filteredUsers.length == 0 ? (
                                <div className="text-center py-3 w-100 mb-5">
                                    <h3 className='text-size-sm'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>

                                                     
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default AllUsers