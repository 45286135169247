import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getLoans, clearErrors } from '../../actions/loanActions'

import SellerNavigation from '../layout/sellerNavigation'

let clicked = "All";
let firstLoad = true;
let top3;

const SellerLoans = () => {
    const dispatch = useDispatch();

    const { loading, error, loans } = useSelector(state => state.loans);

    let [ filteredLoans, setFilteredLoans ] = useState(loans)

    if (firstLoad) { 
        filteredLoans = loans
        top3 = filteredLoans.slice(0, 3)
    };


    useEffect(() => {
        dispatch(getLoans("Seller"));

        if (error) {
            console.log(error)
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const toggleTable = (status) => {
        firstLoad = false
        let filter
        clicked = status
        if (status === "All") {
            filter = loans;
        } else if (status === "Processing") {
            filter = loans.filter(loan => loan.paymentStatus === "Processing")
        } else if (status === "Active") {
            filter = loans.filter(loan => loan.paymentStatus === "Active")
        } else if (status === "Overdue") {
            filter = loans.filter(loan => loan.paymentStatus === "Overdue")
        } else if (status === "Paid-Off"){
            filter = loans.filter(loan => loan.paymentStatus === "Paid-Off")
        } else {
            filter = []
        }
        setFilteredLoans(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Loans"}/>
            <SellerNavigation Loans/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-md text-muted">LOANS</h3>

                    <div className="row mt-5 mb-5">
                        {top3.map(loan =>  
                            <div key={loan._id} className="col-md-4 px-2">
                                <div className="green-bg rounded px-3 py-4 pb-5">
                                    <p className="text-size-xxs">{loan.type.toUpperCase()}</p>
                                    <p className="text-size-xl bold-2 m-0">&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountToRepayAfterDiscount.toFixed(2))}</p>
                                    <p className="text-size-sm bold-1 mb-4">{loan.status === "Approved" ? `Due on ${loan.loanDetails.dateDue.split("T")[0]}: ₦${numberWithCommas(loan.loanDetails.amountDue)}` : `Loan is ${loan.status}`}</p>
                                    <Link to={`/seller/loans/${loan._id}`} className="border-btn text-size-xs">Pay now</Link>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
                        <span className="text-muted text-size-xs order-1 order-md-2">{filteredLoans.length} {clicked === 'All' ? 'Loans' : clicked}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Processing') }} className={`table-nav ${clicked === 'Processing'?'table-active':''}`}>Processing</button>
                            <button onClick={function() { toggleTable('Active') }} className={`table-nav ${clicked === 'Active'?'table-active':''}`}>Active</button>
                            <button onClick={function() { toggleTable('Overdue') }} className={`table-nav ${clicked === 'Overdue'?'table-active':''}`}>Overdue</button>
                            <button onClick={function() { toggleTable('Paid-Off') }} className={`table-nav ${clicked === 'Paid-Off'?'table-active':''}`}>Paid-Off</button>
                        </div>
                    </div>
                
                    {loading ? <Loader/> : (
                        <Fragment>
                            <table className="table mt-3">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">Amount Loaned</th>
                                        <th scope="col">Amount Due</th>
                                        <th scope="col">Amount Repaid</th>
                                        <th scope="col">Monthly Repayment</th>
                                        <th scope="col">Financier</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Interest Rate</th>
                                        <th scope="col">Status</th>
                                        <th className="text-center" scope="col">Action</th>
                                    </tr>
                                </thead>

                                {filteredLoans.forEach(loan => rowData.push(
                                    <tr className="bg-white" key={loan._id}>
                                        <td className="text-size-xs d-flex align-items-center">{loan.new ? <span className="red-circle">🔴</span> : <Fragment></Fragment>}&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountToRepayAfterDiscount)}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(loan.loanDetails.amountDue)}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountRepaid)}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(loan.loanDetails.monthlyRepayment)}</td>
                                        <td className="text-size-xs">{loan.loanDetails.financier}</td>
                                        <td className="text-size-xs">{loan.loanDetails.duration}</td>
                                        <td className="text-size-xs">{loan.loanDetails.interestRate}</td>
                                        <td className="text-size-xs">{loan.paymentStatus}</td>
                                            <td className="text-center"><Link to={`/seller/loans/${loan._id}`} className="table-btn">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { filteredLoans.length === 0 ? (
                                <div className="text-center py-3 w-100">
                                    <h3 className='text-size-sm'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>
                    )}
                </div>
            </div>
            
        </Fragment>
    )
}

export default SellerLoans