import React, { Fragment, useState } from 'react'

import Metadata from '../layout/metadata';

import { useLocation, useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { numberWithCommas, formatDate } from '../../Functions/functions'
import PaymentTable from './dataTable';

function nextMonth(date) {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    return newDate.toISOString();
}

function hideStuff() {
    document.querySelector(".navbar").classList.toggle("d-none")
    document.querySelector(".top-h").classList.toggle("d-none")
    document.querySelector(".footer").classList.toggle("d-none")
    document.querySelector(".buttons").classList.toggle("d-none")
    document.querySelector(".main-box").classList.toggle("shadow-lg" )
    document.querySelector(".main-box").classList.toggle("pt-5" )
    document.querySelector(".main-box").classList.toggle("pt-0" )
}

const PrintLoanSummary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const loan = location.state.loan;
    const loanDetails = loan.loanDetails;

    let downPayment = (parseInt(loanDetails.downPayment)/100 * loanDetails.totalAmountToRepayAfterDiscount).toFixed(2)
    let interestRate = parseInt(loanDetails.interestRate)

    const print = (e) => {
        hideStuff()
        window.print()
        hideStuff()
    }
    
    const goBack = (e) => {
        navigate(-1)
    }

    return (
        <Fragment>
            <Metadata title={'Loan particulars'} />
            <ToastContainer />
            <div className="top-h container-md green-color my-4 ps-4 ps-md-2">
                <p onClick={goBack} className="green-color action">{"< Back to Financing"}</p>
            </div>
            <div className="main-box container-md shadow-lg white-bg my-0 mb-md-5 pt-5">

                <div className="d-flex justify-content-between align-items-center">
                    <img src="/images/fm-logo.png" alt="" width="130" height="30"/>
                    <h3 className="bold-2 text-size-xs text-muted">LOAN DETAILS</h3>
                </div>

                <hr className="mt-3" />

                <fieldset className="mt-4">
                    <legend className="text-size-xs bold-2 px-3">LOAN PARTICULARS</legend>

                    <div className="row gx-5">
                        <div className="col-6">
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Total amount loaned</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{numberWithCommas(loanDetails.amountRequired)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Total amount to repay</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{numberWithCommas(loanDetails.totalAmountToRepayAfterDiscount)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Total interest incurred</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{numberWithCommas((interestRate/100 * loanDetails.amountRequired).toFixed(2))}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Down payment required</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{numberWithCommas(downPayment)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Monthly payment after down payment</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{numberWithCommas(((loanDetails.totalAmountToRepayAfterDiscount - downPayment)/(parseInt(loanDetails.duration)*12)).toFixed(2))}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Interest rate</h5>
                                <h5 className="bold-2 text-size-xs">{interestRate}%</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Loan term</h5>
                                <h5 className="bold-2 text-size-xs">{loanDetails.duration}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Discount</h5>
                                <h5 className="bold-2 text-size-xs">{loanDetails.discount}</h5>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Total amount repaid</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{numberWithCommas(loanDetails.totalAmountRepaid)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Down payment amount paid</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{numberWithCommas(loanDetails.payments.length > 0 ? loanDetails.payments[0].amount : 0)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Months paid for</h5>
                                <h5 className="bold-2 text-size-xs">{loanDetails.payments.length}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Date of last payment</h5>
                                <h5 className="bold-2 text-size-xs">{loanDetails.payments.length > 0 ? formatDate(loanDetails.payments[loanDetails.payments.length - 1].date) : "No payments made"}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Amount due</h5>
                                <h5 className="bold-2 text-size-xs">&#x20A6;{loanDetails.payments.length > 0 ? numberWithCommas(loanDetails.monthlyRepayment) : numberWithCommas(downPayment)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Date due</h5>
                                <h5 className="bold-2 text-size-xs">{loanDetails.payments.length === 0 ? "Due immediately" : formatDate(loanDetails.dateDue)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Date Created</h5>
                                <h5 className="bold-2 text-size-xs">{formatDate(loan.createdAt)}</h5>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5 className="bold-1 text-size-xs">Loan End Date</h5>
                                <h5 className="bold-2 text-size-xs">{formatDate(loan.loanDetails.endDate) !== "Invalid Date" ? formatDate(loan.loanDetails.endDate) : "[Fixed after first payment]"}</h5>
                            </div>
                        </div>
                    </div>
                </fieldset>

                <h5 className="text-size-xs bold-2 mt-5 mb-4">AMORTIZATION SCHEDULE</h5>
                    <PaymentTable initDate={loanDetails.startDate ? loanDetails.startDate : nextMonth(loan.createdAt)} numOfMonths={parseInt(loanDetails.duration)*12} payments={loanDetails.payments} monthly={loanDetails.monthlyRepayment}/>
                <div className="buttons mt-5">
                    <button type="button" className="border-btn text-size-xs me-2" onClick={goBack}>Go back</button>
                    <button type="button" className="green-btn text-size-xs" onClick={print}>Print summary</button>
                </div>
            </div>
        </Fragment>
    )
};

export default PrintLoanSummary;
