import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'
import { formatDate, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts, clearErrors } from '../../actions/productActions'

let firstLoad = true;

const AllProducts = () => {
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { loading, error, products } = useSelector(state => state.products);

    let [ filteredProducts, setFilteredProducts ] = useState(products)

    if (firstLoad) { filteredProducts = products };

    useEffect(() => {
        dispatch(getAllProducts());

        if (error) {
            console.log(error, "ERRROORRRR!!")
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const rowData = [];

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredProducts(products)
        }
        let matches = products.filter(product => product.name.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredProducts(matches);
        firstLoad = false;
    }

    return (
        <Fragment>
            <Metadata title={'All Products'}/>
            <AdminNavigation Products/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <div className="mid-container-header d-flex justify-content-between align-items-center">
                        <h3 className="text-size-sm text-muted d-inline-block m-0">PRODUCTS</h3>
                        <span className="text-muted text-size-xs">{products.length} products</span>
                    </div>

                    <div className="d-flex mb-3">
                        <input 
                        className="form-control me-2" 
                        type="search" 
                        placeholder="Search inventory" 
                        aria-label="Search"
                        onChange={onSearchChange}
                        />
                        {/* { user && user.role === "Admin+" ? <Link to={"/admin/products/add-product"} className="btn green-btn w-25 plus" type="submit">+<span className="d-none d-sm-inline text-size-xxs"> Add Product</span></Link> : <Fragment></Fragment>} */}
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>
                        <table className="table">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">
                                        <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="15"/>
                                    </th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Farm</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Stock</th>
                                    <th scope="col">Created</th>
                                    <th className="text-center" scope="col">Action</th>
                                </tr>
                            </thead>
                            {filteredProducts.forEach(product => rowData.push(
                                
                                <tr className="bg-white" key={product._id}>
                                    <td><div style={{backgroundImage: `url('${product.images[0].url}')`, backgroundSize: 'cover'}} className="cart-img3 border rounded"></div></td>
                                    <td>{product.name}</td>
                                    <td>{product.farm ? product.farm.farmName : 'None (Vendor)'}</td>
                                    <td>&#x20A6;{numberWithCommas(product.price)}</td>
                                    <td>{product.category}</td>
                                    <td className={`${product.stock === 0 ? 'red-color' : 'green-color'}`}>{product.stock}</td>
                                    <td>{formatDate(product.createdAt)}</td>
                                    <td className="text-center"><Link to={`/products/${product.category.toLowerCase()}/${product._id}`} state={{ from: "admin" }} className="table-btn">View</Link></td>
                                </tr>                                 
                            ))}
                            <tbody>{rowData}</tbody>
                        </table>
                        { filteredProducts.length == 0 ? (
                            <div className="text-center py-3 w-100">
                                <h3 className='text-size-sm'>NO DATA</h3>
                            </div>
                        ):(<Fragment></Fragment>)}
                    </Fragment>
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default AllProducts