import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Metadata from '../layout/metadata'
import { numberWithCommas } from '../../Functions/functions'
import CheckoutSteps from './checkoutSteps'

import { useSelector } from 'react-redux'

const ConfirmOrder = () => {

    const { cartItems, shippingInfo, paymentMethod } = useSelector(state => state.cart)
    const { user } = useSelector(state => state.auth)


    // Calculate order prices
    const itemsPrice = cartItems.reduce((acc, item) => acc + (item.quantity * item.price), 0)
    const shippingPrice = itemsPrice > 200000 ? 200 : 0//500
    const taxPrice = Number((0.075 * itemsPrice).toFixed(2))
    const totalPrice = (itemsPrice + shippingPrice + taxPrice).toFixed(2)

    const navigate = useNavigate();

    const proceedToPayment = () => {

        const data = {
            itemsPrice: itemsPrice.toFixed(2),
            shippingPrice: shippingPrice.toFixed(2),
            taxPrice: taxPrice.toFixed(2),
            totalPrice
        }

        sessionStorage.setItem('orderInfo', JSON.stringify(data))

        if (paymentMethod === "Get Financing") {
            navigate('/seller/loans/apply', {state: { orderDetails: {
                amount: totalPrice,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.credential,
                phoneNo: shippingInfo.phoneNo,
                cartItems,
                shippingInfo,
                paymentMethod
            }
        }})} else {
                navigate('/checkout/payment', {state: {
                    amount: totalPrice,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.credential,
                    phoneNo: shippingInfo.phoneNo,
                    cartItems,
                    shippingInfo,
                    paymentMethod
                }})
            }   
    }
    
  return (
    <Fragment>
        <Metadata title={'Checkout - Confirm Order'}/>
        <h2 className="container-sm mid-container-header text-size-md">CHECKOUT</h2>
        <div className="container-sm mid-container mt-4 pt-4">
            <CheckoutSteps shipping confirmOrder/>
            <h3 className="text-size-md mt-5"><strong>Shipping Information</strong></h3>
            <hr />
            <div className="shipping-info mt-4">
                <div className="text-size-sm"><strong>Name: </strong>{user && user.firstName} {user && user.lastName}</div>
                <div className="text-size-sm mt-4"><strong>Address: </strong>{shippingInfo.address}, {shippingInfo.city}, {shippingInfo.postalCode}, {shippingInfo.country}</div>
                <div className="text-size-sm mt-4"><strong>Phone Number: </strong>{shippingInfo.phoneNo}</div>
                <div className="text-size-sm mt-4"><strong>Payment Method: </strong>{paymentMethod}</div>
            </div>

            <h3 className="text-size-md mt-5"><strong>Cart Items</strong></h3>
            <hr className="mb-4"/>
            { cartItems.map(item => (
                <Fragment key={item.product}>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <div className="cart-img2 border rounded" style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}}></div>
                        <div className="d-flex flex-wrap justify-content-between w-85 ms-3 ms-md-0">
                            <Link to={`/product/${item.product}`} className="text-size-sm w-70">{item.name}</Link>
                            <div className="text-size-sm mt-2 mt-lg-0">{numberWithCommas(item.quantity)} x &#x20A6;{numberWithCommas(item.price)} = <strong>&#x20A6;{numberWithCommas(item.quantity * item.price)}</strong></div>
                        </div>
                        
                    </div>
                </Fragment>
            ))}

            <hr className="mt-4"/>

            <div className="mt-4">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="text-size-sm">Subtotal:</div>
                    <div className="text-size-sm"><strong>&#x20A6;{numberWithCommas(itemsPrice)}</strong></div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="text-size-sm">Shipping:</div>
                    <div className="text-size-sm"><strong>&#x20A6;{numberWithCommas(shippingPrice)}</strong></div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="text-size-sm">VAT:</div>
                    <div className="text-size-sm"><strong>&#x20A6;{numberWithCommas(taxPrice)}</strong></div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="text-size-md">Total:</div>
                    <div className="text-size-md green"><strong>&#x20A6;{numberWithCommas(totalPrice)}</strong></div>
                </div>

                <div className="d-flex justify-content-end mt-5">
                    <Link to="/checkout/shipping" className="d-flex align-items-center justify-content-center border-btn text-size-xs me-3">Back to Shipping</Link>
                    <button onClick={proceedToPayment} className="d-flex align-items-center justify-content-center green-btn text-size-xs">Proceed to Payment</button>
                </div>

            </div>
        </div>
    </Fragment>
  )
}

export default ConfirmOrder