import React, { Fragment } from 'react'
import Metadata from './metadata'
import { formatDate, numberWithCommas } from '../../Functions/functions'

const PaymentHistoryModal = ({paymentHistory}) => {


    return (
        <Fragment>
            <Metadata title={'Loan Details'}/>
            {/* Modal */}
            <div className="modal fade" id="payment-history-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-size-sm" id="payment-history-modal-label">Payment history</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        {paymentHistory.map(payment => (
                            <p className="text-size-xs bold-1"><span className="bold-2">₦{numberWithCommas(payment.amount)}</span> - {formatDate(payment.date)} <span className="text-size-xxs bold-2">({payment.method} by {payment.registeredBy})</span></p>
                        ))}
                        {paymentHistory.length === 0 ? <p className="text-size-xs bold-1">No payments made yet</p>:<Fragment></Fragment>}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
    }

export default PaymentHistoryModal
