import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Loader from '../layout/loader'
import Metadata from '../layout/metadata'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { addItemToCart, removeItemFromCart } from '../../actions/cartActions'

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, cartItems } = useSelector(state => state.cart)

    const removeItemFromCartHandler = (id) => {
        dispatch(removeItemFromCart(id))
        toast.success("Item removed from cart 👍")
    }

    const increaseQty = (id, quantity, stock) => {
        const newQty = quantity + 1;

        if (newQty > stock) return;
        
        dispatch(addItemToCart(id, newQty))
    }

    const decreaseQty = (id, quantity) => {
        const newQty = quantity - 1;

        if (newQty <= 0) return;
        
        dispatch(addItemToCart(id, newQty))
    }

    const checkoutHandler = () => {
        navigate('/signin?redirect=checkout/shipping')
    }

    const goBack = () => {
        navigate(-1)
    }

  return (
    <Fragment>
        <ToastContainer />
        <Metadata title={'Your Cart'}/>
        {loading ? <Loader/> : (
            <Fragment>
                <div className="container-md green-color my-4 ps-4 ps-md-2">
                    <a href="#" onClick={goBack} className="green-color">{"< Go Back"}</a>
                </div>
                <div className="container-md white-bg shadow-lg my-0 mb-md-5">

                    <div className="d-flex justify-content-between align-items-center">
                    {/* <h3 className="container-md dash-header mt-1 ps-0 text-muted">PRODUCT DETAILS</h3> */}
                    
              
                        <h3 className="text-size-lg text-muted m-0">CART</h3>

                        <span className="text-size-xs">{cartItems.length} {cartItems.length === 1 ? "Item": "Items"}</span>
                    </div>
                    <hr className="my-3"/>
                    {cartItems.length === 0 ? 
                        <h2 className="mt-3 text-size-md">Your cart is empty.</h2> : (
                        <Fragment>
                            {cartItems.map(item => (
                                <Fragment key={item.product}>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div className="">
                                            <div className="product-box d-flex align-items-center">
                                                <div className="cart-img border rounded" style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}}></div>
                                                {/* <img src={item.image} className="cart-img border rounded" alt="Product Image"/> */}
                                                <div className="product-info d-flex flex-column ms-3">
                                                    <Link to={`/products/${item.product}`} state={{ from: "cart" }} className="cart-text">{item.name}</Link>
                                                    <span className="cart-text">&#x20A6;{numberWithCommas(item.price)}</span>
                                                    <button className="red-color cart-text remove" onClick={() => removeItemFromCartHandler(item.product)}>Remove</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cart-qty-box">
                                            <div className="d-flex align-items-center justify-content-end">
                                                <button className="d-flex align-items-center justify-content-center green-btn quantity-toggle" onClick={() => decreaseQty(item.product, item.quantity)}>-</button>
                                                <input type="number" id="count" className="m-0 p-0" value={item.quantity} readOnly/>
                                                <button className="d-flex align-items-center justify-content-center green-btn quantity-toggle" onClick={() => increaseQty(item.product, item.quantity, item.stock)}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </Fragment>
                            ))}
                            <div className="d-flex justify-content-between mt-4">
                                <h4 className="text-size-md">Total</h4>
                                <div className="cart-price-box d-flex flex-column align-items-end">
                                <span className="text-size-xs text-muted">{cartItems.reduce((acc, item) => (acc + Number(item.quantity)), 0)} (Units)</span>
                                    <span className="text-size-lg green-color my-1">&#x20A6;{numberWithCommas(cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0).toFixed(2))}</span>
                                    <span className="text-size-xxs text-muted">Shipping fees not yet applied</span>
                                </div>
                            </div>
                            <div className="d-flex mt-4 justify-content-end">
                                <Link to="/" className="d-flex align-items-center justify-content-center border-btn text-size-xs me-3">Continue Shopping</Link>
                                <button onClick={checkoutHandler} className="d-flex align-items-center justify-content-center green-btn text-size-xs">Proceed to Checkout</button>
                            </div>
                        </Fragment>
                        )}
                </div>
            </Fragment>
        )}
    </Fragment>
  )
}

export default Cart