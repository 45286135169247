import React, { Fragment, useEffect } from 'react'
import Metadata from '../layout/metadata'
import ProductCard from '../product/productCard'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { enrollUser, getUserDetails, respondToUserRequest, clearErrors } from '../../actions/userActions'
import { getProductsBySellerId } from '../../actions/productActions'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'
import RejectSellerModal from '../layout/rejectSellerModal'

import { ENROLL_USER_RESET } from '../../constants/userConstants'
import { formatDate } from '../../Functions/functions'

let	fingerpos = ["LEFT_THUMB", "LEFT_INDEX", "RIGHT_THUMB", "RIGHT_INDEX" ];
let	service_handle = "";
let	ENROLL_OBJ; 
let totalEnrollments = 0;

const UserDetails = (admin=false) => {
    const location = useLocation();
    const { from } = location.state

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const loggedInUser = useSelector(state => state.auth).user;

    const userLoading = useSelector(state => state.auth).loading

    const enrollLoading = useSelector(state => state.updateUser).loading;
    const enrollSuccess = useSelector(state => state.updateUser).isUpdated;

    const { user, error, loading} = useSelector(state => state.userDetails)
    const { success, loading:responseLoading } = useSelector(state => state.response)
    
    let { products } = useSelector(state => state.products);
    
    useEffect(() => {
        dispatch(getUserDetails(params.id))

        if (user._id) dispatch(getProductsBySellerId(user._id));

        if (enrollSuccess) {
            dispatch({ type: ENROLL_USER_RESET });
            toast.success("Enrollment successful!");
        }

        if (error) {
            console.log(error)
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success, enrollSuccess, params.id, user._id])

    const approveOnClick = (seller) => {
        dispatch(respondToUserRequest({
            "decision": "Approve",
            "reason": "None"
        }, params.id, seller))

        if (error) {
            toast.error(error);
            dispatch(clearErrors())
        }
        if (success) {
            dispatch(getUserDetails(params.id))
            toast.success('Seller account request has been approved. The user has been notified.');
        }
    }
    const goBack = () => {
        navigate(-1)
    }

    // FINGERPRINTS

    function Restart() {
        totalEnrollments = 0;
        window.location.reload(true);
    }

    function captureFP( arrIndex, event ) {
        let	imgId = fingerpos[arrIndex];
        if ( ENROLL_OBJ && ENROLL_OBJ.length > arrIndex  && ENROLL_OBJ.EnrollData[arrIndex].na >= 3 ) {
            alert("Maximum Attempts Over!");
            return;
        }
        document.getElementById(imgId).src = "/images/wait.gif";
        CallSGIFPGetData( arrIndex, successFunc, errorFunc);
    }

    function CallSGIFPGetData( arrIndex, successCall, failCall ) {
        let	uri 	= "https://localhost:8443/SGIFPEnroll";
        // let	uri 	= "https://market.farmwarehouse.ng:8443/SGIFPEnroll";
        let	params 	= "timeout=" + "5000";
            params  += "&quality=" + "50";
            params += "&templateFormat=" + "ISO"; // DEFAULT IS ISO
            params  += "&srvhandle=" + service_handle;
            params  += "&FingerPos=" + fingerpos[arrIndex];

        let	xmlhttp = new XMLHttpRequest();
        xmlhttp.open("POST", uri, true );
        xmlhttp.onreadystatechange=function() {
            if (xmlhttp.readyState===4 && xmlhttp.status===200) {
                totalEnrollments++;
                let fpobject = JSON.parse(xmlhttp.responseText);
                ENROLL_OBJ = fpobject;
                successCall( arrIndex, fpobject);
            } else if ( xmlhttp.status === 404 ) {
                failCall(arrIndex, xmlhttp.status)
            };
        };
        
        xmlhttp.onerror=function() {
            failCall(arrIndex, xmlhttp.status);
        };
        
        xmlhttp.send(params);
    };

    function successFunc( arrIndex, fpdata ) {

        let	imgId = fingerpos[arrIndex];

        if ( fpdata.ErrorCode === 0 ) {
            if ( service_handle !== "" && fpdata.SerHandle !== service_handle ) {
                toast.error("Session Timeout you will need to restart");
                return Restart();
            };

            service_handle = fpdata.SerHandle;

            document.getElementById(imgId).src = "data:image/bmp;base64," + fpdata.BMPBase64;

            let btnid = "BTN_" + imgId;
            document.getElementById(btnid).disabled = true;
            document.getElementById(btnid).innerText = document.getElementById(btnid).innerText + " 👍"
            
        } else if (fpdata.ErrorCode === 54) {
            totalEnrollments --;
            toast.error("Scanning has timed out, please rescan.");
            document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
            
        } else if (fpdata.ErrorCode === 3) {
            document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
            return toast.error("An error occured. \nPlease check that a compatible SecuGen fingerprint scanner is connected, the drivers are installed and the Web API Service is running.")
        } else {
            document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
            return toast.error("Fingerprint Capture Error Code:  " + fpdata.ErrorCode);
        };
    };

    function errorFunc ( arrIndex, status ) {
        let	imgId = fingerpos[arrIndex];
        document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
        return toast.error("An error occured. \nPlease check that a compatible SecuGen fingerprint scanner is connected, the drivers are installed and the Web API Service is running.");
    };

    function updateOnClick(e) {
        if (!ENROLL_OBJ ) {
            return toast.error("Please register your fingerprints first.")
        } else if (ENROLL_OBJ.EnrollData.Templates.length !== 4 ) {
            return toast.error("Please register all 4 fingerprints first.")
        }
        dispatch(enrollUser(params.id, ENROLL_OBJ.EnrollData.Templates));
    }

    return (
        <Fragment>
            <Metadata title={'User Details'}/>
            <ToastContainer />
            {from === "admin" ? <AdminNavigation Users/> : ""}

            {userLoading || loading ? <Loader/> :
            (
                <Fragment>
                    {/* USERS SHOW PAGE */}
                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <div onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</div>
                    </div>

                    <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                        <h3 className="container-md text-size-md mt-1 ps-0 text-muted">USER PROFILE</h3>

                        <hr className="mb-4"/>

                        <div className="row align-items-center p-0 mx-4">
                            <div className="col-sm-6 col-md-4 text-center text-sm-start img-div">
                                {user.avatar ? (
                                    <div className="details-img2" style={{backgroundImage: `url('${user.avatar.url}')`, backgroundSize: 'cover',  backgroundPosition: 'center'}}></div>
                                ):(<Fragment></Fragment>)}
                            </div> 
                            <div className="col-sm-6 col-md-8 text-center text-sm-start">
                                <div className="ms-3 p-2">
                                    <h3 className="text-size-md m-0 mt-3">{user.firstName} {user.lastName} {user.farmerStatus === 'Pending' || user.vendorStatus === 'Pending' ? "(Pending approval)" : `(${user.role})`}</h3>
                                    <p className="text-size-sm my-2">{user.credential}</p>
                                    <i className="text-size-xs text-muted">Date created: {formatDate(user.createdAt)}</i>
                                    <div className="mt-4">
                                        { loggedInUser.role === "Admin+" && loggedInUser._id !== user._id ? 
                                            <button className="red-border-btn text-size-xs me-2">Suspend User</button>:<Fragment></Fragment>
                                        }
                                        { (loggedInUser.role === 'Admin' || loggedInUser.role === "Admin+") && (user.farmerStatus === 'Pending' || user.vendorStatus === 'Pending') ?
                                            <Fragment>
                                                <button onClick={function() {approveOnClick(`${user.farmerStatus === "Pending" ? "Farmer" : "Vendor"}`)}} className="green-btn text-size-xs me-2 mb-3" disabled={responseLoading ? true : false}>{`Approve Request`}</button>
                                                <button className="red-btn text-size-xs mb-3" data-bs-toggle="modal" data-bs-target="#staticBackdrop">{`Reject Request`}</button>
                                                <RejectSellerModal user={user} id={params.id}/>
                                            </Fragment> : <Fragment></Fragment>
                                        }
                                    </div>  
                                </div>


                            </div>
                        </div>

                        {/* FARM DETAILS */}
                        {user.farm ? (
                            <Fragment>
                                <hr className="mx-4 my-4"/>
                                <div className="mx-4">
                                    <h5 className="text-size-sm text-muted mb-4 ">Farm Details</h5>
                                    { loggedInUser.role === "Admin" ? <p className="text-size-sm mt-2">Farm name: <strong><Link to={`/admin/farms/${user.farm._id}`}>{user.farm.farmName}</Link></strong></p> : <Fragment></Fragment>}
                                    { loggedInUser.role === "Farmer" ? <p className="text-size-sm mt-2">Farm name: <strong><Link to={`/farms/${user.farm._id}`}>{user.farm.farmName}</Link></strong></p> : <Fragment></Fragment>}
                                    { loggedInUser.role === "Buyer" || user.role === "Vendor" ? <p className="text-size-sm mt-2">Farm name: <strong>{user.farm.farmName}</strong></p> : <Fragment></Fragment>}
                                    
                                    <p className="text-size-sm mt-2">Farm location: <strong>{user.farm.farmLocation}</strong></p>
                                    <p className="text-size-sm mt-2">Farm size: <strong>{user.farm.farmSize} Hectares</strong></p>
                                </div>
                            </Fragment>
                            ) : (<Fragment></Fragment>)
                        }

                        {/* FINGERPRINTS */}
                        { user.fingerPrints && (loggedInUser.role === "Admin" || loggedInUser.role === "Admin+") ?
                        <fieldset className="mt-4">
                            <legend className="form-label">Register Fingerprints</legend>
                            <div className="row">
                                {/* LEFT THUMB */}
                                <div className="col-6 text-center">
                                    <div className="print-set">
                                        <img id="LEFT_THUMB" alt="LEFT_THUMB" className="hand" src="/images/hand_0.png" /> <br/>
                                        <button disabled={user.fingerPrints.length > 0} className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_LEFT_THUMB" onClick={(event) => captureFP(0, event)}>Left Thumb</button>
                                        { user.fingerPrints.length > 0 ? <p>Enrolled</p> : <Fragment></Fragment>}
                                    </div>
                                </div>

                                {/* LEFT INDEX */}
                                <div className="col-6 text-center">
                                    <div className="print-set">
                                        <img id="LEFT_INDEX" alt="LEFT_INDEX" className="hand" src="/images/hand_1.png" /> <br/>
                                        <button disabled={user.fingerPrints.length > 0} className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_LEFT_INDEX" onClick={(event) => captureFP(1, event)}>Left Index</button> 
                                        {user.fingerPrints.length > 0 ? <p>Enrolled</p> : <Fragment></Fragment>}
                                    </div>
                                </div>

                                {/* RIGHT THUMB */}
                                <div className="col-6 text-center">
                                    <div className="print-set">
                                        <img id="RIGHT_THUMB" alt="RIGHT_THUMB" className="hand" src="/images/hand_2.png" /> <br/>
                                        <button disabled={user.fingerPrints.length > 0} className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_RIGHT_THUMB" onClick={(event) => captureFP(2, event)}>Right Thumb</button>
                                        {user.fingerPrints.length > 0 ? <p>Enrolled</p> : <Fragment></Fragment>}
                                    </div>
                                </div>

                                {/* RIGHT INDEX */}
                                <div className="col-6 text-center">
                                    <div className="print-set">
                                        <img id="RIGHT_INDEX" alt="RIGHT_INDEX" className="hand" src="/images/hand_3.png" /> <br/>
                                        <button disabled={user.fingerPrints.length > 0} className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_RIGHT_INDEX" onClick={(event) => captureFP(3, event)}>Right Index</button>
                                        {user.fingerPrints.length > 0 ? <p>Enrolled</p> : <Fragment></Fragment>}
                                    </div>
                                </div>

                                { enrollLoading ? <p className="text-dark text-center">Enrolling...</p> : <Fragment></Fragment> }

                                { user.fingerPrints && user.fingerPrints.length === 0 ?
                                <div className="col-12 text-center mt-4"> 
                                    <button className="border-btn py-1 me-2" onClick={updateOnClick}>Update</button>
                                    <button className="border-btn py-1" type="button" value="Restart" id="BTN_RESTART" onClick={Restart}>Restart</button>
                                </div>
                                : <Fragment></Fragment>}
                            </div>
                        </fieldset>
                        : <Fragment></Fragment>}


                        {/* PRODUCTS */}
                        {user.role === "Farmer" || user.role === "Vendor" ? (
                            <Fragment>
                                <h5 className="text-size-sm text-muted mt-5 ms-4">Seller's Products</h5>
                                { products.length > 0 ?
                                <div className="row mx-4 my-3">
                                    { products && products.map(product => (
                                        <ProductCard key={product._id} product={product}/>
                                    ))}
                                </div>
                                : <div className="ms-4 mt-3">This seller has no products</div>
                                }

                            </Fragment>
                            ) : (<Fragment></Fragment>)
                        }
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default UserDetails