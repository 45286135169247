import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'


import SellerNavigation from '../layout/sellerNavigation'


const LoanOffers = () => {

    return (
        <Fragment>
            <Metadata title={'Loan offers'}/>
            <SellerNavigation Loans/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-md text-muted mb-5">LOAN OFFERS</h3>
                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/seller/loans/apply" className="green-color">{"< Go Back"}</Link>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="d-flex flex-column bg-white shadow-sm p-4">
                                <h5 className=" text-size-md">Crysland Agency</h5>
                                <span className="text-size-xs my-1">5% Interest</span>
                                <hr />
                                <p className=" text-size-xs mb-3">✅ Ultrices pharetra pellentesque amet in at.</p>
                                <p className=" text-size-xs mb-3">✅ Fringilla sed sapien ornare rutrum.</p>
                                <p className=" text-size-xs mb-3">✅ In duis nunc sollicitudin blandit hendrerit.</p>
                                <p className=" text-size-xs mb-3">✅ Nunc feugiat augue turpis ultrices vitae.</p>
                                <p className=" text-size-xs mb-3">✅ Lectus lacus porttitor sem sem netus sed.</p>
                                <button className="text-size-xxs bold py-2 border-btn my-2">Apply for Loan</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="d-flex flex-column bg-white shadow-sm p-4">
                                <h5 className=" text-size-md">Greenfarm Agency</h5>
                                <span className="text-size-xs my-1">8% Interest</span>
                                <hr />
                                <p className=" text-size-xs mb-3">✅ Ultrices pharetra pellentesque amet in at.</p>
                                <p className=" text-size-xs mb-3">✅ Fringilla sed sapien ornare rutrum.</p>
                                <p className=" text-size-xs mb-3">✅ In duis nunc sollicitudin blandit hendrerit.</p>
                                <p className=" text-size-xs mb-3">✅ Nunc feugiat augue turpis ultrices vitae.</p>
                                <p className=" text-size-xs mb-3">✅ Lectus lacus porttitor sem sem netus sed.</p>
                                <button className="text-size-xxs bold py-2 border-btn my-2">Apply for Loan</button>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>

        </Fragment>
    )
}

export default LoanOffers