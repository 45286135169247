import React, { Fragment, useEffect, useState } from 'react'
import Clock from 'react-live-clock';
import Metadata from '../layout/metadata'

import { useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getAdminFarmDetails, updateFarmDetails, clearErrors } from '../../actions/farmActions'

import { useParams } from 'react-router-dom'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'

import { useMemo } from 'react'
import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";

import axios from 'axios';

async function getWeatherInfo(lat, lng) {
    const response = await axios.get('https://api.openweathermap.org/data/2.5/weather', {
      params: {
        lat: lat,
        lon: lng,
        units: "metric",
        appid: "3ef854f7991038bcbd78140cd55f91d9"
      }
    });

    return response;
}

let firstLoad = true;
let firstCoords = true;

const AdminFarmDetails = (admin=false) => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

    // let dateOffset = (24*60*60*1000) * 10; // 10 days
    // let currDate = new Date();
    // let prev10Days = currDate.setTime(currDate.getTime() - dateOffset);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyD0euAKJS_ktUhBd52TGHPY5NcPQ1ewAfQ"
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const { farm, error, loading } = useSelector(state => state.farmDetails)
    const updateLoading = useSelector(state => state.updateFarm).loading

    const [weather, setWeather] = useState(null);

    async function fetchWeatherInfo() {
        const data = await getWeatherInfo(farm.farmLat, farm.farmLng);
        setWeather(data);

    }

    if (!weather && farm.farmer) {
        fetchWeatherInfo()
    }

    useEffect(() => {
        dispatch(getAdminFarmDetails(params.id))

        if (error) {
            console.log(error, "ERRROOORR!! - adminFarmDetails.jsx")
            dispatch(clearErrors())
        }

    }, [dispatch, error, params.id])

    const goBack = () => {
        navigate(-1)
    }

    const [readonly, setreadonly] = useState(true)

    let [coords, setCoords] = useState({
        latA: '',
        lngA: '',
        latB: '',
        lngB: '',
        latC: '',
        lngC: '',
        latD: '',
        lngD: ''
    })

    let { latA, lngA, latB, lngB, latC, lngC, latD, lngD } = coords;

    if (firstLoad && farm.coords) {
        firstCoords = false;
        latA = farm.coords.pointA.latA
        lngA = farm.coords.pointA.lngA
        latB = farm.coords.pointB.latB
        lngB = farm.coords.pointB.lngB
        latC = farm.coords.pointC.latC
        lngC = farm.coords.pointC.lngC
        latD = farm.coords.pointD.latD
        lngD = farm.coords.pointD.lngD

        coords = { latA: latA, lngA: lngA, latB: latB, lngB: lngB, latC: latC, lngC: lngC, latD: latD, lngD: lngD }
    }

    const onChange = e => {
        firstLoad = false
        setCoords({...coords, [e.target.name]: e.target.value })
    }

    const enableUpdate = e => {
        setreadonly(false)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateFarmDetails(coords, params.id));
        toast.success("Coords set successfully")
        setreadonly(true)
        if (firstCoords) dispatch(getAdminFarmDetails(params.id))
        firstCoords = false;
    }

    return (
        <Fragment>
            <Metadata title={'Farm Details'}/>
            <ToastContainer />
            <AdminNavigation Farms/>

            {loading ? <Loader/> :
            (
                <Fragment>
                    {/* USERS SHOW PAGE */}
                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <span onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</span>
                    </div>

                    <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">
                        {farm.farmer ? 
                            <div className="mb-3">
                                <h3 className="text-size-lg">{farm.farmName}</h3>
                                <p className="d-block text-size-xs my-2 bold"><span>{farm.farmLocation}</span> | <span>{farm.farmSize} hectares</span> | <span>{farm.farmer.firstName} {farm.farmer.lastName}</span></p>
                                <p className="text-size-xs">Lat: <span className="bold">{farm.farmLat},</span> Long: <span className="bold">{farm.farmLng}</span></p>     
                            </div> : <Fragment></Fragment>
                        }

                        {/* WEATHER */}
                        {weather ? 
                            <div className="weather-box d-flex justify-content-between align-items-center p-4 mt-4 mb-4" style={{backgroundImage: "url('/images/weather-dark.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom'}}>
                                <div>
                                    <p className="white-color text-size-md bold mb-0">Current Weather</p>
                                    <i className="white-color text-size-xxs"><span>{date}</span> | <span className="text-size-xxs bold"><Clock format={'HH:mm:ss'} ticking={true} timezone={'Africa/Lagos'} /></span></i>
                                    <h3 className="white-color text-size-md bold mt-3">{(weather.data.weather[0].description).charAt(0).toUpperCase() + (weather.data.weather[0].description).substr(1).toLowerCase()}</h3>      
                                </div>

                                <div className="weather-divider"></div>

                                <div>
                                    <p className="white-color text-size-sm mb-1">temperature: <span className="bold white-color">{weather.data.main.temp}&#8451;</span></p>
                                    <p className="white-color text-size-sm mb-1">humidity: <span className="bold white-color">{weather.data.main.humidity}%</span></p>
                                    <p className="white-color text-size-sm mb-1">pressure: <span className="bold white-color">{weather.data.main.pressure}hPa</span></p>
                                </div>

                                <div className="weather-divider"></div>

                                <div>
                                    <p className="white-color text-size-sm mb-1">wind speed: <span className="bold white-color">{weather.data.wind.speed}m/s</span></p>
                                    <p className="white-color text-size-sm mb-1">wind direction: <span className="bold white-color">{weather.data.wind.deg}deg</span></p>
                                    <p className="white-color text-size-sm mb-1">wind gust: <span className="bold white-color">{weather.data.wind.gust}</span></p>
                                </div>
                            </div>
                        : <Fragment></Fragment> }

                        {/* SET FARM COORDINATES */}
                        <hr className="my-4"/>

                        <form onSubmit={submitHandler}>
                            <h5 className="text-size-sm text-muted mb-4 d-inline-block">Farm Coordinates</h5> <strong><u onClick={enableUpdate} className="action text-size-xs green-color ms-2">Update</u></strong>
                            <div className="row">

                                {/* POINT A */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point A</h4>
                                    </div>
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="latA" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latA"
                                                name='latA' 
                                                value={latA}
                                                onChange={onChange}
                                                aria-describedby="latAHelp" 
                                                placeholder="E.g. 9.0543"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="lngA" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="lngA"
                                                name='lngA' 
                                                value={lngA}
                                                onChange={onChange}
                                                aria-describedby="lngAHelp" 
                                                placeholder="E.g. 6.9432"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* POINT B */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point B</h4>
                                    </div>
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="latB" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latB"
                                                name='latB' 
                                                value={latB}
                                                onChange={onChange}
                                                aria-describedby="latBHelp" 
                                                placeholder="E.g. 9.0543"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="lngB" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="lngB"
                                                name='lngB' 
                                                value={lngB}
                                                onChange={onChange}
                                                aria-describedby="lngBHelp" 
                                                placeholder="E.g. 6.9432"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* POINT C */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point C</h4>
                                    </div>                                    
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="latC" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latC"
                                                name='latC' 
                                                value={latC}
                                                onChange={onChange}
                                                aria-describedby="latCHelp" 
                                                placeholder="E.g. 9.0543"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="lngC" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="lngC"
                                                name='lngC' 
                                                value={lngC}
                                                onChange={onChange}
                                                aria-describedby="lngCHelp" 
                                                placeholder="E.g. 6.9432"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* POINT D */}
                                <div className="col-lg-6">
                                    <div>
                                        <h4 className="text-size-xs">Point D</h4>
                                    </div>
                                    <div className="point-block">
                                        <div className="w-100 me-2">
                                            <label htmlFor="latD" className="form-label text-size-xxs">Latitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="latD"
                                                name='latD' 
                                                value={latD}
                                                onChange={onChange}
                                                aria-describedby="latDHelp" 
                                                placeholder="E.g. 9.0543"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                        <div className="w-100">
                                            <label htmlFor="lngD" className="form-label text-size-xxs">Longitude</label>
                                            <input 
                                                type="number" 
                                                className="form-control" 
                                                id="lngD"
                                                name='lngD' 
                                                value={lngD}
                                                onChange={onChange}
                                                aria-describedby="lngDHelp" 
                                                placeholder="E.g. 6.9432"
                                                required
                                                readOnly={readonly}
                                            />
                                        </div>
                                    </div>
                                </div>
 
                            </div>
                            <button type="submit" className="text-size-xs green-btn ms-auto my-2" disabled={updateLoading || readonly ? true : false}>{updateLoading ? "Setting Coordinates, please hold on..." : "Set Coordinates"}</button>

                        </form>

                        <hr className="my-4"/>
                        
                        {farm.coords && !isEmptyString({latA:parseFloat(latA), lngA:parseFloat(lngA), latB:parseFloat(latB), lngB:parseFloat(lngB), latC:parseFloat(latC), lngC:parseFloat(lngC), latD:parseFloat(latD), lngD:parseFloat(lngD)})? 
                            <div className="map">
                                {!isLoaded ? <div>Loading Map...</div> : <Map latLng={{latA:parseFloat(latA), lngA:parseFloat(lngA), latB:parseFloat(latB), lngB:parseFloat(lngB), latC:parseFloat(latC), lngC:parseFloat(lngC), latD:parseFloat(latD), lngD:parseFloat(lngD)}}/>}
                            </div> : 
                            <div className="text-center">
                                <h5 className="text-size-sm">SET COORDINATES TO SEE MAP</h5>
                            </div>
                        }
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

function isEmptyString(latLng) {
    // CHECK FOR EMPTY STRINGS AND DON'T UPDATE MAP
    const coords = [
        { lat: latLng.latA, lng: latLng.lngA },
        { lat: latLng.latB, lng: latLng.lngB },
        { lat: latLng.latC, lng: latLng.lngC },
        { lat: latLng.latD, lng: latLng.lngD },
      ];
    const checkLat = coords.map(coord => coord.lat)
    const checkLng = coords.map(coord => coord.lng)
    if (checkLat.includes(NaN) || checkLng.includes(NaN)) return true;
    return false;
}

function Map(latLng) {
    latLng = latLng.latLng;
    let centerCoords = { lat: latLng.latA, lng: latLng.lngA }

    const coords = [
        { lat: latLng.latA, lng: latLng.lngA },
        { lat: latLng.latB, lng: latLng.lngB },
        { lat: latLng.latC, lng: latLng.lngC },
        { lat: latLng.latD, lng: latLng.lngD },
      ];

    const center = useMemo(() => (centerCoords), [centerCoords]);

    return <GoogleMap 
        zoom={12} 
        center={center} 
        mapContainerClassName="map-container"
        >
            {/* <Marker position={ center }/> */}
            <Polygon
                paths = {coords}
                strokeColor = "#FF0000"
                strokeOpacity = {0.8}
                strokeWeight = {1}
                fillColor = "#FF0000"
                fillOpacity = {0.35}
            />
        </GoogleMap>
}

export default AdminFarmDetails