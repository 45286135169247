import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


const SellerNavigation = ({Dashboard, Inventory, Orders, Payments, Requests, Storage, Insurance, Loans}) => {
  const { user } = useSelector(state => state.auth)

  return (
    <Fragment>
        <nav className="container-fluid navbar navbar-expand-lg">
            <div className="container-md">
              <div className="user-nav-block">
                {/* DASHBOARD */}
                <Link to="/seller/dashboard" className="user-nav-item ms-0">
                  <img src={Dashboard ? '/images/dashboard-icon.png' : '/images/dashboard-icon-gray.png'} className="seller-nav-img" alt="Dashboard-icon" />
                  <span className={`text-size-xs user-nav-label ${Dashboard?'green':'gray'}`}>Dashboard</span>
                </Link>

                {/* INVENTORY */}
                <Link to="/seller/inventory" className="user-nav-item">
                <img src={Inventory ? '/images/inventory-icon.png' : '/images/inventory-icon-gray.png'} className="seller-nav-img" alt="Inventory-icon" />
                  <span className={`text-size-xs user-nav-label ${Inventory?'green':'gray'}`}>Inventory</span>
                </Link>

                {/* ORDERS */}
                <Link to="/seller/orders" className="user-nav-item">
                <img src={Orders ? '/images/orders-icon.png' : '/images/orders-icon-gray.png'} className="seller-nav-img" alt="Orders-icon" />
                  <span className={`text-size-xs user-nav-label ${Orders?'green':'gray'}`}>Orders</span>
                </Link>

                {/* PAYMENTS */}
                <Link to="/seller/payments" className="user-nav-item">
                  <img src={Payments ? '/images/payments-icon.png' : '/images/payments-icon-gray.png'} className="seller-nav-img" alt="DPayments-icon" />
                  <span className={`text-size-xs user-nav-label ${Payments?'green':'gray'}`}>Payments</span>
                </Link>

                {/* REQUESTS */}
                {/* <Link to="/seller/requests" className="user-nav-item">
                    <img src={Requests ? '/images/storage-icon.png' : '/images/storage-icon-gray.png'} className="seller-nav-img" alt="Storage-icon" />
                    <span className={`text-size-xs user-nav-label ${Requests?'green':'gray'}`}>Requests</span>
                </Link> */}

                {user && user.role === 'Farmer' ? (
                  <Fragment>
                    {/* STORAGE */}
                    <Link to="/seller/storage" className="user-nav-item">
                      <img src={Storage ? '/images/storage-icon.png' : '/images/storage-icon-gray.png'} className="seller-nav-img" alt="Storage-icon" />
                      <span className={`text-size-xs user-nav-label ${Storage?'green':'gray'}`}>Storage</span>
                    </Link>

                    {/* INSURANCE */}
                    <Link to="/seller/insurance" className="user-nav-item">
                      <img src={Insurance ? '/images/insurance-icon.png' : '/images/insurance-icon-gray.png'} className="seller-nav-img" alt="Insurance-icon" />
                      <span className={`text-size-xs user-nav-label ${Insurance?'green':'gray'}`}>Insurance</span>
                    </Link>

                    {/* LOANS */}
                    <Link to="/seller/loans" className="user-nav-item">
                      <img src={Loans ? '/images/loans-icon.png' : '/images/loans-icon-gray.png'} className="seller-nav-img" alt="Loans-icon" />
                      <span className={`text-size-xs user-nav-label ${Loans?'green':'gray'}`}>Loans</span>
                    </Link>
                  </Fragment>
                ):(
                  <Fragment></Fragment>
                )}
              </div>
            </div>
        </nav>
    </Fragment>
  )
}

export default SellerNavigation