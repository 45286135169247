import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getSellerProducts, clearErrors } from '../../actions/productActions'

import SellerNavigation from '../layout/sellerNavigation'

import { PRODUCT_DETAILS_RESET } from '../../constants/productConstants'


const SellerInventory = () => {

    const dispatch = useDispatch();

    const { loading, error, products } = useSelector(state => state.products);

    useEffect(() => {
        dispatch(getSellerProducts());
        dispatch({ type: PRODUCT_DETAILS_RESET })
        if (error) {
            console.log(error, "ERRROOORR!! - sellerInventory.jsx")
            dispatch(clearErrors())
        }
    }, [dispatch, error]);

    const rowData = [];

    // const onSearchChange = (e) => {
    //     if (e.target.value === "") {
    //         return setFilteredProducts(products)
    //     }
    //     let matches = products.filter(product => product.name.toLowerCase().includes(e.target.value.toLowerCase()))

    //     setFilteredProducts(matches);
    //     firstLoad = false;
    // }

    return (
        <Fragment>
            <Metadata title={'Seller Inventory'}/>
            <SellerNavigation Inventory/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <div className="mid-container-header d-flex justify-content-between align-items-center">
                        <h3 className="text-size-md text-muted d-inline-block m-0">INVENTORY</h3>
                        <span className="text-muted text-size-xs">{products.length} products</span>
                    </div>
                    <div className="d-flex">
                        <input 
                        className="form-control me-2 py-2" 
                        type="search" 
                        placeholder="Search inventory" 
                        aria-label="Search"
                        // onChange={onSearchChange}
                        />
                        <Link to={"/seller/add-product"} className="btn green-btn w-25 plus" type="submit">+<span className="d-none d-sm-inline text-size-xxs"> Add Product</span></Link>
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>
                            <table className="table mt-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">
                                            <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                        </th>
                                        <th scope="col">Product name</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Category</th>
                                        <th className="text-center" scope="col">Stock</th>
                                        <th className="text-center" scope="col">Action</th>
                                    </tr>
                                </thead>
                                {products.forEach(product => rowData.push(
                                    <tr className="bg-white" key={product._id}>
                                        <td><div style={{backgroundImage: `url('${product.images[0].url}')`, backgroundSize: 'cover'}} className="cart-img3 border rounded"></div></td>
                                        <td className="text-size-xs">{product.name}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(product.price)}</td>
                                        <td className="text-size-xs">{product.category}</td>
                                        <td className={`text-center text-size-xs ${product.stock === 0 ? 'red-color' : 'green-color'}`}>{product.stock}</td>
                                        <td className="text-center">
                                            <span className="me-2"><Link to={`/seller/update-product/${product._id}`} state={{ from: "seller" }} className="table-btn">Update</Link></span>
                                            <span><Link to={`/products/${product._id}`} state={{ from: "seller" }} className="table-btn">View</Link></span>
                                        </td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { products.length === 0 ? (
                                <div className="text-center py-3 w-100">
                                    <h3 className='text-size-md'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default SellerInventory