import { 
    GET_DEFAULTS_REQUEST,
    GET_DEFAULTS_SUCCESS,
    GET_DEFAULTS_FAIL,
    UPDATE_DEFAULTS_REQUEST,
    UPDATE_DEFAULTS_SUCCESS,
    UPDATE_DEFAULTS_FAIL,
    UPDATE_DEFAULTS_RESET,
    CLEAR_ERRORS
} from '../constants/defaultsConstants'


export const getDefaultsReducer = (state = { defaults: {} }, action) => {
    switch(action.type) {
        case GET_DEFAULTS_REQUEST:
            return {
                loading: true,
                defaults: []
            }

        case GET_DEFAULTS_SUCCESS:
            return {
                loading: false,
                defaults: action.payload
            }

        case GET_DEFAULTS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const modifyDefaultsReducer = (state = { defaults: {} }, action) => {
    switch(action.type) {
        
        case UPDATE_DEFAULTS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        
        case UPDATE_DEFAULTS_SUCCESS:
            return {
                loading: false,
                success: true,
                defaults: action.payload.defaults

            }

        case UPDATE_DEFAULTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case UPDATE_DEFAULTS_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}