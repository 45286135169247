import axios from 'axios';
import {
    GET_STORAGE_REQUEST,
    GET_STORAGE_SUCCESS,
    GET_STORAGE_FAIL,
    NEW_STORAGE_REQUEST,
    NEW_STORAGE_SUCCESS,
    NEW_STORAGE_FAIL,
    STORAGE_DETAILS_REQUEST,
    STORAGE_DETAILS_SUCCESS,
    STORAGE_DETAILS_FAIL,
    UPDATE_STORAGE_REQUEST,
    UPDATE_STORAGE_SUCCESS,
    UPDATE_STORAGE_FAIL,
    CLEAR_ERRORS
} from '../constants/storageConstants'

export const newStorage = (storageData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_STORAGE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/admin/storage/new', storageData, config)

        dispatch({
            type: NEW_STORAGE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_STORAGE_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getAllStorage = () => async (dispatch) => {
    try {
        dispatch({ type: GET_STORAGE_REQUEST })

        const { data } = await axios.get(`/api/v1/admin/storage`)

        dispatch({
            type: GET_STORAGE_SUCCESS,
            payload: data.storages
        })
    } catch (error) {
        dispatch({
            type: GET_STORAGE_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getSellerStorage = () => async (dispatch) => {
    try {
        dispatch({ type: GET_STORAGE_REQUEST })

        const { data } = await axios.get(`/api/v1/seller/storage`)

        dispatch({
            type: GET_STORAGE_SUCCESS,
            payload: data.storages
        })
    } catch (error) {
        dispatch({
            type: GET_STORAGE_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getStorageDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: STORAGE_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/v1/admin/storage/${id}`)

        dispatch({
            type: STORAGE_DETAILS_SUCCESS,
            payload: data.storage
        })
    } catch (error) {
        dispatch({
            type: STORAGE_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updateStorage = (retrieveNo, id) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_STORAGE_REQUEST,
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.put(`/api/v1/admin/storage/${id}`, {retrieveNo: retrieveNo}, config)

        dispatch({
            type: UPDATE_STORAGE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_STORAGE_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}