import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'

// import { useDispatch, useSelector } from 'react-redux'
// import { getAllInsurance, clearErrors } from '../../actions/insuranceActions'

let loading = false; // REMOVE

const AllInsurance = () => {
    // const dispatch = useDispatch();

    // const { loading, error, insurance } = useSelector(state => state.allInsurance);

    // useEffect(() => {
    //     dispatch(getAllInsurance());

    //     if (error) {
    //         console.log(error, "ERRROORRRR!!")
    //         dispatch(clearErrors())
    //     }
    // }, [dispatch, error])

    // const rowData = [];

    return (
        <Fragment>
            <Metadata title={"All Insurance"}/>
            <AdminNavigation Insurance/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <div className="mid-container-header d-flex justify-content-between align-items-center">
                        <h3 className="text-size-sm text-muted d-inline-block m-0">INSURANCE</h3>
                        {/* <span className="text-muted text-size-xs">{loans.length} loans</span> */}
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>
                            <table className="table mt-3">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">Farmer</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Package</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Expires</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className="bg-white">
                                        <td className="text-size-xs">John Doe</td>
                                        <td className="text-size-xs">Nigeria</td>
                                        <td className="text-size-xs">N500,000</td>
                                        <td className="text-size-xs">Bronze</td>
                                        <td className="text-size-xs">Crysland LTD</td>
                                        <td className="text-size-xs">23/07/2023</td>
                                        <td className="text-size-xs green-color">Active</td>
                                        <td className="text-center"><Link to={`#`} className="table-btn bg-dark">View</Link></td>
                                    </tr>  
                                    <tr className="bg-white">
                                        <td className="text-size-xs">Walter Smith</td>
                                        <td className="text-size-xs">Kano</td>
                                        <td className="text-size-xs">N900,000</td>
                                        <td className="text-size-xs">Silver</td>
                                        <td className="text-size-xs">Green Farms PLC</td>
                                        <td className="text-size-xs">03/11/2022</td>
                                        <td className="text-size-xs red-color">Expired</td>
                                        <td className="text-center"><Link to={`#`} className="table-btn bg-dark">View</Link></td>
                                    </tr> 
                                </tbody>


                                {/* {loans.forEach(insurance => rowData.push(
                                    <tr className="bg-white" key={insurance._id}>
                                        <td className="text-size-xs">{insurance.farmName}</td>
                                        <td className="text-size-xs">{insurance.farmLocation}</td>
                                        <td className="text-size-xs">{insurance.farmSize}</td>
                                        <td className="text-size-xs">{insurance.farmer.firstName} {insurance.farmer.lastName}</td>
                                        <td className="text-center"><Link to={`/admin/insurance/${insurance._id}`} className="table-btn">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody> */}
                            </table>
                            {/* { loans.length == 0 ? (
                                <div className="text-center py-3 w-100">
                                    <h3 className='text-size-sm'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)} */}
                        </Fragment>                              
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default AllInsurance;