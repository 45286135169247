import React, { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Metadata from '../layout/metadata'
import CheckoutSteps from './checkoutSteps'

import { useDispatch, useSelector } from 'react-redux'
import { savePaymentMethod, saveShippingInfo } from '../../actions/cartActions'


const Shipping = () => {

    const { shippingInfo, paymentMethod } = useSelector(state => state.cart)
    const loggedInUser = useSelector(state => state.auth).user;

    const [address, setAddress] = useState(shippingInfo.address)
    const [city, setCity] = useState(shippingInfo.city)
    const [postalCode, setPostalCode] = useState(shippingInfo.postalCode)
    const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo)
    const [method, setMethod] = useState(paymentMethod)
    const country = "Nigeria"

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(saveShippingInfo({address, city, postalCode, phoneNo, method, country}))
        dispatch(savePaymentMethod(method))
        navigate('/checkout/confirm-order')
    }
  return (
    <Fragment>
        <Metadata title={'Checkout - Shipping'}/>
        <h2 className="container-sm mid-container-header text-size-md">CHECKOUT</h2>
        <div className="container-sm mid-container mt-4 pt-4">
            <CheckoutSteps shipping/>
            <form className="px-2" onSubmit={submitHandler}>
                <h3 className="text-size-md mb-5 mt-4">Enter Shipping Information</h3>
                <div className="row">

                    <div className="col-md-12 mb-5">
                        <label htmlFor="address" className="form-label">Address</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="address"
                            name='address'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            aria-describedby="addressHelp" 
                            placeholder="E.g. No 15, Farm warehouse road, Lagos Island"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-5">
                        <label htmlFor="city" className="form-label">City</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="city"
                            name='city' 
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            aria-describedby="cityHelp" 
                            placeholder="E.g. Victoria Island"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-5">
                        <label htmlFor="postalCode" className="form-label">Postal Code</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="postalCode"
                            name='postalCode'
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            aria-describedby="postalCodeHelp" 
                            placeholder="E.g. 121124"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-5">
                        <label htmlFor="phoneNo" className="form-label">Phone Number</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="phoneNo"
                            name='phoneNo'
                            value={phoneNo}
                            onChange={(e) => setPhoneNo(e.target.value)}                  
                            aria-describedby="phoneNoHelp" 
                            placeholder="E.g. 09012345678"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-5">
                        <label htmlFor="Country" className="form-label">Country</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="Country"
                            name='Country' 
                            value = {country}
                            aria-describedby="CountryHelp" 
                            placeholder="E.g. John"
                            readOnly
                        />
                    </div>
                    <div className="col-md-12 mb-5">
                        <label htmlFor="method" className="form-label">Payment Method</label>
                        <select 
                            name="method" 
                            id="method" 
                            className="form-control"
                            required
                            value={method}
                            onChange={(e) => setMethod(e.target.value)}
                            >
                            <option value="">Choose Payment Method</option> 
                            <option>Pay on Delivery (Cash)</option>
                            <option>Pay with Card</option>
                            {loggedInUser && loggedInUser.role === "Farmer" ? <option>Get Financing</option> : <Fragment></Fragment>}
                        </select>
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-5">
                    <Link to="/cart" className="d-flex align-items-center justify-content-center border-btn text-size-xs me-3">Back to Cart</Link>
                    <button type="submit" className="d-flex align-items-center justify-content-center green-btn text-size-xs">Continue</button>
                </div>
            </form>
        </div>
    </Fragment>
  )
}

export default Shipping