import React, { Fragment, useEffect, useState } from 'react'
import Metadata from '../layout/metadata'

// import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getRequestDetails, updateRequestStatus, clearErrors } from '../../actions/requestActions'

import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../layout/loader'

const RequestDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();
    // const alert = useAlert()

    const { request, error, loading } = useSelector(state => state.requestDetails)

    useEffect(() => {
        dispatch(getRequestDetails(params.id))

        if (error) {
            console.log(error, "ERRROOORR!! - request/RequestDetails.jsx")
            // alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Request Details'}/>
            {loading ? <Loader/> : (
                <Fragment>
                    {/* REQUESTS SHOW PAGE */}

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/requests" className="green-color">{"< Back to requests"}</Link>
                    </div>
                    <div className="container-md white-bg shadow-lg mb-5 pt-5">

                    <h3 className="text-size-md">Request <span className="text-muted">#{request._id}</span></h3>
                    
                    <hr className="mb-5"/>
                        <div className="row">
                            <div className="col-12">
                                <p className="text-size-sm">Created: <strong>{request.createdAt ? request.createdAt.split('T')[0] : ""}</strong></p>
                                {request.user ? (
                                    <p className="text-size-sm">Request By: <strong>{request.user.firstName} {request.user.lastName}</strong></p>
                                ):(<Fragment></Fragment>)}
                                <p className="text-size-sm">Status: <strong>{request.status}</strong></p>
                                <p className="text-size-sm">Requested Product: <strong>{request.product}</strong></p>
                                <p className="text-size-sm">Product Category: <strong>{request.category}</strong></p>
                                <p className="text-size-sm">Requested Quantity: <strong>{request.quantity}</strong></p>
                                { request.date ? <p className="text-size-sm">Date Needed: <strong>{request.date.split('T')[0]}</strong></p> : <Fragment></Fragment>}
                                { request.comment ? <p className="text-size-sm">Comments: <strong>{request.comment}</strong></p> : <Fragment></Fragment>}
                                {request.status === "Completed" ? <p className="text-size-sm"><span className="green-color">Completed: </span><strong>{request.completedAt ? request.completedAt.split('T')[0] : ""}</strong></p> : <Fragment></Fragment>}
                                {request.status === "Cancelled" ? <p className="text-size-sm"><span className="red-color">Cancelled: </span><strong>{request.cancelledAt ? request.cancelledAt.split('T')[0] : ""}</strong></p> : <Fragment></Fragment>}

                            </div>

                        </div>
                        
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default RequestDetails