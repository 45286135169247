import React, { Fragment, useEffect, useState } from 'react'
import Metadata from '../layout/metadata'

import SellerNavigation from '../layout/sellerNavigation'

import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getLoanDetails, clearErrors } from '../../actions/loanActions'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useParams  } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../layout/loader'

import { PaystackButton } from 'react-paystack'

let downPayment;
let componentProps = {}

const SellerLoanDetails = ({paystackPK}) => {

    const dispatch = useDispatch();
    const params = useParams();

    const { loan, error, loading, success } = useSelector(state => state.loanDetails);
    const { loading: updateLoading, error: updateError } = useSelector(state => state.newLoan);

    let [amount, setAmount] = useState(0);

    if (loan.loanDetails) {
        componentProps = {
            email: loan.userDetails.emailAddress,
            amount: (parseFloat(amount) * 100).toFixed(2),
            metadata: {
              name: `${loan.userDetails.firstName} ${loan.userDetails.lastName}`,
              phone: loan.userDetails.mobileNumber,
            },
            publicKey: paystackPK,
            text: `Pay ${"\u20A6" + numberWithCommas(amount)}`,
            onSuccess: () => {
                toast.success("Successfully paid! 👍")
            },
            onClose: () => {}
          }
    }

    useEffect(() => {
        dispatch(getLoanDetails("Farmer", params.id));

        if (error) {
            toast.error("Error fetching loan details, please check your internet connection.")
            dispatch(clearErrors())
        }

        if (success) {
            setAmount(loan.loanDetails.payments.length === 0 ? parseFloat(downPayment) : parseFloat(loan.loanDetails.monthlyRepayment))
        }

    }, [dispatch, error, success ])

    const rowData = []

    if (loan.loanDetails) downPayment = (parseInt(loan.loanDetails.downPayment)/100 * loan.loanDetails.totalAmountToRepayAfterDiscount).toFixed(2)

    return (
        <Fragment>
            <Metadata title={'Loan Details'}/>
            <SellerNavigation Loans/>
            <ToastContainer/>

            {loading ? <Loader/> : (
                <Fragment>

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/seller/loans" className="green-color">{"< Back to loans"}</Link>
                    </div>

                    <div className="container-md white-bg shadow-lg mb-5 pt-5">
                        <h3 className="text-size-md">LOAN <span className="text-muted">#{loan._id}</span></h3>
                        
                        <hr className="mb-5"/>

                        {/* DETAILS */}
                        <div className="row">
                            <div className="col-md-6">
                                {loan.loanDetails ? (
                                    <div className="">
                                        <h5 className="bold-2 text-size-sm mb-4">Loan Details</h5>
                                        <p className="text-size-sm">Loan Type: <strong>{loan.type}</strong></p>
                                        <p className="text-size-sm">Created: <strong>{loan.createdAt ? loan.createdAt.split('T')[0] : ""}</strong></p>
                                        <p className="text-size-sm">Order: <Link to={`/orders/${loan.order}`} className="green-color bold-2">#{loan.order}</Link></p>
                                        <p className="text-size-sm">Product Total: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.amountRequired)} (Incl. VAT & Shipping)</strong></p>
                                        <p className="text-size-sm">Amount to Repay: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountToRepayAfterDiscount)}</strong></p>
                                        <p className="text-size-sm">Down Payment: <strong>{loan.loanDetails.downPayment} (&#x20A6;{numberWithCommas((loan.loanDetails.totalAmountToRepayAfterDiscount * (parseInt(loan.loanDetails.downPayment) / 100)).toFixed(2))}) - {loan.loanDetails.payments.length === 0 ? "Not Paid" : "Paid"}</strong></p>
                                        <p className="text-size-sm">Monthly Repayments: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.monthlyRepayment)}/month (After Down Payment)</strong></p>
                                        <p className="text-size-sm">Amount Repayed: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.totalAmountRepaid)}</strong></p>
                                        <p className="text-size-sm">No. of payments made: <strong>{loan.loanDetails.payments.length}</strong></p>
                                        <p className="text-size-sm">Amount Due: <strong>&#x20A6;{numberWithCommas(loan.loanDetails.amountDue)}</strong></p>
                                        <p className="text-size-sm">Date Due: <strong>{loan.loanDetails.dateDue ? loan.loanDetails.dateDue.split("T")[0] : "[ Set after approval ]"}</strong></p>
                                        <p className="text-size-sm">Interest Rate: <strong>{loan.loanDetails.interestRate}</strong></p>
                                        <p className="text-size-sm">Term: <strong>{loan.loanDetails.duration}</strong></p>
                                        <p className="text-size-sm">Discount: <strong>{loan.loanDetails.discount}</strong></p>
                                        <p className="text-size-sm">Financier: <strong>{loan.loanDetails.financier}</strong></p>
                                        <p className="text-size-sm m-0">Association: <strong> {loan.loanDetails.association}</strong></p>
                                    </div>
                                ):(<Fragment></Fragment>)}

                                {loan.userDetails ? (
                                    <div className="">
                                        <hr className="w-25 my-4" />
                                        <h5 className="bold-2 text-size-sm my-4">Your Details</h5>
                                        <p className="text-size-sm">Applicant: <strong>{loan.userDetails.firstName} {loan.userDetails.lastName}</strong></p>
                                        <p className="text-size-sm">Address: <strong>{loan.userDetails.address}</strong></p>
                                        <p className="text-size-sm">Telephone: <strong> {loan.userDetails.mobileNumber}</strong></p>
                                        <p className="text-size-sm">BVN: <strong> {loan.userDetails.BVN}</strong></p>
                                        <p className="text-size-sm">NIN: <strong> {loan.userDetails.NIN}</strong></p>
                                        <p className="text-size-sm">Next of Kin: <strong>{loan.userDetails.NOK}</strong></p>
                                        <p className="text-size-sm">Relationship with NOK: <strong>{loan.userDetails.NOKrelationship}</strong></p>
                                        <p className="text-size-sm">Address of NOK: <strong>{loan.userDetails.NOKaddress}</strong></p>
                                    </div>
                                ):(<Fragment></Fragment>)}
                            </div>

                            {/* UPDATE */}
                            <div className="col-md-6">
                                {loan.loanDetails ? (
                                    <form className="border border-success rounded p-4">
                                        {/* PAY LOAN */}
                                        <h5 className="text-size-xs mt-2">Select Payment Amount (₦)</h5>
                                        <i className="d-block text-size-xs mt-3 mb-2">Min - ₦{loan.loanDetails.payments.length === 0 ? numberWithCommas(downPayment) : numberWithCommas(loan.loanDetails.monthlyRepayment)}, Max - ₦{numberWithCommas(loan.loanDetails.totalAmountToRepayAfterDiscount - loan.loanDetails.totalAmountRepaid)}</i>
                                        <input type="number" 
                                            defaultValue={amount} 
                                            className="form-control mb-3 w-75" name="payment" 
                                            min={loan.loanDetails.payments.length === 0 ? downPayment : loan.loanDetails.monthlyRepayment} 
                                            max={loan.loanDetails.totalAmountToRepayAfterDiscount - loan.loanDetails.totalAmountRepaid}
                                            placeholder={`Max - ${loan.loanDetails.totalAmountToRepayAfterDiscount - loan.loanDetails.totalAmountRepaid}` } 
                                            readOnly={loan.status !== "Approved"}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                        <p className="text-size-sm"><span className="bold-2">₦{loan.loanDetails.payments.length === 0 ? numberWithCommas(downPayment) : numberWithCommas(loan.loanDetails.monthlyRepayment)}</span> due by {loan.loanDetails.dateDue ? loan.loanDetails.dateDue.split("T")[0] : "[ Set after approval ]"}</p>
                                        
                                        <PaystackButton {...componentProps} className="green-btn text-size-xs mt-1 py-2 px-3"/>

                                        {updateLoading ? (
                                            <i className="d-block text-muted text-size-xxs">Working...</i>
                                        ):(<Fragment></Fragment>)}
                                    </form>
                                    ):<Fragment></Fragment>
                                }
                            </div>
                        </div>

                            
                        <hr className="my-4" />

                        { loan.productDetails ?
                            <Fragment>
                                <div className="d-flex justify-content-between align-items-center my-4">
                                    <h3 className="text-size-sm bold-2 m-0">Products</h3>
                                    <span className="text-size-xs text-muted">({loan.productDetails.length} Products)</span>
                                </div>
                        
                                <table className="table">
                                    <thead className="table-secondary py-5">
                                        <tr>
                                            <th scope="col">
                                                <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                            </th>
                                            <th scope="col">Product name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    {
                                        loan.productDetails.forEach(item => rowData.push(
                                            <tr className="bg-white" key={item._id}>
                                                <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="cart-img3 bloan rounded"></div></td>
                                                <td className="text-size-xs">{item.name}</td>
                                                <td className="text-size-xs">&#x20A6;{numberWithCommas(item.price)}</td>
                                                <td className="text-size-xs">{numberWithCommas(item.quantity)}</td>
                                                <td className="text-size-xs">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                            </tr>                                 
                                        ))
                                    }

                                    <tbody>{rowData}</tbody>
                                </table>
                            </Fragment>:<Fragment></Fragment>
                        }
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default SellerLoanDetails;