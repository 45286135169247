import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { forgotPassword, clearErrors } from '../../actions/userActions'
import { FORGOT_PASSWORD_RESET } from '../../constants/userConstants';

const ForgotPassword = () => {

    const [credential, setCredential] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { error, loading, success, message } = useSelector(state => state.forgotPassword);

    useEffect(() => {

        if (error) {
            toast.error(error)
            dispatch(clearErrors());
        }

        if (success) {
            toast.success(message)
            dispatch({ type: FORGOT_PASSWORD_RESET })
            navigate('/signin')
        }

    }, [dispatch, success, error])
 
    const submitHandler = (e) => {
        e.preventDefault();

        if (credential.length === 0) return toast.error("Please enter a valid credential")
        else if (credential.charAt(0).match(/[a-z]/i) && !credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/)) return toast.error("Please enter a valid email address")
        else if (!isNaN(parseInt(credential.charAt(0))) || (credential.charAt(0) === "+" && (credential.match(/[a-z]/i) || credential.length !== 14))) return toast.error("Please enter a valid phone number")

        dispatch(forgotPassword(credential));
    }

    return (
        <Fragment>
            <Fragment>
                <Metadata title={'Forgot Password'} />
                <ToastContainer />
                <div className="container-md mid-container shadow-lg p-0 my-0 my-md-5 mx-md-auto">
                    <div className="row">
                        <div className="col-md-8 p-4 py-2 p-md-5">
                            <form className="px-2" onSubmit={submitHandler}>
                                <div className="mb-3">
                                    <h3 className="text-size-md mb-5 mt-4">Forgot Password</h3>
                                    <label htmlFor="credential" className="form-label">Email address or Phone number</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="credential" 
                                        aria-describedby="credentialHelp" 
                                        placeholder="E.g. john@email.com or +2348012345678"
                                        value={credential}
                                        onChange={(e) => setCredential(e.target.value)}
                                        required
                                    />
                                    <div id="credentialHelp" className="text-size-xxs mt-2 red-color">
                                        {   credential.length === 0 ? "Please enter a valid credential"
                                            : credential.charAt(0).match(/[a-z]/i) ? (credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/) ? <strong className="green-color">A verification link will be sent to this email</strong> : "Please enter a valid email address")
                                            :!isNaN(parseInt(credential.charAt(0))) ? "Phone number must have country code. E.g. +234"
                                            : credential.charAt(0) === "+" && credential.length < 14 ? "Keep typing..." 
                                            : credential.match(/[a-z]/i) || credential.length !== 14? "Enter a valid phone number"
                                            : <strong className="green-color">Your login password will be sent to this phone number</strong>
                                        } 
                                    </div>  
                                </div>

                                <button 
                                    type="submit" 
                                    className="text-size-xs green-btn mb-5 w-100"
                                    disabled={loading ? true : false}
                                    >{loading ? "Resetting, please wait..." : "Reset password"}</button>
                            </form>
                        </div>
                        <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/sign-in-image.png')", backgroundSize: "cover"}}>
                            <img src="/images/fm-logo-white.png" className="white-logo my-4 me-4" alt="Farmwarehouse Logo"/>
                        </div>
                    </div>
                </div>    
            </Fragment>     
        </Fragment>
    )}

export default ForgotPassword;