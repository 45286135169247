import React, { Fragment, useEffect } from 'react'
import { Link, useNavigate} from 'react-router-dom'

import Loader from '../layout/loader'
import Metadata from '../layout/metadata'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getNotifications, clearErrors } from '../../actions/notificationActions'

const Notification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, notifications } = useSelector(state => state.getNotifications);

    useEffect(() => {
        dispatch(getNotifications());

        if (error) {
            console.log(error)
            toast.error("An error has occurred, check your internet connection and refresh the page.")
            dispatch(clearErrors())
        }
    }, [dispatch, error])


    const goBack = () => {
        navigate(-1)
    }

  return (
    <Fragment>
        <ToastContainer />
        <Metadata title={'Your Notifications'}/>
        {loading ? <Loader/> : (
            <Fragment>
                <div className="container-md green-color my-4 ps-4 ps-md-2">
                    <a href="#" onClick={goBack} className="green-color">{"< Go Back"}</a>
                </div>
                <div className="container-md white-bg shadow-lg my-0 mb-md-5">

                    {notifications.length === 0 ? 
                        <h2 className="mt-3 text-size-md">You have no notifications at this time.</h2> : (
                        <Fragment>
                            <div className="d-flex justify-content-between align-items-center">
                                <h3 className="text-size-sm text-muted m-0">NOTIFICATIONS</h3>
                                <span className="text-size-xs">{notifications[0].messages.length} {notifications[0].messages.length === 1 ? "Notification": "Notifications"}</span>
                            </div>
                            <hr className="my-3"/>
                            {notifications[0].messages.reverse().map(message => (
                                <Fragment key={message._id}>
                                    <Link to={`/seller/orders/${message.referenceId}`} className="d-flex justify-content-between mb-2 bg-light px-2 py-3">
                                        <div className="d-flex align-items-center">
                                            {message.new ? 
                                                <Fragment>
                                                    <span className="red-circle">🔴</span>
                                                    <strong className="text-size-xs">{message.message}</strong>
                                                </Fragment> 
                                            : <span className="text-size-xs">{message.message}</span> }
                                        </div>
                                        <div className="cart-qty-box">
                                            <span className="text-size-xs">{message.date.split('T')[0]} ({message.date.split('T')[1].split('.')[0]})</span>
                                        </div>
                                    </Link>
                                </Fragment>
                            ))}
                        </Fragment>
                    )}
                </div>
            </Fragment>
        )}
    </Fragment>
  )
}

export default Notification;