import React, { Fragment, useEffect } from 'react'

import Metadata from './layout/metadata'
import ProductCard from './product/productCard'
import Loader from './layout/loader'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getProducts } from '../actions/productActions'


const Home = () => {

    const dispatch = useDispatch();
    const { loading, products, error } = useSelector(state => state.products)
    const { user } = useSelector(state => state.auth);

    // Group products by category
    const productsByCategory = products.reduce((acc, product) => {
        const { category } = product;
        if (!acc[category]) {
            acc[category] = [];
        }
        if (acc[category].length < 12) {
            acc[category].push(product);
        }
        return acc;
    }, {});

    // Sort products within each category by name
    for (const category in productsByCategory) {
        productsByCategory[category].sort((a, b) => a.name.localeCompare(b.name));
    }

    useEffect(() => {
        if (error) {
            console.log(error, "ERRROOORR!! - home.jsx")
        }
        dispatch(getProducts());

    }, [dispatch, error])

    return (
        
        <Fragment>
            <Metadata title={'Buy the best Farm Products'}/>
            {/* BUYER HERO IMAGE */}
            {user && (user.farmerStatus === "Pending" || user.vendorStatus === "Pending") ? (
            <div className="info text-center bg-warning py-2 text-size-xs">Your Seller account is pending admin approval. Your dashboard will be active when approved.</div>) 
            : (<Fragment></Fragment>)}
            
            <div className="container-fluid hero-section" style={{backgroundImage: "url('/images/hero-image2-dark.png')"}}>
                <div className="container d-flex flex-column justify-content-center h-100 px-0">
                    <h1 className="hero-header">One Stop Shop for Everything Agriculture.</h1>
                    
                    <div className="d-flex align-items-center flex-wrap">
                        <span className="text-size-xs white-color">Farm Equipment</span><span className="round-divider white-color mx-2">•</span>
                        <span className="text-size-xs white-color">Farm Inputs</span><span className="round-divider white-color mx-2">•</span>
                        <span className="text-size-xs white-color">Fresh & Processed Produce</span><span className="round-divider white-color mx-2">•</span>
                        <span className="text-size-xs white-color">Loans</span><span className="round-divider white-color mx-2">•</span>
                        <span className="text-size-xs white-color">Insurance</span><span className="round-divider white-color mx-2">•</span>
                        <span className="text-size-xs white-color">Produce Certification</span><span className="round-divider white-color mx-2">•</span>
                        <span className="text-size-xs white-color">Capacity Building</span>
                    </div>

                    <Link to="/requests/new" className="white-btn mt-4">Request a Product</Link>
                </div>
            </div>

            {loading ? <Loader/> : (
                <Fragment>
                    {/* PRODUCTS SHOW PAGE */}
                    <div className="container mt-5">
                        {Object.entries(productsByCategory).map(([category, productsInCategory]) => (
                            <Fragment key={category}>
                                <div className="cat-box d-flex align-items-center justify-content-between mb-0">
                                    <h3 className="text-size-sm mb-0">{category}</h3>
                                    <Link to={`/products/${category.toLowerCase()}`} state={{category, productsInCategory: products
                                        .filter(product => product.category === category)
                                        .sort((a, b) => a.name.localeCompare(b.name))}} className="white text-size-sm">{"See all >"}</Link>
                                </div>

                                <div className="row m-0 mb-3 mb-md-5">
                                    { productsInCategory.map((product) => (
                                        <ProductCard key={product._id} product={product}/>
                                    ))}
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Home