import React, { Fragment, useEffect } from 'react'
import Metadata from '../layout/metadata'
import { numberWithCommas } from '../../Functions/functions'

// import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getSellerOrderDetails, clearErrors } from '../../actions/orderActions'
import { useNavigate } from 'react-router-dom'

import { useParams } from 'react-router-dom'
import Loader from '../layout/loader'
import SellerNavigation from '../layout/sellerNavigation'

const SellerOrderDetails = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const { order, error, loading } = useSelector(state => state.orderDetails)

    let totalPrice = 0;
    let productsPrice = 0;


    if (order.orderItems) { productsPrice = order.orderItems.map(product => product.price * product.quantity).reduce((a, c) => a + c)};
    totalPrice += productsPrice;

    useEffect(() => {
        dispatch(getSellerOrderDetails(params.id))

        if (error) {
            console.log(error, "ERRROOORR!! - sellersOrders.jsx")
            // alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const rowData = []

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Fragment>
            <Metadata title={'Order Details'}/>
            <SellerNavigation Orders/>

            {loading ? <Loader/> : (
                <Fragment>

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <div onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</div>
                    </div>

                    <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                        <h3 className="text-size-md">ORDER <span className="text-muted">#{order._id}</span></h3>
                        
                        <hr className="my-4" />

                        <div>
                            <p className="text-size-sm">Created: <strong>{order.createdAt ? order.createdAt.split('T')[0]:""}</strong></p>
                            {order.user ? (
                                <p className="text-size-sm">Order By: <strong>{order.user.firstName} {order.user.lastName} ({order.shippingInfo.phoneNo})</strong></p>
                            ):(<Fragment></Fragment>)}
                            <p className="text-size-sm">Status: <strong>{order.orderStatus}</strong></p>
                            <p className="text-size-sm">Items Price: <strong> &#x20A6;{numberWithCommas(totalPrice)}</strong></p>
                            <p className="text-size-sm">Payment Status: <strong>{order.paidAt ? "Paid" : "Not Paid"}</strong></p>
                            <p className="text-size-sm">Payment Method: <strong>{order.paymentMethod}</strong></p>
                            <i className="d-block text-size-sm red-color">{order.change && order.orderStatus !== "Delivered" && order.orderStatus !== "Cancelled"? `(Customer requires ${"\u20A6" + numberWithCommas(order.change)} change)`: ""}</i>
                            {order.orderStatus === "Delivered" ? <p className="text-size-sm"><span className="green-color">Delivered: </span><strong>{order.deliveredAt ? order.deliveredAt.split('T')[0] : ""}</strong></p> : <Fragment></Fragment>}
                            {order.orderStatus === "Cancelled" ? <p className="text-size-sm"><span className="red-color">Cancelled: </span><strong>{order.cancelledAt ? order.cancelledAt.split('T')[0] : ""}</strong></p> : <Fragment></Fragment>}

                        </div>
 

                        <hr className="my-4" />

                        <div className="d-flex justify-content-between align-items-center my-4">
                            <h3 className="text-size-md m-0">Products in Order</h3>
                            {order.orderItems ? (
                                <span className="text-size-sm text-muted">({order.orderItems.length} Products)</span>
                            ):(<Fragment></Fragment>)}
                        </div>
                
                        <table className="table">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">
                                        <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                    </th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            {order.orderItems ? (
                                order.orderItems.forEach(item => rowData.push(
                                    <tr className="bg-white" key={item._id}>
                                        <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="cart-img3 border rounded"></div></td>
                                        <td className="text-size-xs">{item.name}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(item.price)}</td>
                                        <td className="text-size-xs">{numberWithCommas(item.quantity)}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                    </tr>                                 
                                ))
                            ):(<Fragment></Fragment>)}

                            <tbody>{rowData}</tbody>
                        </table>

                        {order.shippingInfo ? (
                            <Fragment>
                                <h3 className="text-size-md mt-5 mb-4">Shipping Information</h3>
                                <p className="text-size-sm">Address: <strong>{order.shippingInfo.address}</strong></p>
                                <p className="text-size-sm">City: <strong>{order.shippingInfo.city}</strong></p>
                                <p className="text-size-sm">Postal Code: <strong>{order.shippingInfo.postalCode}</strong></p>
                                <p className="text-size-sm">Country: <strong>{order.shippingInfo.country}</strong></p>
                                <p className="text-size-sm">Phone Number: <strong>{order.shippingInfo.phoneNo}</strong></p>
                            </Fragment>
                        ):(<Fragment></Fragment>)}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default SellerOrderDetails