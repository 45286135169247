import React, { Fragment, useEffect } from 'react'
import Metadata from '../layout/metadata'
import { numberWithCommas } from '../../Functions/functions'

// import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetails, clearErrors } from '../../actions/orderActions'

import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../layout/loader'

const OrderDetails = () => {

    const dispatch = useDispatch();
    // const alert = useAlert()
    const { order, error, loading } = useSelector(state => state.orderDetails)

    const params = useParams();

    useEffect(() => {
        dispatch(getOrderDetails(params.id))

        if (error) {
            console.log(error, "ERRROOORR!! - order/orderDetails.jsx")
            // alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Order Details'}/>

            {loading ? <Loader/> : (
                <Fragment>

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/orders" className="green-color">{"< Back to Orders"}</Link>
                    </div>

                    <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                        <h3 className="text-size-md">ORDER <span className="text-muted">#{order._id}</span></h3>
                        
                        <hr className="my-4" />

                        <p className="text-size-sm">Created: <strong>{order.createdAt}</strong></p>
                        {order.user ? (
                            <p className="text-size-sm">Order By: <strong>{order.user.firstName} {order.user.lastName} ({order.shippingInfo.phoneNo})</strong></p>
                            ):(<Fragment></Fragment>)
                        }
                        <p className="text-size-sm">Status: <strong>{order.orderStatus}</strong></p>
                        <p className="text-size-sm">Items Price: <strong> &#x20A6;{numberWithCommas(order.itemsPrice)}</strong></p>
                        <p className="text-size-sm">Shipping: <strong> &#x20A6;{numberWithCommas(order.shippingPrice)}</strong></p>
                        <p className="text-size-sm">Tax: <strong> &#x20A6;{numberWithCommas(order.taxPrice)}</strong></p>
                        <p className="text-size-sm">Total: <strong className="green-color"> &#x20A6;{numberWithCommas(order.totalPrice)}</strong></p>
                        <p className="text-size-sm">Payment Status: <strong>{order.paidAt ? "Paid" : "Not Paid"}</strong></p>
                        {order.shippingInfo ? (
                            <p className="text-size-sm">Payment Method: <strong>{order.paymentMethod}</strong>{order.financeStatus ? ` - ${order.financeStatus}` : ""}</p>
                        ):(<Fragment></Fragment>)}
                        
                        <hr className="my-4" />

                        <div className="d-flex justify-content-between align-items-center my-4">
                            <h3 className="text-size-md m-0">Products in Order</h3>
                            {order.orderItems ? (
                                <span className="text-size-sm text-muted">({order.orderItems.length} Products)</span>
                            ):(<Fragment></Fragment>)}
                        </div>
                
                        <table className="table">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">
                                        <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                    </th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            {order.orderItems ? (
                                order.orderItems.forEach(item => rowData.push(
                                    <tr className="bg-white" key={item._id}>
                                        <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="cart-img3 border rounded"></div></td>
                                        <td className="text-size-xs">{item.name}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(item.price)}</td>
                                        <td className="text-size-xs">{numberWithCommas(item.quantity)}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                    </tr>                                 
                                ))
                            ):(<Fragment></Fragment>)}

                            <tbody>{rowData}</tbody>
                        </table>

                        {order.shippingInfo ? (
                            <Fragment>
                                <h3 className="text-size-md mt-5 mb-4">Shipping Information</h3>
                                <p className="text-size-sm">Address: <strong>{order.shippingInfo.address}</strong></p>
                                <p className="text-size-sm">City: <strong>{order.shippingInfo.city}</strong></p>
                                <p className="text-size-sm">Postal Code: <strong>{order.shippingInfo.postalCode}</strong></p>
                                <p className="text-size-sm">Country: <strong>{order.shippingInfo.country}</strong></p>
                                <p className="text-size-sm">Phone Number: <strong>{order.shippingInfo.phoneNo}</strong></p>
                            </Fragment>
                        ):(<Fragment></Fragment>)}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default OrderDetails