import { 
    GET_LOANS_REQUEST,
    GET_LOANS_SUCCESS,
    GET_LOANS_FAIL,
    NEW_LOAN_REQUEST,
    NEW_LOAN_SUCCESS,
    NEW_LOAN_RESET,
    NEW_LOAN_FAIL,
    UPDATE_LOAN_REQUEST,
    UPDATE_LOAN_SUCCESS,
    UPDATE_LOAN_FAIL,
    UPDATE_LOAN_RESET,
    DELETE_LOAN_REQUEST,
    DELETE_LOAN_SUCCESS,
    DELETE_LOAN_FAIL,
    DELETE_LOAN_RESET,
    LOAN_DETAILS_REQUEST,
    LOAN_DETAILS_SUCCESS,
    LOAN_DETAILS_RESET,
    LOAN_DETAILS_FAIL,
    CLEAR_ERRORS
} from '../constants/loanConstants'

export const loanReducer = (state = { loans:[] }, action) => {
switch(action.type) {
    case GET_LOANS_REQUEST:
        return {
            loading: true,
            loans: []
        }

    case GET_LOANS_SUCCESS:
        return {
            loading: false,
            loans: action.payload
        }

    case GET_LOANS_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const modifyLoanReducer = (state = { loan: {} }, action) => {
switch(action.type) {
    case NEW_LOAN_REQUEST:
    case UPDATE_LOAN_REQUEST:
    case DELETE_LOAN_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case NEW_LOAN_SUCCESS:
    case UPDATE_LOAN_SUCCESS:
    case DELETE_LOAN_SUCCESS:
        return {
            loading: false,
            success: action.payload.success,
            loan: action.payload.loan

        }

    case NEW_LOAN_FAIL:
    case UPDATE_LOAN_FAIL:
    case DELETE_LOAN_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }

    case NEW_LOAN_RESET:
    case UPDATE_LOAN_RESET:
    case DELETE_LOAN_RESET:
        return {
            ...state,
            success: false,
            loan: {}
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const loanDetailsReducer = (state = {loan: {}}, action) => {
switch (action.type) {
    case LOAN_DETAILS_REQUEST:
        return {
            ...state,
            loading: true
            
        }
    case LOAN_DETAILS_SUCCESS:
        return {
            loading: false,
            success: true,
            loan: action.payload
        }
    case LOAN_DETAILS_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case LOAN_DETAILS_RESET:
        return {
            loading: false,
            success: false,
            loan: {}
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state
}
}