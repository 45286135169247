import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { PaystackButton } from 'react-paystack'

import Metadata from '../layout/metadata'
import CheckoutSteps from './checkoutSteps'
import { numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { createOrder, clearErrors } from '../../actions/orderActions'

import { emptyCart } from '../../actions/cartActions'


const Payment = ({paystackPK}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { error } = useSelector(state => state.modifyOrder)
    const [change, setChange] = useState("")

    useEffect(() => {
        if (error) {
            console.log(error, "ERRROORRRR!!")

            dispatch(clearErrors())
        }
    }, [dispatch, error]);

    const { amount, firstName, lastName, email, phoneNo, cartItems, shippingInfo, paymentMethod } = location.state;
    
    const publicKey = paystackPK

    const componentProps = {
        email: email,
        amount: Math.round(amount * 100),
        metadata: {
          name: `${firstName} ${lastName}`,
          phone: phoneNo,
        },
        publicKey,
        text: `Pay ${"\u20A6" + numberWithCommas(location.state.amount)}`,
        onSuccess: () => {
            order.paidAt = new Date();
            dispatch(createOrder(order))
            navigate('/checkout/success')
        },
        onClose: () => {}
      }

    const order = {
        orderItems: cartItems,
        shippingInfo,
        paymentMethod
    }

    const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));

    if (orderInfo) {
        order.itemsPrice = orderInfo.itemsPrice
        order.shippingPrice = orderInfo.shippingPrice
        order.taxPrice = orderInfo.taxPrice
        order.totalPrice = orderInfo.totalPrice
    }

    const onClickPlaceOrder = () => {
        order.change = change;
        dispatch(createOrder({orderData: order}))
        dispatch(emptyCart())
        navigate('/checkout/success')
    }

  return (
    <Fragment>
        <Metadata title={'Checkout - Payment'}/>
        <h2 className="container-sm mid-container-header text-size-md">CHECKOUT</h2>
        <div className="container-sm mid-container mt-4 pt-4">
            <CheckoutSteps shipping confirmOrder payment/>
            <h3 className="text-size-md mt-5"><strong>Payment</strong></h3>
            <hr />
            {paymentMethod === "Pay with Card" ? (
                <Fragment>
                    <div className="payment-box p-4">
                        <h5 className="text-size-sm">{paymentMethod}</h5>
                        <img src="/images/Paystack.png" alt="Paystack Icon" className="d-block my-4" width="100" height="18"/>
                        <div className="d-flex justify-content-end mt-5">
                            <Link to="/checkout/confirm-order" className="d-flex align-items-center justify-content-center border-btn text-size-xs me-3">Back to Confirmation</Link>
                            <PaystackButton {...componentProps} className="d-flex align-items-center justify-content-center green-btn text-size-xs"/>
                        </div>
                    </div>

                </Fragment>
                ):(
                <Fragment>
                    <h5 className="text-size-sm">{paymentMethod} - &#x20A6;{numberWithCommas(amount)}</h5>
                    <label htmlFor="change" className="text-size-xs mt-3 my-2">Do you need change? Enter amount below. <i>(Leave blank if you have the exact amount for your order)</i></label>
                    <input 
                        type="number" 
                        id="change" 
                        className="form-control mb-4" 
                        value={change}
                        onChange={(e) => setChange(e.target.value)}
                        placeholder="E.g. 500" />
                    <div className="d-flex justify-content-end mt-5">
                        <Link to="/checkout/confirm-order" className="d-flex align-items-center justify-content-center border-btn text-size-xs me-3">Back to Confirmation</Link>
                        <button onClick={onClickPlaceOrder} className="d-flex align-items-center justify-content-center green-btn text-size-xs">Place Order</button>
                    </div>
                </Fragment>
                )}
        </div>
    </Fragment>
  )
}

export default Payment