import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Metadata from './metadata'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getDefaults, modifyDefaults, clearErrors } from '../../actions/defaultsActions'
import { UPDATE_DEFAULTS_RESET } from '../../constants/defaultsConstants'

const SetDefaults = ({ type }) => {
    let firstLoad = useRef(true)
    const dispatch = useDispatch();

    const { error, defaults } = useSelector(state => state.getDefaults);

    const { success } = useSelector(state => state.modifyDefaults);

    const [defaultsData, setDefaultsData] = useState({
        interestRate1: 0,
        interestRate2: 0
    })

    let { interestRate1, interestRate2 } = defaultsData;

    if (firstLoad.current && defaults.interestRate1) {
        setDefaultsData({
            interestRate1: defaults.interestRate1,
            interestRate2: defaults.interestRate2
        })
        firstLoad.current = false
        
    }

    useEffect(() => {
        dispatch(getDefaults());

        if (error) {
            console.log(error)
            dispatch(clearErrors())
        }
        if (success) {
            toast.success("Operation successful")
            dispatch({ type: UPDATE_DEFAULTS_RESET })
        }
    }, [dispatch, error, success])

    const onChange = (e) => {
        setDefaultsData({...defaultsData, [e.target.name]: e.target.value });
    }

    const setDefaultsHandler = () => {
        dispatch(modifyDefaults(defaultsData));
    }

    return (
        <Fragment>
            <Metadata title={'All Loans'}/>
            <ToastContainer />
            {/* Modal */}
            <div className="modal fade" id="default-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-size-sm" id="default-modal-label">Set {type} default</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        
                        <label htmlFor="interestRate1" className="form-label">interest rate (1 year)</label> <span className="red-color">*</span>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="interestRate1"
                            name='interestRate1' 
                            value={interestRate1}
                            onChange={onChange}
                            aria-describedby="interestRate1Help" 
                            placeholder="E.g. 10"
                            required
                        />

                        <label htmlFor="interestRate2" className="form-label">Interest rate (2+ years)</label> <span className="red-color">*</span>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="interestRate2"
                            name='interestRate2' 
                            value={interestRate2}
                            onChange={onChange}
                            aria-describedby="interestRate2Help" 
                            placeholder="E.g. 20"
                            required
                        />

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" onClick={setDefaultsHandler} data-bs-dismiss="modal" className="btn btn-danger">Set</button>
                    </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
    }

export default SetDefaults
