export const GET_STORAGE_REQUEST = 'GET_STORAGE_REQUEST'
export const GET_STORAGE_SUCCESS = 'GET_STORAGE_SUCCESS'
export const GET_STORAGE_FAIL = 'GET_STORAGE_FAIL'

export const NEW_STORAGE_REQUEST = 'NEW_STORAGE_REQUEST'
export const NEW_STORAGE_SUCCESS = 'NEW_STORAGE_SUCCESS'
export const NEW_STORAGE_RESET = 'NEW_STORAGE_RESET'
export const NEW_STORAGE_FAIL = 'NEW_STORAGE_FAIL'

export const STORAGE_DETAILS_REQUEST = 'STORAGE_DETAILS_REQUEST'
export const STORAGE_DETAILS_SUCCESS = 'STORAGE_DETAILS_SUCCESS'
export const STORAGE_DETAILS_FAIL = 'STORAGE_DETAILS_FAIL'

export const UPDATE_STORAGE_REQUEST = 'UPDATE_STORAGE_REQUEST'
export const UPDATE_STORAGE_SUCCESS = 'UPDATE_STORAGE_SUCCESS'
export const UPDATE_STORAGE_FAIL = 'UPDATE_STORAGE_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'