import React, { Fragment } from 'react'

const Footer = () => {
  return (
    <Fragment>
        <footer className="container-fluid footer bg-dark">
            <div className="container-md footer-content">
                <div className="col-md-3 d-block d-md-none">
                    <img src="/images/fm-logo-white.png" alt="" width="160px" height="40px"/>
                </div>
                <div className="row">
                    <div className="col-md-3 d-none d-md-block">
                        <img src="/images/fm-logo-white.png" alt="" width="160px" height="40px"/>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 mt-5 mt-md-0">
                        <div className="d-flex flex-column">
                            <h3 className="footer-header">Company</h3>
                            <a href="" className="footer-link">About Us</a>
                            <a href="" className="footer-link">Contact</a>
                            <a href="" className="footer-link">Privacy Policy</a>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3 mt-5 mt-md-0">
                        <div className="d-flex flex-column">
                            <h3 className="footer-header">Resources</h3>
                            <a href="" className="footer-link">Forum</a>
                            <a href="" className="footer-link">Blog</a>
                            <a href="" className="footer-link">Market Intelligence</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 mt-5 mt-md-0">
                        <div className="d-flex flex-column">
                            <h3 className="footer-header">Support</h3>
                            <a href="" className="footer-link">Help</a>
                            <a href="" className="footer-link">Buying on Farm Warehouse</a>
                            <a href="" className="footer-link">Selling on Farm Warehouse</a>
                        </div>
                    </div>
                </div>

                <div className="footer-seperator"></div>

                <div className="footer-bottom d-flex justify-content-between align-items-center flex-column flex-sm-row">
                    <div className="social-media-icons-block d-flex my-3 m-sm-0">
                        <img src="/images/twitter-icon.png" alt="Twitter Icon" />
                        <img className="mx-3" src="/images/facebook-icon.png" alt="Facebook Icon" />
                        <img src="/images/instagram-icon.png" alt="Instagram Icon" />
                    </div>
                    <div className="copyright-block">
                        <span className="copyright-text">© 2022 FarmWarehouse • All Rights Reserved</span>
                    </div>
                </div>
            </div>
        </footer>
    </Fragment>
  )
}

export default Footer