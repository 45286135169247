import React, { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

let isReadOnly = true;
let isMobile = false;

function getOTP() {
    const characters = "1234567abcdefg";
    let otp = "";
    for (let i = 0; i < 8; i++) {
        otp += characters[Math.floor( Math.random() * characters.length )];
    }
    return otp;
};

const RegisterFarmer = () => {
 
    const navigate = useNavigate();

    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        credential: "",
        address: "",
        latitude: "",
        longitude: "",
        ageGroup: "",
        gender: ""
    })
    const [password, setPassword] = useState("")

    const { firstName, lastName, credential, address, latitude, longitude, ageGroup, gender } = user;
    let [farmerType, setFarmerType] = useState([])

    const [avatar, setAvatar] = useState('')
    const [avatarPreview, setAvatarPreview] = useState('/images/default-avatar.png')

    function arrayRemove(arr, value) { 
        return arr.filter(function(element){ 
            return element != value; 
        });
    }
    const onChecked = e => {
        if (e.target.checked) {
            farmerType.push(e.target.value)
        }else{
            farmerType = arrayRemove(farmerType, e.target.value)
        }
        setFarmerType(farmerType)
    }

    const submitHandler = (e) => {
        e.preventDefault();

        if (credential.length === 0) return toast.error("Please enter a valid credential")
        else if (credential.charAt(0).match(/[a-z]/i) && !credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/)) return toast.error("Please enter a valid email address")
        else if (!isNaN(parseInt(credential.charAt(0))) || (credential.charAt(0) === "+" && (credential.match(/[a-z]/i) || credential.length !== 14))) return toast.error("Please enter a valid phone number")

        if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
            return toast.error("Password does not match the required format.")
        }

        navigate('/register/farmer/farm-details', {state: {
            user: user,
            farmerType: farmerType,
            avatar: avatar,
            password: password
            }
        })
        isMobile = false
    }

    const credOnchange = e => {

        if (e.target.value === ""){
            setPassword("")
            isReadOnly = true;
            isMobile = false;
        }else if (e.target.value.match(/[a-z]/i)) {
            isMobile = false
            isReadOnly= false
        }else if (e.target.value.includes("+") && e.target.value.length >= 8) {
            setPassword(getOTP())
            isReadOnly= true
            isMobile = true;
        }
        setUser({...user, [e.target.name]: e.target.value })
    }

    const onChange = e => {
        if(e.target.name === 'avatar'){
            
            const file = e.target.files[0]
            const reader = new FileReader();

            reader.onload = (e) => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                    setAvatar(reader.result)
                }
            }
            reader.readAsDataURL(file)
        } else {
            setUser({...user, [e.target.name]: e.target.value })
        }
    }

  return (
    <Fragment>
        <Metadata title={'Register as a Farmer'} />
        <ToastContainer />
            <div className="container-md mid-container shadow-lg p-0 my-5">
                <div className="row">
                    <div className="col-md-8 p-5 order-2 order-md-1">
                        <form className="px-2" onSubmit={submitHandler} encType="multipart/form-data">
                            <Link to={"/register"} className="green-color text-size-xs">{"< Back to Sign Up"}</Link>
                            <h3 className="text-size-lg mb-5 mt-4">Sign up as a Farmer</h3>

                            <div className="row">

                                {/* FIRST NAME */}
                                <div className="col-md-6 mb-4">
                                <label htmlFor="first-name" className="form-label">First Name</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="first-name"
                                        name='firstName' 
                                        value={firstName}
                                        onChange={onChange}
                                        aria-describedby="firstNameHelp" 
                                        placeholder="E.g. John"
                                        required
                                    />
                                </div>

                                {/* LAST NAME */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="last-name" className="form-label">Last Name</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="last-name" 
                                        name='lastName' 
                                        value={lastName}
                                        onChange={onChange}
                                        aria-describedby="lastNameHelp" 
                                        placeholder="E.g. Smith"
                                        required
                                    />
                                </div>

                                {/* CREDENTIAL */}
                                <div className="col-12 mb-4">
                                    <label htmlFor="credential" className="form-label">Email address or Phone number</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="credential" 
                                        name='credential' 
                                        value={credential}
                                        onChange={credOnchange}
                                        aria-describedby="credentialHelp" 
                                        placeholder="E.g. john@email.com or +2348012345678"
                                        required
                                    />
                                    <div id="credentialHelp" className="text-size-xxs mt-2 red-color">
                                        {   credential.length === 0 ? "Please enter a valid credential"
                                            : credential.charAt(0).match(/[a-z]/i) ? (credential.match(/(^\+(?:[0-9]●?){6,14}[0-9]$)|(^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$)/) ? <span className="green-color">A verification code will be sent to this email</span> : "Please enter a valid email address")
                                            :!isNaN(parseInt(credential.charAt(0))) ? "Phone number must have country code. E.g. +234"
                                            : credential.charAt(0) === "+" && credential.length < 14 ? "Keep typing..." 
                                            : credential.match(/[a-z]/i) || credential.length !== 14? "Enter a valid phone number"
                                            : <strong className="green-color">Your login password will be sent to this phone number</strong>
                                        } 
                                    </div>  
                                </div>

                                {/* ADDRESS */}
                                <div className="col-12 mb-4">
                                    <label htmlFor="address" className="form-label">Address</label> <span className="red-color">*</span>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="address" 
                                        name='address' 
                                        value={address}
                                        onChange={onChange}
                                        aria-describedby="addressHelp" 
                                        placeholder="E.g. 18. Village Road, Gishiri, Abuja"
                                        required
                                    />
                                </div>

                                {/* LATITUDE */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="latitude" className="form-label">Latitude</label> <i className="text-size-xs">(optional)</i>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="latitude" 
                                        name='latitude' 
                                        value={latitude}
                                        onChange={onChange}
                                        aria-describedby="latitudeHelp" 
                                        placeholder="E.g. 4.1234"
                                    />
                                </div>

                                {/* LONGITUDE */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="longitude" className="form-label">Longitude</label> <i className="text-size-xs">(optional)</i>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        id="longitude" 
                                        name='longitude' 
                                        value={longitude}
                                        onChange={onChange}
                                        aria-describedby="longitudeHelp" 
                                        placeholder="E.g. 6.1234"
                                    />
                                </div>

                                {/* AGE GROUP */}
                                <div className="col-md-6 mb-4">
                                        <label htmlFor="ageGroup" className="form-label">Age group</label> <span className="red-color">*</span>
                                        <select 
                                            name="ageGroup" 
                                            id="ageGroup" 
                                            className="form-control"
                                            value={ageGroup} 
                                            onChange={onChange}
                                            required
                                            >
                                            <option value="">Select age group</option>
                                            <option value="10 - 18">10 - 18</option>
                                            <option value="19 - 25">19 - 25</option>
                                            <option value="26 - 35">26 - 35</option>
                                            <option value="36 - 50">36 - 50</option>
                                            <option value="50+">50+</option>
                                        </select>
                                </div>

                                {/* GENDER */}
                                <div className="col-md-6 mb-4">
                                    <label htmlFor="gender" className="form-label">Gender</label> <span className="red-color">*</span>
                                    <select 
                                        name="gender" 
                                        id="gender" 
                                        className="form-control"
                                        value={gender} 
                                        onChange={onChange}
                                        required
                                        >
                                        <option value="">Select gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                {/* PASSWORD */}
                                <div className="col-12 mb-4">
                                    <label htmlFor="Password" className="form-label">Password</label> <span className="red-color">*</span>
                                    <input 
                                        type="password" 
                                        className="form-control"
                                        id="Password" 
                                        name='password' 
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder={"**********"}
                                        readOnly={isReadOnly}
                                        required
                                    />
                                    {isMobile || password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
                                    ? <div id="passHelp" className="text-size-xxs mt-2 green-color">Looks good!</div>
                                    : <div id="passHelp" className="text-size-xxs mt-2 red-color">Password must have a minimum of 8 characters with at least 1 letter and 1 number.</div>}
                                </div>
                                {/* FARMER TYPE */}
                                <h5 className="form-label mb-3">What do you farm? (You can select multiple)<span className="red-color">*</span></h5>
                                <div className="col-12 mb-3">

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="Rice" name="farmerType" value="Rice" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Rice">Rice</label>
                                    </div>
  
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="Maize" name="farmerType" value="Maize" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Maize">Maize</label>
                                    </div>
  
                                    <div className="form-check form-check-inline mb-3">
                                        <input className="form-check-input" type="checkbox" id="Guinea Corn" name="farmerType" value="Guinea Corn" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Guinea Corn">Guinea Corn</label>
                                    </div>
  
                                    <div className="form-check form-check-inline mb-3">
                                        <input className="form-check-input" type="checkbox" id="Soya Beans" name="farmerType" value="Soya Beans" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Soya Beans">Soya Beans</label>
                                    </div>
  
                                    <div className="form-check form-check-inline mb-3">
                                        <input className="form-check-input" type="checkbox" id="Yam" name="farmerType" value="Yam" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Yam">Yam</label>
                                    </div>
  
                                    <div className="form-check form-check-inline mb-3">
                                        <input className="form-check-input" type="checkbox" id="Cassava" name="farmerType" value="Cassava" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Cassava">Cassava</label>
                                    </div>
  
                                    <div className="form-check form-check-inline mb-3">
                                        <input className="form-check-input" type="checkbox" id="Palm Oil" name="farmerType" value="Palm Oil" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Palm Oil">Palm Oil</label>
                                    </div>
  
                                    <div className="form-check form-check-inline mb-3">
                                        <input className="form-check-input" type="checkbox" id="Fish" name="farmerType" value="Fish" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Fish">Fish</label>
                                    </div>
  
                                    <div className="form-check form-check-inline mb-3">
                                        <input className="form-check-input" type="checkbox" id="Livestock" name="farmerType" value="Livestock" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Livestock">Livestock</label>
                                    </div>
  
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="Others" name="farmerType" value="Others" onChange={onChecked}/>
                                        <label className="form-check-label text-size-xs" htmlFor="Others">Others</label>
                                    </div>
  
                                </div>

                                {/* AVATAR */}
                                <div className="mb-4">
                                    <label htmlFor="avatar-upload" className="form-label">Avatar</label> <i className="text-size-xs">(optional)</i>
                                    <div className="d-flex align-items-center">
                                        <figure className="m-0 p-0">
                                            <img 
                                                src={avatarPreview} 
                                                alt="Avatar Preview"
                                                className="preview" 
                                            />
                                        </figure>
                                        <div className="border rounded mx-2 p-2 w-100">
                                            <input 
                                                type="file"
                                                name="avatar"
                                                className="custom-file-input text-size-xs"
                                                id="customFile" 
                                                accept="image/png, image/jpeg"
                                                onChange={onChange}
                                            />
                                            <label htmlFor="customFile" className="text-size-xxs text-muted">Choose Avatar</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" id="register-button" className="green-btn my-4 w-100">Next</button>
                            <div className="form-label text-center mt-1">Already have a farmwarehouse account? <Link to="/signin" className="text-end green-link">Sign In</Link></div>
                        </form>
                    </div>
                    <div className="col-md-4 order-1 order-md-2 text-end" style={{backgroundImage: "url('/images/sign-in-image.png')", backgroundSize: "cover"}}>
                        <img src="/images/fm-logo-white.png" className="white-logo my-4 me-4" alt="Farmwarehouse Logo"/>
                    </div>
                </div>
            </div>    
    </Fragment>
  )
}

export default RegisterFarmer