import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Search from './search'
import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getNotifications, clearErrors } from '../../actions/notificationActions'
import { signout } from '../../actions/userActions'

const Header = () => {

    const dispatch = useDispatch();

    const { isAuthenticated, user } = useSelector(state => state.auth);
    const { cartItems } = useSelector(state => state.cart)
    const { error, notifications } = useSelector(state => state.getNotifications)

    let newNotifications = 0;

    if (notifications.length > 0) {
        newNotifications = (notifications[0].messages.filter(message => message.new === true)).length
    }

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getNotifications());
        }

        if (error) {
            console.log(error)
            toast.error("An error has occurred, check your internet connection and refresh the page.")
            dispatch(clearErrors())
        }
    }, [dispatch, isAuthenticated, error])

    const signoutHandler = () => {
        dispatch(signout());
        toast.success('Signed out successfully 👍.')
    }
  return (
    <Fragment>
        <ToastContainer />
            <nav className="container-fluid navbar navbar-expand-lg">
            <div className="container-md d-flex align-items-center">
                <Link to="/" className="navbar-brand"><img src="/images/fm-logo.png" alt="" width="130" height="30"/></Link>

                {user && user.role === "Admin+" ? (
                    <span className="nav-label bg-info ms-auto me-3 me-lg-0">Admin+</span>
                ):user && user.role === "Admin" ? (
                    <span className="nav-label bg-yellow ms-auto me-3 me-lg-0">Admin</span>
                ):(
                    user && user.role === "Farmer" ? <span className="nav-label bg-green ms-auto me-3 me-lg-0">Farmer</span> : 
                    user && user.role === "Vendor" ? <span className="nav-label bg-green ms-auto me-3 me-lg-0">Vendor</span> : ""
                )}

                <button className="navbar-toggler p-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon d-block mt-1 bg-light"></span>
                    <span className="navbar-toggler-icon d-block my-1 bg-light"></span>
                    <span className="navbar-toggler-icon d-block mb-1 bg-light"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <Search/>
                    {/* {user && user.role === "Buyer" ? (
                        <Search/>
                    ):(
                        <Fragment></Fragment>
                    )} */}
                    
                    <ul className={`navbar-nav d-md-flex align-items-center my-4 my-lg-0 ${user && user.role === "Buyer"? "":"ms-auto"}`}>

                    {/* SMALL SCREEN DROPDOWN */}
                    {user ? (
                        <Fragment>
                            {/* SMALL SCREEN DROPDOWN */}
                            <ul className="inner-list text-center p-0 d-lg-none">
                                <i className="text-muted text-size-xxs d-block m-0 p-0">{user.firstName} {user.lastName}</i>
                                <hr className="m-2" />
                                {/* CHECK ADMIN */}
                                {user && (user.role === 'Admin+' || user.role === 'Admin') ? (
                                    <li>
                                        <Link to="/admin/dashboard" className="dropdown-item green mt-1">Admin Dashboard</Link> 
                                    </li>
                                ):(<Fragment></Fragment>)}

                                {/* CHECK SELLER */}
                                {user && (user.farmerStatus !== 'None' || user.vendorStatus !== 'None') ? (
                                    <Fragment>
                                        <li>
                                            {user && (user.farmerStatus === 'Pending' || user.vendorStatus === 'Pending') ? (<span className="dropdown-item green mt-1 disabled">Seller's Dashboard</span>) 
                                            : (<Link to="/seller/dashboard" className="dropdown-item green mt-1" href="#">Seller's Dashboard</Link>)
                                            }
                                            
                                        </li>
                                    </Fragment>

                                ):(<Fragment></Fragment>)}


                                <li><Link to={`/profile`} state={{ from: "undefined" }} className="dropdown-item">My Account</Link></li>
                                <li><Link to="/orders" className="dropdown-item">My Orders</Link></li>
                                <li><a className="dropdown-item" href="#">Favorites</a></li>
                                <li><a className="dropdown-item" href="#">Help</a></li>

                                {/* CART */}
                                <li className="nav-item position-relative">
                                    <Link to="/cart" className="dropdown-item d-flex justify-content-center align-items-center" aria-current="page">
                                        <span>Cart</span>
                                        <span className="cart-number ms-2">{cartItems.length}</span>
                                    </Link>
                                </li>

                                {/* NOTIFICATION */}
                                <div className="dropdown-item">
                                    <span className="d-block d-lg-none ms-2">Notifications</span>
                                    {newNotifications > 0 ? <span className="notification-number ms-2">{newNotifications}</span> : <Fragment></Fragment>}
                                </div>

                                <li><hr className="dropdown-divider"/></li>
                                <li><Link to="/" className="dropdown-item mb-1" onClick={signoutHandler}>Sign Out</Link></li>
                            </ul>
                        </Fragment>):(<Fragment></Fragment>)}
                    
                    {/* BIG SCREEN DROPDOWN */}
                    {user ? (
                        <Fragment>
                            {/* BELL */}
                            <li className="nav-item position-relative d-none d-lg-block">
                                <Link to="/notifications" className="nav-link active d-flex align-items-center">
                                    <img src="/images/bell-icon.png" alt="" width="25" height="25"/>
                                    {newNotifications > 0 ? 
                                        <div className="notification-number position-absolute d-none d-lg-flex"> {newNotifications} </div> 
                                        : <Fragment></Fragment>}
                                </Link >
                            </li>

                            {/* PROFILE */}
                            <li className="nav-item dropdown my-2 my-lg-0 mx-lg-4 d-none d-lg-block">
                            {/* <a href="#"  className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Profile</a> */}
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img 
                                        src={user.avatar && user.avatar.url}
                                        alt="User Avatar"
                                        className="avatar-top" 
                                    />
                                </a>
                                <ul className="dropdown-menu">
                                    <i className="text-muted text-size-xxs d-block text-center m-0 p-0">{user.firstName} {user.lastName}</i>
                                    <hr className="m-2" />
                                    {/* CHECK ADMIN */}
                                    {user && (user.role === 'Admin+' || user.role === 'Admin') ? (
                                        <li>
                                            <Link to="/admin/dashboard" className="dropdown-item green mt-1">Admin Dashboard</Link> 
                                        </li>
                                    ):(<Fragment></Fragment>)}

                                    {/* CHECK SELLER */}
                                    {user && (user.farmerStatus !== 'None' || user.vendorStatus !== 'None') ? (
                                        <Fragment>
                                            <li>
                                                {user && (user.farmerStatus === 'Pending' || user.vendorStatus === 'Pending') ? (<span className="dropdown-item green mt-1 disabled">Seller's Dashboard</span>) 
                                                : (<Link to="/seller/dashboard" className="dropdown-item green mt-1" href="#">Seller's Dashboard</Link>)
                                                }
                                                
                                            </li>
                                        </Fragment>

                                    ):(<Fragment></Fragment>)}
                                    
                                    <li><Link to={`/profile`} state={{ from: "undefined" }} className="dropdown-item">My Account</Link></li>
                                    <li><Link to="/orders" className="dropdown-item">My Orders</Link></li>
                                    <li><Link to="/requests" className="dropdown-item">My Requests</Link></li>
                                    <li><a className="dropdown-item" href="#">Favorites</a></li>
                                    <li><a className="dropdown-item" href="#">Help</a></li>
                                    <li><hr className="dropdown-divider"/></li>
                                    <li><Link to="/" className="dropdown-item mb-1" onClick={signoutHandler}>Sign Out</Link></li>
                                </ul>
                            </li>
                        </Fragment>
                            
                        ) : (<Fragment>
                                <Link to="/signin" className="signin p-2 me-lg-4 border">Sign In</Link>
                                <li className="nav-item position-relative d-lg-none">
                                    <Link to="/cart" className="dropdown-item d-flex justify-content-center align-items-center" aria-current="page">
                                        <span>Cart</span>
                                        <span className="cart-number ms-2">{cartItems.length}</span>
                                    </Link>
                                </li>
                            </Fragment>
                            )}

                        {/* CART */}
                        <li className="nav-item position-relative d-none d-lg-block">
                            <Link to="/cart" className="nav-link active d-flex align-items-center" aria-current="page">
                                <img src="/images/cart-icon.png" alt="" width="25" height="25"/>
                            </Link>
                            <div className="cart-number position-absolute d-none d-lg-flex">{cartItems.length}</div>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </nav>
    </Fragment>
  )
}

export default Header