import React, { Fragment, useEffect, useState } from 'react'
import Clock from 'react-live-clock';

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import SellerNavigation from '../layout/sellerNavigation'
import { costFormatter, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'

import { getSellerProducts } from '../../actions/productActions'
import { getSellerOrders } from '../../actions/orderActions'

import axios from 'axios';

async function getWeatherInfo(lat, lng) {
    const response = await axios.get('https://api.openweathermap.org/data/2.5/weather', {
      params: {
        lat: lat,
        lon: lng,
        units: "metric",
        appid: "3ef854f7991038bcbd78140cd55f91d9"
      }
    });

    return response;
  }


const SellerDashboard = () => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

    const dispatch = useDispatch();

    const loggedInUser = useSelector(state => state.auth).user;
    const { products } = useSelector(state => state.products)
    const { orders, loading } = useSelector(state => state.getOrders)

    const [weather, setWeather] = useState(null);

    const paidOrders = orders.filter(order => order.paidAt)

    let totalSales = 0;

    for (let order of paidOrders){
        const productsPrice = order.orderItems.map(product => product.price * product.quantity).reduce((a, c) => a + c);
        totalSales += productsPrice;
    }

    useEffect(() => {
        dispatch(getSellerProducts())
        dispatch(getSellerOrders())

        async function fetchWeatherInfo() {
            const weather = await getWeatherInfo(loggedInUser.farm.farmLat, loggedInUser.farm.farmLng);
            setWeather(weather);
        }
        
        fetchWeatherInfo();

    }, [dispatch, loggedInUser])

    return (
        <Fragment>
            <Metadata title={'Seller Dashboard'}/>
            <SellerNavigation Dashboard/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-md text-muted">DASHBOARD</h3>
                    <div className="d-flex justify-content-end">
                        <span className="bold text-size-lg green-color"></span>
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>

                            {/* <div className="weather-box d-flex justify-content-between align-items-center p-5 mb-4" style={{backgroundImage: "url('/images/weather.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom'}}>
                                <div>
                                    <h3 className="bold mb-0">{loggedInUser.farm.farmName}</h3>
                                    <i className="text-size-xxs">Latitude: <span className="bold">{loggedInUser.farm.farmLat},</span> Longitude: <span className="bold">{loggedInUser.farm.farmLng}</span></i>
                                    
                                    <h3 className="text-size-lg bold mt-3">{(weather.data.weather[0].description).charAt(0).toUpperCase() + (weather.data.weather[0].description).substr(1).toLowerCase()}</h3>
                                </div>

                                <div className="weather-divider"></div>

                                <div>
                                    <p className="text-size-sm mb-1">temperature: <span className="bold green-color">{weather.data.main.temp}&#8451;</span></p>
                                    <p className="text-size-sm mb-1">humidity: <span className="bold green-color">{weather.data.main.humidity}</span></p>
                                    <p className="text-size-sm mb-1">pressure: <span className="bold green-color">{weather.data.main.pressure}</span></p>
                                </div>

                                <div className="weather-divider"></div>

                                <div>
                                    <p className="text-size-sm mb-1">wind speed: <span className="bold green-color">{weather.data.wind.speed}</span></p>
                                    <p className="text-size-sm mb-1">wind degree: <span className="bold green-color">{weather.data.wind.deg}</span></p>
                                    <p className="text-size-sm mb-1">gust: <span className="bold green-color">{weather.data.wind.gust}</span></p>
                                </div>
                                
                            </div> */}

                        {/* WEATHER */}
                        {loggedInUser && weather ? 
                            <div className="weather-box d-flex justify-content-between align-items-center p-5 mb-3" style={{backgroundImage: "url('/images/weather-dark.jpg')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom'}}>
                                <div>
                                    <h3 className="bold white-color mb-0">{loggedInUser.farm.farmName}</h3>
                                    <i className="d-block white-color text-size-xxs">{loggedInUser.farm.farmLocation}</i>
                                    <i className="white-color text-size-xxs">Latitude: <span className="bold">{loggedInUser.farm.farmLat},</span> Longitude: <span className="bold">{loggedInUser.farm.farmLng}</span></i>
                                    
                                    <h3 className="white-color text-size-lg bold mt-3 mb-0">{(weather.data.weather[0].description).charAt(0).toUpperCase() + (weather.data.weather[0].description).substr(1).toLowerCase()}</h3>
                                    <i className="white-color text-size-xxs"><span>{date}</span> | <span className="bold"><Clock format={'HH:mm:ss'} ticking={true} timezone={'Africa/Lagos'} /></span></i>
                                </div>

                                <div className="weather-divider"></div>

                                <div>
                                    <p className="white-color text-size-sm mb-1">temperature: <span className="bold white-color">{weather.data.main.temp}&#8451;</span></p>
                                    <p className="white-color text-size-sm mb-1">humidity: <span className="bold white-color">{weather.data.main.humidity}%</span></p>
                                    <p className="white-color text-size-sm mb-1">pressure: <span className="bold white-color">{weather.data.main.pressure}hPa</span></p>
                                </div>

                                <div className="weather-divider"></div>

                                <div>
                                    <p className="white-color text-size-sm mb-1">wind speed: <span className="bold white-color">{weather.data.wind.speed}m/s</span></p>
                                    <p className="white-color text-size-sm mb-1">wind direction: <span className="bold white-color">{weather.data.wind.deg}deg</span></p>
                                    <p className="white-color text-size-sm mb-1">wind gust: <span className="bold white-color">{weather.data.wind.gust}</span></p>
                                </div>
                            </div>
                        : <Fragment></Fragment> }
                        
                            <div className="row m-0 p-0 justify-content-between">
                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">&#x20A6;{numberWithCommas(costFormatter(orders ? totalSales : 0))}</h3>
                                        <span className="dash-text-bottom">Total sales</span>
                                    </div>
                                    <div className="vertical-divider mx-3 mx-sm-5"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">&#x20A6;0</h3>
                                        <span className="dash-text-bottom">Total withdrawal</span>
                                    </div>
                                </div>

                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(products ? (products.filter(product => product.stock > 0)).length : 0))}</h3>
                                        <span className="dash-text-bottom">Products in stock</span>
                                    </div>
                                    <div className="vertical-divider mx-3 mx-sm-5"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(products ? (products.filter(product => product.stock === 0)).length : 0))}</h3>
                                        <span className="dash-text-bottom">Products out of stock</span>
                                    </div>
                                </div>

                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(orders ? (orders.filter(order => order.orderStatus === "Processing")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Orders in Process</span>
                                    </div>

                                    <div className="vertical-divider mx-2 mx-sm-3"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(orders ? (orders.filter(order => order.orderStatus === "Delivered")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Orders Delivered</span>
                                    </div>

                                    <div className="vertical-divider mx-2 mx-sm-3"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">{numberWithCommas(costFormatter(orders ? (orders.filter(order => order.orderStatus === "Canceled")).length : 0))}</h3>
                                        <span className="dash-text-bottom">Orders Canceled</span>
                                    </div>
                                </div>

                                <div className="col-lg-6 d-flex justify-content-center dash-info-box">
                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">-</h3>
                                        <span className="dash-text-bottom mt-2">Requests pending</span>
                                    </div>
                                    <div className="vertical-divider mx-3 mx-sm-5"></div>

                                    <div className="d-flex flex-column align-items-center dash-item-box">
                                        <h3 className="dash-text-top">-</h3>
                                        <span className="dash-text-bottom mt-2">Requests completed</span>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default SellerDashboard