import React, { Fragment } from 'react'
import { verifyUser } from "../services/auth.service";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'

const Welcome = () => {
    const params = useParams();
    verifyUser(params.confirmationCode)
    // if (props.match.path === "/verify/:confirmationCode") {
    //   verifyUser(params.confirmationCode);
    // }

    return (
        <Fragment>
            <div className="container-md white-bg shadow-lg my-5 pt-5">
                <header className="jumbotron">
                    <h3>
                    <strong>Account verified!</strong>
                    </h3>
                </header>
                <span>Please </span><Link to={"/signin"} className="green-color">Sign in</Link>
            </div>
        </Fragment>
    )}

export default Welcome