import React, { Fragment, useState } from 'react'
import Metadata from './metadata'

const DeleteModal = ({item, onSelect}) => {

    const handleClickedButton = () => {
        onSelect("Delete")
    }

    return (
        <Fragment>
            {/* Modal */}
            <div className="modal fade" id="delete-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-size-sm" id="delete-modal-label">Delete {item}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <p className="text-size-sm">Are you sure you want to delete this {item}?</p>
                        <p className="text-size-xs bold-1 red-color">This action is irreversible</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" onClick={handleClickedButton} data-bs-dismiss="modal" className="btn btn-danger">Delete</button>
                    </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
    }

export default DeleteModal
