import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { registerFarmer, clearErrors } from '../../actions/userActions'
import { REGISTER_USER_RESET } from '../../constants/userConstants'

import axios from 'axios';

async function getCoordinates(address) {
    const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address: address,
        key: "AIzaSyD0euAKJS_ktUhBd52TGHPY5NcPQ1ewAfQ"
      }
    });
    if (response.data.results.length === 0) return null
    return response.data.results[0].geometry.location;
};


let	fingerpos = ["LEFT_THUMB", "LEFT_INDEX", "RIGHT_THUMB", "RIGHT_INDEX" ];
let	service_handle = "";
let	ENROLL_OBJ; 
let TotalEnrollments = 0;

const RegisterFarmerFarm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { firstName, lastName, credential, address, latitude, longitude, ageGroup, gender } = location.state.user;
    const avatar = location.state.avatar
    const farmerType = location.state.farmerType
    const password = location.state.password

    const [farm, setFarm] = useState({
        farmName: '',
        farmLocation: '',
        farmSize: '',
    })

    const { farmName, farmLocation, farmSize } = farm;

    const { isAuthenticated, isSuccess, error, loading } = useSelector(state => state.auth);

    useEffect(() => {

        if (isAuthenticated) {
            navigate('/')
        }

        if (isSuccess) {
            navigate('/signin')
            toast.success("Account successfully created, Please verify your credential before you can sign in")
            dispatch({ type: REGISTER_USER_RESET })
        }

        if (error) {
            if (error.includes("E11000")){
                toast.error("This user already exists. Please use a different email address or go to sign in.")
            } else toast.error(error)
            dispatch(clearErrors());
        }

    }, [dispatch, isSuccess, isAuthenticated, error])

    const submitHandler = async (e) => {
        e.preventDefault();

        const coordinates = await getCoordinates(farmLocation);

        // if (!ENROLL_OBJ) return toast.error("Please register your fingerprints.")
        // else if ( ENROLL_OBJ.EnrollData.Templates.length !== 4) return toast.error("Please register all 4 fingerprints.");

        if (!coordinates) return toast.error("Cannot find coordinates for your location, please modify it.")

        const formData = new FormData();

        formData.set('firstName', firstName);
        formData.set('lastName', lastName);
        formData.set('credential', credential);
        formData.set('address', address);
        formData.set('latitude', latitude);
        formData.set('longitude', longitude);
        formData.set('ageGroup', ageGroup)
        formData.set('gender', gender);
        formData.set('password', password);
        formData.set('avatar', avatar);
        formData.set('farmerType', farmerType)
        formData.set('farmName', farmName);
        formData.set('farmLocation', farmLocation);
        formData.set('farmSize', farmSize);
        formData.set('farmLat', coordinates.lat);
        formData.set('farmLng', coordinates.lng);
        if (ENROLL_OBJ) formData.append('fingerPrints', JSON.stringify(ENROLL_OBJ.EnrollData.Templates));

        dispatch(registerFarmer(formData));
    }

    const onChange = e => {
        setFarm({...farm, [e.target.name]: e.target.value })
    }

    // FINGERPRINTS

    function Restart() {
	    TotalEnrollments = 0;
		window.location.reload(true);
	}

    function captureFP( arrIndex, event ) {
		let	imgId = fingerpos[arrIndex];
		if ( ENROLL_OBJ && ENROLL_OBJ.length > arrIndex  && ENROLL_OBJ.EnrollData[arrIndex].na >= 3 ) {
			alert("Maximum Attempts Over!");
			return;
		}
        document.getElementById(imgId).src = "/images/wait.gif";
		// setWaitGIF("/images/wait.gif");
		CallSGIFPGetData( arrIndex, SuccessFunc, ErrorFunc);
	}

	function CallSGIFPGetData( arrIndex, successCall, failCall ) {
		let	uri = "https://localhost:8443/SGIFPEnroll";
		let	params 	= "timeout=" + "5000";
            params  += "&quality=" + "50";
            params += "&templateFormat=" + "ISO"; // DEFAULT IS ISO
            params  += "&srvhandle=" + service_handle;
            params  += "&FingerPos=" + fingerpos[arrIndex];

			
		let	xmlhttp = new XMLHttpRequest();
		xmlhttp.open("POST", uri, true );
		xmlhttp.onreadystatechange=function() {
			if (xmlhttp.readyState==4 && xmlhttp.status==200) {
			    TotalEnrollments++;
				let fpobject = JSON.parse(xmlhttp.responseText);
				ENROLL_OBJ = fpobject;
				successCall( arrIndex, fpobject);
			} else if ( xmlhttp.status == 404 ) {
				failCall(arrIndex, xmlhttp.status)
			};
		};
		
        xmlhttp.onerror=function() {
			failCall(arrIndex, xmlhttp.status);
		};
		
        xmlhttp.send(params);
	};

    function SuccessFunc( arrIndex, fpdata ) {

		let	imgId = fingerpos[arrIndex];

		if ( fpdata.ErrorCode === 0 ) {
			if ( service_handle != "" && fpdata.SerHandle != service_handle ) {
				toast.error("Session Timeout you will need to restart");
				return Restart();
            };

			service_handle = fpdata.SerHandle;

			document.getElementById(imgId).src = "data:image/bmp;base64," + fpdata.BMPBase64;

            let btnid = "BTN_" + imgId;
            document.getElementById(btnid).disabled = true;
            document.getElementById(btnid).innerText = document.getElementById(btnid).innerText + " 👍"
			
		} else if (fpdata.ErrorCode === 54) {
		    TotalEnrollments --;
            toast.error("Scanning has timed out, please rescan.");
            document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
		   
		} else if (fpdata.ErrorCode === 3) {
            document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
            return toast.error("An error occured. \nPlease check that a compatible SecuGen fingerprint scanner is connected, the drivers are installed and the Web API Service is running.")
        } else {
            document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
            toast.error("Fingerprint Capture Error Code:  " + fpdata.ErrorCode);
        };
	};

    function ErrorFunc ( arrIndex, status ) {
		
		/* 	
			If you reach here, user is probabaly not running the 
			service. Redirect the user to a page where he can download the
			executable and install it. 
		*/
        let	imgId = fingerpos[arrIndex];
        document.getElementById(imgId).src = `/images/hand_${arrIndex}.png`;
        return toast.error("An error occured. \nPlease check that a compatible SecuGen fingerprint scanner is connected, the drivers are installed and the Web API Service is running.");
	};

  return (
    <Fragment>
        <Metadata title={'Register as a Farmer - Enter Farm Details'} />
        <ToastContainer />
            <div className="container-md mid-container shadow-lg p-0">
                <div className="row">
                    <div className="col-md-8 p-5 order-2 order-md-1">
                        <form className="px-2" onSubmit={submitHandler} encType="multipart/form-data">
                        <Link to={"/register/farmer"} className="d-block green-color text-size-xs mb-4">{"< Back to Personal Details"}</Link>
                        <h3 className="text-size-md mb-5 mt-0">Enter Farm Details</h3>

                            {/* FARM NAME */}
                            <div className="mb-4">
                                <label htmlFor="farmName" className="form-label">Farm Name</label> <span className="red-color">*</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="farmName" 
                                    name='farmName' 
                                    value={farmName}
                                    onChange={onChange}
                                    aria-describedby="farmNameHelp" 
                                    placeholder="E.g. Nate's Farmland"
                                    required
                                />
                            </div>

                            {/* FARM LOCATION */}
                            <div className="mb-2">
                                <label htmlFor="farmLocation" className="form-label">Farm Location</label> <span className="red-color">*</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="farmLocation" 
                                    name='farmLocation' 
                                    value={farmLocation}
                                    onChange={onChange}
                                    // onBlur={onLocation}
                                    aria-describedby="farmLocationHelp" 
                                    placeholder="E.g. Ikoyi, Lagos State"
                                    required
                                />
                            </div>

                            {/* FARM SIZE */}
                            <div className="mt-4 mb-2">
                                <label htmlFor="farmSize" className="form-label">Farm Size (Hectares)</label> <span className="red-color">*</span>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    id="farmSize" 
                                    name='farmSize' 
                                    value={farmSize}
                                    onChange={onChange}
                                    aria-describedby="farmSizeHelp" 
                                    placeholder="E.g. 59"
                                    required
                                />
                            </div>

                            <fieldset className="mt-4">
                                <legend className="form-label">Register Fingerprints</legend>
                                <div className="row">
                                    {/* LEFT THUMB */}
                                    <div className="col-6 text-center">
                                        <div className="print-set">
                                            <img id="LEFT_THUMB" alt="LEFT_THUMB" className="hand" src="/images/hand_0.png" /> <br/>
                                            <button className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_LEFT_THUMB" onClick={(event) => captureFP(0, event)}>Left Thumb</button>
                                        </div>
                                    </div>

                                    {/* LEFT INDEX */}
                                    <div className="col-6 text-center">
                                        <div className="print-set">
                                            <img id="LEFT_INDEX" alt="LEFT_INDEX" className="hand" src="/images/hand_1.png" /> <br/>
                                            <button className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_LEFT_INDEX" onClick={(event) => captureFP(1, event)}>Left Index</button> 
                                        </div>
                                    </div>

                                    {/* RIGHT THUMB */}
                                    <div className="col-6 text-center">
                                        <div className="print-set">
                                            <img id="RIGHT_THUMB" alt="RIGHT_THUMB" className="hand" src="/images/hand_2.png" /> <br/>
                                            <button className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_RIGHT_THUMB" onClick={(event) => captureFP(2, event)}>Right Thumb</button>
                                        </div>
                                    </div>

                                    {/* RIGHT INDEX */}
                                    <div className="col-6 text-center">
                                        <div className="print-set">
                                            <img id="RIGHT_INDEX" alt="RIGHT_INDEX" className="hand" src="/images/hand_3.png" /> <br/>
                                            <button className="green-btn py-1 my-4 text-size-xxs" type="button" value="Scan" id="BTN_RIGHT_INDEX" onClick={(event) => captureFP(3, event)}>Right Index</button>
                                        </div>
                                    </div>

                                    <div className="col-12 text-center mt-4"> 
                                        <button className="border-btn py-1" type="button" value="Restart" id="BTN_RESTART" onClick={Restart}>Restart</button>
                                    </div>
                                </div>
                            </fieldset>

                            <button 
                                type="submit" 
                                id="register-button"
                                className="text-size-xs green-btn py- my-4 w-100"
                                disabled={loading ? true : false}
                            >{loading ? "Creating your account, please wait..." : "Create Account"}
                            </button>
                            <div className="form-label text-center">By creating an account, you agree to the Farm Warehouse’s</div>
                            <div className="text-center"><Link to="/terms" className="green-link">Terms and Conditions and Privacy Policy</Link></div>
                        </form>
                    </div>
                    <div className="col-md-4 order-1 order-md-2 text-end" style={{backgroundImage: "url('/images/sign-in-image.png')", backgroundSize: "cover"}}>
                        <img src="/images/fm-logo-white.png" className="white-logo my-4 me-4" alt="Farmwarehouse Logo"/>
                    </div>
                </div>
            </div>    
    </Fragment>
  )
}

export default RegisterFarmerFarm;