import { 
    NEW_STORAGE_REQUEST,
    NEW_STORAGE_SUCCESS,
    NEW_STORAGE_RESET,
    NEW_STORAGE_FAIL,
    GET_STORAGE_REQUEST,
    GET_STORAGE_SUCCESS,
    GET_STORAGE_FAIL,
    STORAGE_DETAILS_REQUEST,
    STORAGE_DETAILS_SUCCESS,
    STORAGE_DETAILS_FAIL,
    UPDATE_STORAGE_REQUEST,
    UPDATE_STORAGE_SUCCESS,
    UPDATE_STORAGE_FAIL,
    CLEAR_ERRORS
} from '../constants/storageConstants'

export const newStorageReducer = (state = { storage: {} }, action) => {
    switch(action.type) {
        case NEW_STORAGE_REQUEST:
        case UPDATE_STORAGE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case NEW_STORAGE_SUCCESS:
        case UPDATE_STORAGE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                storage: action.payload.storage

            }

        case NEW_STORAGE_FAIL:
        case UPDATE_STORAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case NEW_STORAGE_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const storageReducer = (state = { storages:[] }, action) => {
    switch(action.type) {
        case GET_STORAGE_REQUEST:
            return {
                loading: true,
                storages: []
            }

        case GET_STORAGE_SUCCESS:
            return {
                loading: false,
                storages: action.payload
            }

        case GET_STORAGE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const storageDetailsReducer = (state = {storage: {}}, action) => {
    switch (action.type) {
        case STORAGE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case STORAGE_DETAILS_SUCCESS:
            return {
                loading: false,
                storage: action.payload
            }
        case STORAGE_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}