import React, { Fragment, useEffect, useState } from 'react'
import Metadata from '../layout/metadata'

import AdminNavigation from '../layout/adminNavigation'

import { useDispatch, useSelector } from 'react-redux'
import { getAdminRequestDetails, updateRequestStatus, clearErrors } from '../../actions/requestActions'

import { useParams, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../layout/loader'
import { formatDate } from '../../Functions/functions'

const AdminRequestDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const { request, error, loading } = useSelector(state => state.requestDetails)
    const updateLoading = useSelector(state => state.newRequest).loading

    let [status, setStatus] = useState(request.status);

    useEffect(() => {
        dispatch(getAdminRequestDetails(params.id))

        if (error) {
            console.log(error)
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateRequestStatus(status, params.id));
        window.location.reload(true);
        // navigate(`/admin/requests/${id}`)
    }

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Request Details'}/>
            <AdminNavigation Requests/>
            {loading ? <Loader/> : (
                <Fragment>
                    {/* USERS SHOW PAGE */}

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/admin/requests" className="green-color">{"< Back to requests"}</Link>
                    </div>
                    <div className="container-md white-bg shadow-lg mb-5 pt-5">

                    <h3 className="text-size-md">Request <span className="text-muted">#{request._id}</span></h3>
                    
                    <hr className="mb-5"/>
                        <div className="row">
                            <div className="col-md-6">
                                <p className="text-size-sm">Created: <strong>{request.createdAt ? formatDate(request.createdAt) : ""}</strong></p>
                                {request.user ? (
                                    <p className="text-size-sm">Request By: <strong>{request.user.firstName} {request.user.lastName}</strong></p>
                                ):(<Fragment></Fragment>)}
                                <p className="text-size-sm">Status: <strong>{request.status}</strong></p>
                                <p className="text-size-sm">Requested Product: <strong>{request.product}</strong></p>
                                <p className="text-size-sm">Product Category: <strong>{request.category}</strong></p>
                                <p className="text-size-sm">Requested Quantity: <strong>{request.quantity}</strong></p>
                                { request.date ? <p className="text-size-sm">Date Needed: <strong>{formatDate(request.date)}</strong></p> : <Fragment></Fragment>}
                                { request.comment ? <p className="text-size-sm">Comments: <strong>{request.comment}</strong></p> : <Fragment></Fragment>}
                                {request.status === "Completed" ? <p className="text-size-sm"><span className="green-color">Completed: </span><strong>{request.completedAt ? formatDate(request.completedAt) : ""}</strong></p> : <Fragment></Fragment>}
                                {request.status === "Cancelled" ? <p className="text-size-sm"><span className="red-color">Cancelled: </span><strong>{request.cancelledAt ? formatDate(request.cancelledAt) : ""}</strong></p> : <Fragment></Fragment>}

                            </div>
                            <div className="col-md-6">
                                <h5 className="text-size-sm mt-2">Update request status</h5>
                                <form onSubmit={submitHandler}>
                                    <select defaultValue={request.status} className="form-control mt-2 w-75" name="status" id="status" onChange={(e) => setStatus(e.target.value)}
                                    disabled={request.status === "Completed" || request.status === "Cancelled" ? true : false}>
                                        <option value="Processing">Processing</option>
                                        <option className="my-2 py-2" value="Completed">Completed</option>
                                        <option className="red-color" value="Cancelled">Cancel request</option>
                                    </select>
                                    {updateLoading ? (
                                        <i className="d-block text-muted text-size-xxs">Updating request status...</i>
                                    ):(<Fragment></Fragment>)}
                                    
                                    <button type="submit" className="green-btn text-size-xs mt-3 py-2 px-3" disabled={request.status === "Completed" || request.status === "Cancelled" || updateLoading ? true : false}>Update Status</button>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default AdminRequestDetails