import React, { Fragment, useEffect } from 'react'
import Metadata from '../layout/metadata'

import { Link, useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, clearErrors } from '../../actions/userActions'

import Loader from '../layout/loader'

import { useMemo } from 'react'
import { GoogleMap, useLoadScript, Marker, Polygon } from "@react-google-maps/api";

let farmCoords;

const UserProfile = () => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyD0euAKJS_ktUhBd52TGHPY5NcPQ1ewAfQ"
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user, success, error, loading } = useSelector(state => state.userDetails)

    if (user.farm) farmCoords = user.farm.coords;

    useEffect(() => {
        dispatch(getUserProfile())

        if (error) {
            console.log(error)
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success ])

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Fragment>
            <Metadata title={'User Profile'}/>
            <ToastContainer />

            {loading ? <Loader/> :
            (
                <Fragment>
                    {/* USERS SHOW PAGE */}
                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <div onClick={goBack} className="breadcrumb green-color">{"< Go Back"}</div>
                    </div>

                    <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                        <h3 className="container-md text-size-md mt-1 ps-0 text-muted">USER PROFILE</h3>

                        <hr className="mb-4"/>

                        <div className="row align-items-center p-0 mx-4">
                            <div className="col-sm-6 col-md-4 text-center text-sm-start img-div">
                                {user.avatar ? (
                                    <div className="details-img2" style={{backgroundImage: `url('${user.avatar.url}')`, backgroundSize: 'cover',  backgroundPosition: 'center'}}></div>
                                ):(<Fragment></Fragment>)}
                            </div> 
                            <div className="col-sm-6 col-md-8 text-center text-sm-start">
                                <div className="ms-3 p-2">
                                    <h3 className="text-size-md m-0 mt-3">{user.firstName} {user.lastName} {user.farmerStatus === 'Pending' || user.vendorStatus === 'Pending' ? "(Pending approval)" : `(${user.role})`}</h3>
                                    <p className="text-size-sm my-2">{user.credential}</p>
                                    <i className="text-size-xs text-muted">Date created: {user.createdAt}</i>
                                    <div className="mt-4">
                                        <Link to="/password/change" state={{source: "profile"}} className="border-btn text-size-xs me-3 mb-4">Change Password</Link>
                                    </div>  
                                </div>
                            </div>
                        </div>

                        {user.farm ? (
                            <Fragment>
                                <hr className="mx-4 my-4"/>
                                <div className="mx-4">
                                    <h5 className="text-size-sm text-muted mb-4 ">Farm Details</h5>
                                    <p className="text-size-sm mt-2">Farm name: <strong><Link to={`/farms/${user.farm._id}`}>{user.farm.farmName}</Link></strong></p>
                                    <p className="text-size-sm mt-2">Farm location: <strong>{user.farm.farmLocation}</strong></p>
                                    <p className="text-size-sm mt-2">Farm size: <strong>{user.farm.farmSize} Hectares</strong></p>
                                    <p className="text-size-sm mt-2">Latitude: <strong>{user.farm.farmLat}</strong></p>
                                    <p className="text-size-sm mt-2">Longitude: <strong>{user.farm.farmLng} Hectares</strong></p>
                                </div>

                                <hr className="mx-4 my-4"/>
                                {/* <div className="map mx-4">
                                    {!isLoaded  ? <div>Loading Map...</div> : <Map coords={farmCoords}/>}
                                </div> */}
                            </Fragment>
                            ) : (<Fragment></Fragment>)
                        }
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

// function Map(coords) {

//     let centerCoords = { lat: coords.coords.pointA.latA, lng: coords.coords.pointA.lngA }

//     const path = [
//         { lat: coords.coords.pointA.latA, lng: coords.coords.pointA.lngA },
//         { lat: coords.coords.pointB.latB, lng: coords.coords.pointB.lngB },
//         { lat: coords.coords.pointC.latC, lng: coords.coords.pointC.lngC },
//         { lat: coords.coords.pointD.latD, lng: coords.coords.pointD.lngD },
//       ];

//     const center = useMemo(() => (centerCoords), [centerCoords]);

//     return <GoogleMap 
//         zoom={12} 
//         center={center} 
//         mapContainerClassName="map-container"
//         >
//             {/* <Marker position={ center }/> */}
//             <Polygon
//                 paths = {path}
//                 strokeColor = "#FF0000"
//                 strokeOpacity = {0.8}
//                 strokeWeight = {1}
//                 fillColor = "#FF0000"
//                 fillOpacity = {0.35}
//             />
//         </GoogleMap>
// }

export default UserProfile