import React, { Fragment, useEffect, useState, useRef } from 'react'
import Metadata from '../layout/metadata'

import AdminNavigation from '../layout/adminNavigation'
import DeleteModal from '../layout/deleteModal'
import { formatDate, numberWithCommas } from '../../Functions/functions'

import { useDispatch, useSelector } from 'react-redux'
import { getAdminOrderDetails, updateOrderStatus, deleteOrderData, clearErrors } from '../../actions/orderActions'
import { DELETE_ORDER_RESET } from '../../constants/orderConstants'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loader from '../layout/loader'

const AdminOrderDetails = () => {
    let isDelete = useRef(false);

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const { user } = useSelector(state => state.auth);
    const { order, error, loading } = useSelector(state => state.orderDetails)
    const {loading: modifyLoading, error: modifyError, success} = useSelector(state => state.modifyOrder)


    let [orderStatus, setOrderStatus] = useState(order.orderStatus);

    useEffect(() => {
        dispatch(getAdminOrderDetails(params.id))

        if (success) {
            dispatch({type: DELETE_ORDER_RESET});
            toast.success("Completed successfully! 👍");
            if (isDelete.current) navigate("/admin/orders");
        }

        if (error) {
            toast.error("Error fetching order details, please check your internet connection.")
            dispatch(clearErrors())
        }

        if (modifyError) {
            toast.error("An error occurred, please check your internet connection.")
            dispatch(clearErrors())
        }
    }, [dispatch, error, params.id])

    const getClickedButton = (clickedButton) => {
        if (clickedButton === "Delete") {
            dispatch(deleteOrderData(params.id));
            isDelete.current = true;
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(updateOrderStatus(orderStatus, params.id));
        window.location.reload(true);
    }

    const rowData = []
    return (
        <Fragment>
            <Metadata title={'Order Details'}/>
            <AdminNavigation Orders/>
            <ToastContainer/>
            {loading ? <Loader/> : (
                <Fragment>
                    {/* USERS SHOW PAGE */}

                    <div className="container-md green-color my-4 ps-4 ps-md-2">
                        <Link to="/admin/orders" className="green-color">{"< Back to Orders"}</Link>
                    </div>
                    <div className="container-md white-bg shadow-lg mb-5 pt-5">

                        <h3 className="text-size-md">ORDER <span className="text-muted">#{order._id}</span></h3>

                        <hr className="mb-5"/>
                        {order.user ? (
                        <div className="row">
                            <div className="col-md-6">
                                <p className="text-size-sm">Created: <strong>{order.createdAt ? formatDate(order.createdAt) : ""}</strong></p>
                                <p className="text-size-sm">Order By: <strong>{order.user.firstName} {order.user.lastName} ({order.shippingInfo.phoneNo})</strong></p>
                                <p className="text-size-sm">Status: <strong>{order.orderStatus}</strong></p>
                                <p className="text-size-sm">Items Price: <strong> &#x20A6;{numberWithCommas(order.itemsPrice)}</strong></p>
                                <p className="text-size-sm">Shipping: <strong> &#x20A6;{numberWithCommas(order.shippingPrice)}</strong></p>
                                <p className="text-size-sm">Tax: <strong> &#x20A6;{numberWithCommas(order.taxPrice)}</strong></p>
                                <p className="text-size-sm">Total: <strong className="green-color"> &#x20A6;{numberWithCommas(order.totalPrice)}</strong></p>
                                <p className="text-size-sm">Payment Status: <strong>{order.paidAt ? "Paid" : "Not Paid"}</strong></p>
                                <p className="text-size-sm">Payment Method: {order.loan ? <Link to={`/admin/loans/${order.loan._id}`} className="bold-2 green-color">{order.paymentMethod} - {order.loan.status}</Link> : <strong>{order.paymentMethod}</strong>}</p>
                                <i className="d-block text-size-sm red-color">{order.change && order.orderStatus !== "Delivered" && order.orderStatus !== "Cancelled"? `(Customer requires ${"\u20A6" + numberWithCommas(order.change)} change)`: ""}</i>
                                {order.orderStatus === "Delivered" ? <p className="text-size-sm"><span className="green-color">Delivered: </span><strong>{order.deliveredAt ? formatDate(order.deliveredAt) : ""}</strong></p> : <Fragment></Fragment>}
                                {order.orderStatus === "Cancelled" ? <p className="text-size-sm"><span className="red-color">Cancelled: </span><strong>{order.cancelledAt ? formatDate(order.cancelledAt) : ""}</strong></p> : <Fragment></Fragment>}
                            </div>
                            <div className="col-md-6">
                                <h5 className="text-size-sm mt-2">Update order status</h5>
                                <form onSubmit={submitHandler}>
                                    <select defaultValue={order.orderStatus} className="form-control mt-2 w-75" name="orderStatus" id="orderStatus" onChange={(e) => setOrderStatus(e.target.value)}
                                    disabled={order.orderStatus === "Delivered" || order.orderStatus === "Cancelled" ? true : false}>
                                        <option value="Processing">Processing</option>
                                        <option className="my-2 py-2" value="Shipped">Shipped</option>
                                        <option className="my-2 py-2" value="Delivered">Delivered</option>
                                        <option className="red-color" value="Cancelled">Cancel Order</option>
                                    </select>
                                    {modifyLoading ? (
                                        <i className="d-block text-muted text-size-xxs">Updating order status...</i>
                                    ):(<Fragment></Fragment>)}
                                    
                                    <button type="submit" className="green-btn text-size-xs mt-3 py-2 px-3" disabled={  order.orderStatus === "Delivered" || order.orderStatus === "Cancelled" || modifyLoading }>Update Status</button>
                                </form>
                            </div>
                        </div>
                        ):(<Fragment></Fragment>)}
                        
                        <hr className="my-4" />

                        <div className="d-flex justify-content-between align-items-center my-4">
                            <h3 className="text-size-md m-0">Products in Order</h3>
                            {order.orderItems ? (
                                <span className="text-size-sm text-muted">({order.orderItems.length} Products)</span>
                            ):(<Fragment></Fragment>)}
                        </div>
                
                        <table className="table">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">
                                        <img src="/images/img-thumbnail.png" alt="Inventory Images Thumbnail" className="ms-2" width="20"/>
                                    </th>
                                    <th scope="col">Product name</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            {order.orderItems ? (
                                order.orderItems.forEach(item => rowData.push(
                                    <tr className="bg-white" key={item._id}>
                                        <td><div style={{backgroundImage: `url('${item.image}')`, backgroundSize: 'cover'}} className="cart-img3 border rounded"></div></td>
                                        <td className="text-size-xs">{item.name}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas(item.price)}</td>
                                        <td className="text-size-xs">{numberWithCommas(item.quantity)}</td>
                                        <td className="text-size-xs">&#x20A6;{numberWithCommas((item.quantity * item.price).toFixed(2))}</td>
                                    </tr>                                 
                                ))
                            ):(<Fragment></Fragment>)}

                            <tbody>{rowData}</tbody>
                        </table>

                        {order.shippingInfo ? (
                            <Fragment>
                                <h3 className="text-size-md mt-5 mb-4">Shipping Infromation</h3>
                                <p className="text-size-sm">Address: <strong>{order.shippingInfo.address}</strong></p>
                                <p className="text-size-sm">City: <strong>{order.shippingInfo.city}</strong></p>
                                <p className="text-size-sm">Postal Code: <strong>{order.shippingInfo.postalCode}</strong></p>
                                <p className="text-size-sm">Country: <strong>{order.shippingInfo.country}</strong></p>
                                <p className="text-size-sm">Phone Number: <strong>{order.shippingInfo.phoneNo}</strong></p>
                            </Fragment>
                        ):(<Fragment></Fragment>)}


                        {user.role === "Admin+" ?
                        <Fragment>
                            <button className="red-btn bold-2 text-size-xs mt-5" data-bs-toggle="modal" data-bs-target="#delete-modal" disabled={modifyLoading}>Delete order record</button>
                            <DeleteModal item="order record" onSelect={getClickedButton}/>
                        </Fragment>

                        :<Fragment></Fragment>}
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default AdminOrderDetails