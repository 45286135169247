import React, { Fragment, useState } from 'react'

import Metadata from '../layout/metadata';

import { useLocation } from 'react-router-dom'

import { Link } from 'react-router-dom'

import SignModal from './sign';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoanTerms = () => {
    const location = useLocation();

    return (
        <Fragment>
            <Metadata title={'Terms - Financing'} />
            <ToastContainer />
            <div className="container-md green-color my-4 ps-4 ps-md-2">
                <Link to="/seller/loans/apply" state={{ 
                    loanData: location.state.loanData,
                    orderDetails: location.state.orderDetails
                }} className="green-color">{"< Back to Financing"}</Link>
            </div>
            <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

                <h3 className="text-size-md text-muted">TERMS AND CONDITIONS</h3>

                <hr className="my-4" />

                <div className="mt-5">
                    <p className="terms">
                        The following Terms and Conditions are applicable to all Farm Warehouse Products. These Terms and
                        Conditions are explicitly incorporated into your Customer Application &amp; Agreement Form. You should
                        therefore read the below Terms and Conditions together with your Customer Application &amp; Agreement
                        and contact Farm Warehouse Customer Services if you have questions about their interpretation or
                        meaning.

                        <span className="d-block text-size-sm mt-4 bold-2">Intended Use Only</span>
                        All products shall be used solely in accordance with the instructions provided and must be maintained in
                        proper working conditions. The customer shall not be entitled to any warranty in the event of a major
                        usage which is not in line with product usage instruction.

                        <span className="d-block text-size-sm mt-4 bold-2">Contact Information</span>
                        The Customer must ensure that all contact information provided to Farm Warehouse is complete and
                        accurate in order to allow Farm Warehouse to contact the Customer or the Alternative Contact Person
                        during reasonable hours regarding the Customer’s Farm Warehouse account. The Customer agrees to
                        inform Farm Warehouse of any changes to the contact information during the term of the Agreement and
                        undertakes not to withhold any relevant information from Farm Warehouse.
                        
                        <span className="d-block text-size-sm mt-4 bold-2">Customer Data</span>
                        By accepting these Terms and Conditions the Customers hereby consents to and authorizes Farm
                        Warehouse to capture, store, analyze and use their data for processing requests, administering this
                        agreement, informing on amendments to this agreement, informing them of new products and services,
                        research and promotional activities within the subsistence of the contract.

                        
                        <span className="d-block text-size-sm mt-4 bold-2">Continuous Payment</span>
                        The customer agrees to pay the Credit Fee payments (or in advance at their choosing) until they have
                        paid the Total Purchase Price within the Maximum Payment Period.

                        <span className="d-block text-size-sm mt-4 bold-2">Mode of Payment</span>                            
                        The advance payment and all Credit Fee payments (whether daily, weekly, monthly or in total upfront)
                        towards purchase of the Product and/or additional product shall be made through our designated sales channels 
                        (Online banking payments channels on the Marketplace app, or direct transfer or deposit to our designated bank 
                        account as presented by our duly authorized Sales personnel).

                        <span className="d-block text-size-sm mt-4 bold-2">Deactivation</span>                            
                        If the custom fails to keep current on their credit fee payments, Farm Warehouse may deactivate the
                        customer’s product until the customer becomes current again in their credit fee payments.

                        Additionally, if the customers is unable to fully amortize the cost of their product within the maximum allowed 
                        tenor of repayment, then the product will be retrieved from the customer by Farm Warehouse and shall be sold at 
                        depreciated value to another willing buyer in order to recover the outstanding amount. The customer shall not be 
                        entitled to any refunds nor shall he/she reserve any rights of ownership of the product during the amortization 
                        period until the selling price of the product is fully amortized within the allowable period agreed upon.  

                        <span className="d-block text-size-sm mt-4 bold-2">Product Alteration</span>                            
                        The Customers MUST not tamper (including but not limited to altering or removing the Product serial
                        number or other Product Identification marks), open or modify and/or use the Product(s) in any other way
                        not authorized by Farm Warehouse or aid any third party to do the same. Any contravention of this
                        product will result in forfeiture of the Warranty and result in a fundamental breach of the Agreement
                        between Farm Warehouse and the Customer. For avoidance of doubt by tampering and/or modification of
                        the Product(s) shall constitute willful destruction of Farm Warehouse’s property and if done within the
                        Customer or a third party, found to have tampered and/or modified the Product(s) in the manner
                        described herein, Farm Warehouse also reserves the right to pursue any other civil remedies available to
                        it against such person.

                        <span className="d-block text-size-sm mt-4 bold-2">Consent to Farm Warehouse to report and verify my credit status and phone records</span>                            
                        By accepting these Terms and Conditions the Customer hereby consents to and authorizes Farm
                        Warehouse to report and verify the Customer’s credit history, credentials and/or phone records (including
                        mobile money history) with any registered credit reference bureau, mobile service provider and/or such
                        other database. The Customers also agree to bring to Farm Warehouse’s attention any inaccuracies in
                        the credit information reported to a credit reference bureau for review before taking any action against
                        Farm Warehouse for inaccurate reporting.

                        <span className="d-block text-size-sm mt-4 bold-2">Title of goods</span>                            
                        Upon full payment within the maximum payment period, ownership and title in the Product(s) shall vest in
                        the Customer. Further, all risk of loss or damage to the Product for any reason whatsoever shall pass to
                        the communication module updates credits in the Product(s) and (ii) any and all intellectual property that
                        is used to run the Product, in both cases even after the Total Purchase is paid.

                        <span className="d-block text-size-sm mt-4 bold-2">Inspection by Farm Warehouse</span>                            
                        The Customer grants to Farm Warehouse, its employees or agents all reasonable facilities and
                        opportunity for the inspection of the Product including but not limited to with respect to any unauthorized
                        uses of the Product.

                        <span className="d-block text-size-sm mt-4 bold-2">Additional Products</span>                            
                        The Customer may be eligible to purchase other Products on offer from Farm Warehouse from time-to-
                        time.

                        <span className="d-block text-size-sm mt-4 bold-2">Warranties</span>                            
                        Farm Warehouse will replace or repair a Product, in accordance with the Warranty, terms and conditions
                        provided by the Product manufacturer. The Warranty is valid only if the Product is used as instructed and
                        is not tampered, opened, modified and/or used in other ways not authorized by Farm Warehouse.

                        <span  className="d-block mt-4">
                            If the Product is faulty in any way, the Customer should visit the nearest Service Center or Farm
                            Warehouse Processing Hub for repairs or replacement.
                        </span>

                        <span className="d-block text-size-sm mt-4 bold-2">Farm Warehouse Agrees To:</span>                            

                        <span className="d-block text-size-sm mt-4 bold-2">Protect Data</span>                            
                        Farm Warehouse agrees to protect Customer’s data in accordance with the prevailing data protection and
                        consumer protection laws unless required to disclose such information by law or in accordance with these
                        Terms and Conditions.

                        <span className="d-block text-size-sm mt-4 bold-2">Inform of Approval</span>                            
                        Farm Warehouse will communicate approval or disapproval of the agreement to the Customer by SMS,
                        email or phone call within 7 days, thereby granting a limited license for the use of the necessary software
                        code.

                        <span className="d-block text-size-sm mt-4 bold-2">Activate the Product</span>                            
                        Farm Warehouse will ensure the product is activated once the Advance Payment is received and,
                        thereafter, whenever Customer’s Farm Warehouse Account has a positive credit balance. Farm
                        Warehouse reserves the right to offer a customer a discount or any other preferential terms at its
                        direction.

                        <span className="d-block text-size-sm mt-4 bold-2">No Refund of the Advance Payment</span>                            
                        Under no circumstances shall Farm Warehouse provide a refund for Products including upon the
                        termination of this agreement whether upon partial purchase payment or total purchase payment.

                        <span className="d-block text-size-sm mt-4 bold-2">End Billing when Total Purchase Price is Paid</span>                            
                        Farm Warehouse will complete billing for the Device and end further Credit Fee requirements once the
                        Total Purchase price is paid. Farm Warehouse retains ongoing ownership of the communications module
                        (only) and reserves the right to reactivate the module and billing for additional products with the
                        Customer’s consent.

                        <span className="d-block text-size-sm mt-4 bold-2">Repairs or Replacement in Warranty Period</span>
                        Farm Warehouse will repair or replace the Product in accordance with the Warranty terms and conditions.
                        The Warranty valid only if the Product is used as instructed and is not tampered, opened, modified or
                        used in other ways not authorized by Farm Warehouse. The Warranty period will commence on the day
                        of this agreement for the date the Product is activated, whichever is earlier and shall subsist for two years
                        thereafter.

                        <span className="d-block text-size-sm mt-4 bold-2">Installation of Equipment</span>                            
                        Farm Warehouse shall train its agents on how to install the product and shall certify that such products
                        are in good working condition upon installation with confirmation by the Customer. Where the Customer
                        chooses to install the equipment on their own - without the assistance of a Farm Warehouse certified
                        installer, the Customer shall be liable for any negligent handling which leads to the failure of the Product
                        to perform as expected. This includes any damage or breakage suffered in the course of installation. The
                        Farm Warehouse warranty extends to the failure of the Product to function due to any manufacturer
                        defects notwithstanding whether the device is installed by Farm Warehouse installer or the Customer.
                    </p>
                </div>

                <div className="text-end mt-4">
                    <Link to="/seller/loans/apply" state={{ 
                        loanData: location.state.loanData,
                        orderDetails: location.state.orderDetails
                    }} className="border-btn me-3">Back to Financing</Link>
                    <button type="button" className="green-btn" data-bs-toggle="modal" data-bs-target="#signModal">I agree to the terms</button>
                    <SignModal/>
                </div>

            </div>
        </Fragment>
    )
};

export default LoanTerms;
