import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


const AdminNavigation = ({Dashboard, Products, Orders, Payments, Storage, Users, Farms, Requests, Insurance, Loans, Logs}) => {
  const { user } = useSelector(state => state.auth)

  return (
    <Fragment>
        <nav className="container-fluid navbar navbar-expand-lg">
        <div className="container-md">
            <div className="user-nav-block">
                {/* DASHBOARD */}
                <Link to="/admin/dashboard" className="user-nav-item ms-0">
                    <img src={Dashboard ? '/images/dashboard-icon.png' : '/images/dashboard-icon-gray.png'} className="seller-nav-img" alt="Dashboard-icon" />
                    <span className={`text-size-xs user-nav-label ${Dashboard?'green':'gray'}`}>Dashboard</span>
                </Link>

                {/* PRODUCTS */}
                <Link to="/admin/products" className="user-nav-item">
                <img src={Products ? '/images/inventory-icon.png' : '/images/inventory-icon-gray.png'} className="seller-nav-img" alt="Inventory-icon" />
                <span className={`text-size-xs user-nav-label ${Products?'green':'gray'}`}>Products</span>
                </Link>

                {/* ORDERS */}
                <Link to="/admin/orders" className="user-nav-item">
                <img src={Orders ? '/images/orders-icon.png' : '/images/orders-icon-gray.png'} className="seller-nav-img" alt="Orders-icon" />
                <span className={`text-size-xs user-nav-label ${Orders?'green':'gray'}`}>Orders</span>
                </Link>

                {/* PAYMENTS */}
                <Link to="/admin/payments" className="user-nav-item">
                <img src={Payments ? '/images/payments-icon.png' : '/images/payments-icon-gray.png'} className="seller-nav-img" alt="payments-icon" />
                <span className={`text-size-xs user-nav-label ${Payments?'green':'gray'}`}>Payments</span>
                </Link>

                {/* STORAGE */}
                <Link to="/admin/storage" className="user-nav-item">
                    <img src={Storage ? '/images/storage-icon.png' : '/images/storage-icon-gray.png'} className="seller-nav-img" alt="storage-icon" />
                    <span className={`text-size-xs user-nav-label ${Storage?'green':'gray'}`}>Storage</span>
                </Link>

                {/* USERS */}
                <Link to="/admin/users" className="user-nav-item">
                    <img src={Users ? '/images/users-icon.png' : '/images/users-icon-gray.png'} className="seller-nav-img" alt="users-icon" />
                    <span className={`text-size-xs user-nav-label ${Users?'green':'gray'}`}>Users</span>
                </Link>
                
                {/* FARMS */}
                <Link to="/admin/farms" className="user-nav-item">
                    <img src={Farms ? '/images/farms-icon.png' : '/images/farms-icon-gray.png'} className="seller-nav-img" alt="farms-icon" />
                    <span className={`text-size-xs user-nav-label ${Farms?'green':'gray'}`}>Farms</span>
                </Link>

                {/* REQUESTS */}
                <Link to="/admin/requests" className="user-nav-item">
                    <img src={Requests ? '/images/requests-icon.png' : '/images/requests-icon-gray.png'} className="seller-nav-img" alt="requests-icon" />
                    <span className={`text-size-xs user-nav-label ${Requests?'green':'gray'}`}>Requests</span>
                </Link>

                {/* INSURANCE */}
                <Link to="/admin/insurance" className="user-nav-item">
                <img src={Insurance ? '/images/insurance-icon.png' : '/images/insurance-icon-gray.png'} className="seller-nav-img" alt="Insurance-icon" />
                <span className={`text-size-xs user-nav-label ${Insurance?'green':'gray'}`}>Insurance</span>
                </Link>

                {/* LOANS */}
                <Link to="/admin/loans" className="user-nav-item">
                    <img src={Loans ? '/images/loans-icon.png' : '/images/loans-icon-gray.png'} className="seller-nav-img" alt="Loans-icon" />
                    <span className={`text-size-xs user-nav-label ${Loans?'green':'gray'}`}>Loans</span>
                </Link>  

                {/* LOGS */}
                <Link to="/admin/logs" className="user-nav-item">
                    <img src={Logs ? '/images/orders-icon.png' : '/images/orders-icon-gray.png'} className="seller-nav-img" alt="Logs-icon" />
                    <span className={`text-size-xs user-nav-label ${Logs?'green':'gray'}`}>Logs</span>
                </Link>          
            </div>
        </div>
        </nav>

    </Fragment>
  )
}

export default AdminNavigation