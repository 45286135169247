import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'

import { useDispatch, useSelector } from 'react-redux'
import { getLogs, clearErrors } from '../../actions/logsActions'
import { formatDate } from '../../Functions/functions'



const AllLogs = () => {
    let clicked = useRef("All");
    let firstLoad = useRef(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, logs } = useSelector(state => state.getLogs);

    let [ filteredLogs, setFilteredLogs ] = useState(logs)

    if (firstLoad.current) {filteredLogs = logs};

    useEffect(() => {
        dispatch(getLogs());

        if (error) {
            console.log(error, "ERRROORRRR!! - userLogs.jsx")
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const toggleTable = (reference) => {
        firstLoad.current = false
        let filter
        clicked.current = reference
        if (reference === "All") {
            filter = logs;
        } else if (reference === "Loan") {
            filter = logs.filter(log => log.reference === "Loan")
        } else if (reference === "Order") {
            filter = logs.filter(log => log.reference === "Order")
        } else if (reference === "User") {
            filter = logs.filter(log => log.reference === "User")
        } else if (reference === "Product") {
            filter = logs.filter(log => log.reference === "Product")
        } else if (reference === "Default") {
            filter = logs.filter(log => log.reference === "Default")
        }

        setFilteredLogs(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Logs"}/>
            <AdminNavigation Logs/>

            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <h3 className="mid-container-header text-size-sm text-muted">LOGS</h3>
                    <div className="d-flex flex-column flex-md-row justify-content-between border-bottom mb-4">
                        <span className="text-muted text-size-xs order-1 order-md-2">{filteredLogs.length} {clicked.current === 'All' ? 'logs' : clicked.current + " logs"}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked.current === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Loan') }} className={`table-nav ${clicked.current === 'Loan'?'table-active':''}`}>Loans</button>
                            <button onClick={function() { toggleTable('Order') }} className={`table-nav ${clicked.current === 'Order'?'table-active':''}`}>Orders</button>
                            <button onClick={function() { toggleTable('User') }} className={`table-nav ${clicked.current === 'User'?'table-active':''}`}>Users</button>
                            <button onClick={function() { toggleTable('Product') }} className={`table-nav ${clicked.current === 'Product'?'table-active':''}`}>Products</button>
                            <button onClick={function() { toggleTable('Default') }} className={`table-nav ${clicked.current === 'Default'?'table-active':''}`}>Defaults</button>
                        </div>
                    </div>
                        

                    {loading ? <Loader/> : (
                        <Fragment>

                        <table className="table mt-3">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th scope="col">Action</th>
                                    <th scope="col">User</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>

                            {filteredLogs.forEach(log => rowData.push(
                                <tr className="bg-white" key={log._id}>
                                    <td className="text-size-xs">{log.action}</td>
                                    <td className="text-size-xs">{log.user.firstName} {log.user.lastName}</td>
                                    <td className="text-size-xs">{formatDate(log.date)}</td>
                                </tr>                                 
                            ))}
                            <tbody>{rowData}</tbody>
                        </table>
                        { filteredLogs.length == 0 ? (
                            <div className="text-center py-3 w-100">
                                <h3 className='text-size-sm'>NO DATA</h3>
                            </div>
                        ):(<Fragment></Fragment>)}
                    </Fragment>
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default AllLogs