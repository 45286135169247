export const GET_REQUEST_REQUEST = 'GET_REQUEST_REQUEST'
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS'
export const GET_REQUEST_FAIL = 'GET_REQUEST_FAIL'

export const NEW_REQUEST_REQUEST = 'NEW_REQUEST_REQUEST'
export const NEW_REQUEST_SUCCESS = 'NEW_REQUEST_SUCCESS'
export const NEW_REQUEST_RESET = 'NEW_REQUEST_RESET'
export const NEW_REQUEST_FAIL = 'NEW_REQUEST_FAIL'

export const REQUEST_DETAILS_REQUEST = 'REQUEST_DETAILS_REQUEST'
export const REQUEST_DETAILS_SUCCESS = 'REQUEST_DETAILS_SUCCESS'
export const REQUEST_DETAILS_FAIL = 'REQUEST_DETAILS_FAIL'

export const UPDATE_REQUEST_REQUEST = 'UPDATE_REQUEST_REQUEST'
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS'
export const UPDATE_REQUEST_FAIL = 'UPDATE_REQUEST_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'