import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Metadata from './metadata'
import { getUserDetails, respondToUserRequest, clearErrors } from '../../actions/userActions'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RejectSellerModal = ({user, id}) => {

    const dispatch = useDispatch();
    const params = useParams();
    const {loading, error, success } = useSelector(state => state.response)
    const [reason, setReason] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();
        rejectOnClick(`${user.farmerStatus === "Pending"? "Farmer" : "Vendor"}`);
    }

    const rejectOnClick = (seller) => {
        dispatch(respondToUserRequest({
            "decision": "Reject",
            "reason": reason
        }, id, seller))

        if (error) {
            console.log(error)
            toast.error("An error occurred, please check your internet connection, refresh, and try again.");
            dispatch(clearErrors())
        }
        if (success) {
            toast.success('Seller account request has been rejected. The user has been notified.');
        }

        dispatch(getUserDetails(params.id))
    }


    return (
        <Fragment>
            <Metadata title={'Users'} />
            <ToastContainer />
            <form className="px-2" onSubmit={submitHandler}>
                {/* Modal */}
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-size-md" id="staticBackdropLabel">Reject request</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="reason text-size-xs mb-2">Enter your reason for rejecting this user request.</label>
                        <textarea 
                            name="reason"
                            id="reason"
                            className="form-control"
                            cols="30" rows="4"
                            value={reason} 
                            onChange={(e) => setReason(e.target.value)}
                            required></textarea>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-danger" disabled={loading ? true : false}>Reject</button>
                    </div>
                    </div>
                </div>
                </div>
            </form>

        </Fragment>
    )
    }

export default RejectSellerModal
