import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'

import { useDispatch, useSelector } from 'react-redux'
import { getAllStorage, clearErrors } from '../../actions/storageActions'
import { formatDate } from '../../Functions/functions'

let firstLoad = true;

const AllStorage = () => {
    const dispatch = useDispatch();

    const { loading, error, storages } = useSelector(state => state.getStorages);

    let [ filteredStorages, setFilteredStorages ] = useState(storages)

    if (firstLoad) { filteredStorages = storages };

    useEffect(() => {
        dispatch(getAllStorage());

        if (error) {
            console.log(error, "ERRROORRRR!!")
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const rowData = [];

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredStorages(storages)
        }
        let matches = storages.filter(storage => storage.product.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredStorages(matches);
        firstLoad = false;
    }

    return (
        <Fragment>
            <Metadata title={'All Storages'}/>
            <AdminNavigation Storage/>
            <div className="container-fluid">
                <div className="container-md seller-section-box">
                    <div className="mid-container-header d-flex justify-content-between align-items-center">
                        <h3 className="text-size-sm text-muted d-inline-block m-0">STORAGE</h3>
                        <span className="text-muted text-size-xs">{storages.length} Items in storage</span>
                    </div>

                    <div className="d-flex">
                        <input 
                        className="form-control me-2 py-2" 
                        type="search" 
                        placeholder="Search storage by product" 
                        aria-label="Search"
                        onChange={onSearchChange}
                        />
                        <Link to={"/admin/storage/add-product"} className="btn green-btn w-25 plus" type="submit">+<span className="d-none d-sm-inline text-size-xxs"> Store Product</span></Link>
                    </div>

                    {loading ? <Loader/> : (
                        <Fragment>
                        <table className="table mt-4">
                            <thead className="table-secondary py-5">
                                <tr>
                                    <th className="text-size-xs" scope="col">Warehouse</th>
                                    <th className="text-size-xs" scope="col">Product</th>
                                    <th className="text-size-xs" scope="col">Shelf</th>
                                    <th className="text-size-xs" scope="col">Owner</th>
                                    <th className="text-size-xs text-center" scope="col">Quantity</th>
                                    <th className="text-size-xs text-center" scope="col">Date Stored</th>
                                    <th className="text-size-xs text-center" scope="col">Action</th>
                                </tr>
                            </thead>
                            {filteredStorages.forEach(storage => rowData.push(
                                
                                <tr className="bg-white" key={storage._id}>
                                    <td className="text-size-xs">{storage.location}</td>
                                    <td className="text-size-xs">{storage.product}</td>
                                    <td className="text-size-xs">{storage.shelf}</td>
                                    <td className="text-size-xs">{storage.seller.firstName} {storage.seller.lastName}</td>
                                    <td className="text-size-xs text-center">{storage.quantity}</td>
                                    <td className="text-size-xs text-center">{formatDate(storage.createdAt)}</td>
                                    <td className="text-size-xs text-center"><Link to={`/admin/storage/${storage._id}`} className="table-btn">View</Link></td>
                                </tr>                                 
                            ))}
                            <tbody>{rowData}</tbody>
                        </table>
                        { filteredStorages.length == 0 ? (
                            <div className="text-center py-3 w-100">
                                <h3 className='text-size-sm'>NO DATA</h3>
                            </div>
                        ):(<Fragment></Fragment>)}
                    </Fragment>
                    )}
                </div>
            </div>
            


        </Fragment>
    )
}

export default AllStorage