import React from 'react';
import { numberWithCommas, formatDate } from '../../Functions/functions'

const PaymentTable = ({ initDate, numOfMonths, payments, monthly }) => {

  const generateTables = () => {
    const tables = [];
    let currentMonth = new Date(initDate);
    let paymentIndex = 0;
    for (let i = 0; i < Math.ceil(numOfMonths / 12); i++) {
      const rows = [];
      for (let j = 0; j < Math.min(numOfMonths - i * 12, 12); j++) {
        rows.push(
          <tr key={j}>
            <td className="text-size-xs">{formatDate(currentMonth)}</td>
            <td className="text-size-xs">
              {payments[paymentIndex]
                ? "₦0"
                : "₦" + numberWithCommas(monthly)}
            </td>
            <td className="text-size-xs">
              {payments[paymentIndex]
                ? "₦" + numberWithCommas(payments[paymentIndex].amount)
                : "₦0"}
            </td>
          </tr>
        );
        currentMonth.setMonth(currentMonth.getMonth() + 1);
        paymentIndex++;
      }
      tables.push(
        <div key={i}>
          <h5 className="text-size-sm bold-2">Year {i + 1}</h5>
          <table className="date-table">
            <thead className="new-year-header">
              <tr>
                <th>Payment Due Date</th>
                <th>Amount Due</th>
                <th>Amount Paid</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      );
    }
    return tables;
  };

  return <div>{generateTables()}</div>;
};

export default PaymentTable;