import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAllUsers } from '../../actions/userActions'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { NEW_STORAGE_RESET } from '../../constants/storageConstants'
import { newStorage, clearErrors } from '../../actions/storageActions'

const StoreProduct = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { users } = useSelector(state => state.allUsers );
    const { loading, error, success } = useSelector(state => state.newStorage)

    const sellers = users.filter(user => user.role === "Farmer")

    useEffect(() => {
        dispatch(getAllUsers());

        if (error) {
            console.log(error, "ERRORRR - storeProduct.jsx")
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }
        if (success) {
            navigate('/admin/storage')
            console.log('Stored product')
            toast.success("Successfully stored product 👍")
            dispatch({ type: NEW_STORAGE_RESET })
        }
    }, [dispatch, error, success])

    const [storage, setStorage] = useState({
        product: '',
        location: '',
        quantity: '',
        packaging: '',
        shelf: '',
        seller: ''
    })

    const { product, location, quantity, packaging, shelf, seller } = storage;

    const locations = [
        'Kaduna Warehouse',
        'Ibadan Warehouse',
        'Abeokuta Warehouse',
    ]

    const packages = [
        'Bags',
        'Drums',
        'Sacks',
    ]

    const shelves = [
        'Isle 9-1',
        'Isle-12-4',
        'Isle 22-3',
    ]

    const onChange = e => {
        setStorage({...storage, [e.target.name]: e.target.value })
    }

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(newStorage(storage));
    }


  return (
    <Fragment>
        <Metadata title={'Add Product to Storage'} />
        <ToastContainer />
        <AdminNavigation Storage/>

        <div className="container-md green-color my-4 ps-4 ps-md-2">
            <Link to="/admin/storage" className="text-size-xs green-color">{"< Back to Storage"}</Link>
        </div>
        <div className="container-md white-bg shadow-lg my-0 mb-md-5 pt-5">

            <h3 className="text-size-md text-muted">ADD PRODUCT TO STORAGE</h3>
            <hr className="my-4" />
            <form onSubmit={submitHandler}>

                <div className="row">

                    {/* WAREHOUSE LOCATION */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="location" className="form-label">Warehouse Location</label>
                        <select 
                            name="location" 
                            id="location" 
                            className="form-control"
                            value={location} 
                            onChange={(onChange)}
                            required
                            >
                            <option value="">Choose the warehouse location</option>
                            {locations.map(location => (
                                <option key={location} value={location}>{location}</option>
                            ))}
                        </select>
                    </div>

                    {/* PRODUCT NAME */}
                    <div className="col-md-6 mb-4">
                    <label htmlFor="product" className="form-label">Product Name</label>
                    <i id="productNameHelp" className="ms-2 text-muted text-size-xxs">(Cannot exceed 40 characters)</i>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="product"
                            name='product'
                            value={product} 
                            onChange={onChange}
                            aria-describedby="productNameHelp" 
                            placeholder="E.g. Fresh Tomatoes"
                            required
                        />
                    </div>

                    {/* PRODUCT OWNER */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="seller" className="form-label">Product Owner</label>
                        <select 
                            name="seller" 
                            id="seller" 
                            className="search--select form-control"
                            value={seller} 
                            onChange={(onChange)}
                            required
                            >
                            <option value="">Choose the Product Owner</option>

                            {sellers.map(seller => (
                                <option key={seller._id} value={seller._id}>{seller.firstName} {seller.lastName}</option>
                                
                            ))}
                        </select>
                    </div>

                    {/* PRODUCT QUANTITY */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="quantity" className="form-label">Product Quantity</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="quantity" 
                            name='quantity' 
                            value={quantity} 
                            onChange={(onChange)}
                            placeholder="E.g. 50"
                            required
                        />
                    </div>

                    {/* PRODUCT PACKAGING */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="packaging" className="form-label">Product Packaging</label>
                        <select 
                            name="packaging" 
                            id="packaging" 
                            className="form-control"
                            value={packaging} 
                            onChange={(onChange)}
                            required
                            >
                            <option value="">Choose Packaging Type</option>
                            {packages.map(packaging => (
                                <option key={packaging} value={packaging}>{packaging}</option>
                            ))}
                        </select>
                    </div>

                    {/* SHELF */}
                    <div className="col-md-6 mb-4">
                        <label htmlFor="shelf" className="form-label">Storage Shelf</label>
                        <select 
                            name="shelf" 
                            id="shelf" 
                            className="search--select form-control"
                            value={shelf} 
                            onChange={(onChange)}
                            required
                            >
                            <option value="">Choose Storage Shelf</option>
                            {shelves.map(shelf => (
                                <option key={shelf} value={shelf}>{shelf}</option>
                            ))}
                        </select>
                    </div>

                    <button 
                        type="submit" 
                        className="text-size-xs green-btn my-4 ms-auto me-3"
                        disabled={loading ? true : false}
                    >{loading ? "Storing product, please hold on..." : "Store product"}
                    </button>
                </div>
            </form>

        </div>
    </Fragment>
  )
}

export default StoreProduct