import React from 'react'
import { Link } from 'react-router-dom'
import { numberWithCommas } from '../../Functions/functions'

const ProductCard = ({ product }) => {
  return (
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 px-0 py-1">
        <Link to={`/products/${product.category.toLowerCase()}/${product._id}`} state={{ from: "home" }} className="card">
            {/* <img src={product.image[0].url} className="card-img-top" alt="Product Image"/> */}
            <div className="card-img-top" style={{backgroundImage: `url('${product.images[0].url}')`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
            <div className="card-body px-2">
                <h4 className="text-size-sm">{product.name}</h4>
                <span className="card-price green-color">&#x20A6;{numberWithCommas(product.price)}/Unit</span>
                {/* <button className="add-to-cart">+</button> */}
            </div>
        </Link>
    </div>
  )
}

export default ProductCard